/**
 * 指令：用于操作权限判断
 */
export default {

  install: function (Vue, options) {
    /**
     * 是否存在操作权限
     * @param authCode
     * @returns {boolean}
     */
    Vue.prototype.isExistAuthorization=function(authCode){
      var me=this;
      if(!me.$store.state.sysUserInfo||me.$store.state.sysUserInfo == undefined ||!me.$store.state.sysUserInfo.funCodes|| me.$store.state.sysUserInfo.funCodes.length==0){
        return false;
      }
      var funCodes=me.$store.state.sysUserInfo.funCodes;
      let funCodeList = funCodes.split(",");
      for(var i=0;i<funCodeList.length;i++){
        if(funCodeList[i]==authCode){
          return true;
        }
      }
      return false;
    };
    /**
     * 是否同时不存在操作权限
     * @param authCode
     * @returns {boolean}
     */
    Vue.prototype.isAllNotExistAuthorization=function(authCode){
      var me=this;
      if(!me.$store.state.sysUserInfo||me.$store.state.sysUserInfo == undefined ||!me.$store.state.sysUserInfo.funCodes|| me.$store.state.sysUserInfo.funCodes.length==0){
        return false;
      }
      let funAry = authCode.split(",");
      for(var i=0;i<funAry.length;i++){
        if(me.isExistAuthorization(funAry[i])){
          return false;
        }
      }
      return true;
    }


  }
}

<template>
  <el-table-column :label="label" :className="className" :prop="prop" :width="width" :align="align" :minWidth="minWidth">
    <template slot-scope="scope">
      {{currentValue(scope.row[prop])?currentValue(scope.row[prop]):'暂无'}}
    </template>
  </el-table-column>
</template>

<script>
import { getdictListByPid, getdictListByPkey, getDictCache } from "@/api/system/dict";
export default {
  name: "DictTableColumn",
  components: {},
  mounted() {
    let me = this;
    me.init();
  },
  computed: {},
  props: {
    dictKey: String,
    label: String,
    className: String,
    labelClassName: String,
    property: String,
    prop: String,
    width: {},
    minWidth: {},
    renderHeader: Function,
    sortable: {
      type: [String, Boolean],
      default: false,
    },
    sortMethod: Function,
    sortBy: [String, Function, Array],
    resizable: {
      type: Boolean,
      default: true,
    },
    context: {},
    columnKey: String,
    align: String,
    headerAlign: String,
    showTooltipWhenOverflow: Boolean,
    showOverflowTooltip: Boolean,
    fixed: [Boolean, String],
    formatter: Function,
    selectable: Function,
    reserveSelection: Boolean,
    filterMethod: Function,
    filteredValue: Array,
    filters: Array,
    filterPlacement: String,
    filterMultiple: {
      type: Boolean,
      default: true,
    },
    index: [Number, Function],
  },
  data() {
    return {
      item: [],
    };
  },
  methods: {
    currentValue(value) {
      var me = this;
      for (var i = 0; i < me.item.length; i++) {
        if (me.item[i].value === value + "") {
          return me.item[i].key;
        }
      }
    },
    async init() {
      let me = this;
      me.$store.commit("reloadDictData");
      if (!me.value) {
        //          TODO 模块放置字典值为空
        //          console.error("模块放置字典值为空！");
      }
      if (!me.$store.state.dictData || me.$store.state.dictData.length < 0) {
        await getDictCache(
          {},
          (response) => {
            this.$store.commit("setDictData", response);
            me.initDict();
          },
          (error) => {
            console.warn(error);
            this.$message.error(error);
          }
        );
      } else me.initDict();
    },
    initDict() {
      const me = this;
      if (me.dictKey && me.dictKey.length > 0) {
        //        加载字典项值
        me.loadDataByKey();
        //        TODO 加载默认值
        me.initDefault();
        return;
      }
    },
    //    获取默认值
    getDefaultVal() {
      let me = this;
      for (var i = 0; i < me.item.length; i++) {
        if (me.item[i].isDefault) {
          return me.item[i].value;
        }
      }
    },
    initDefault() {
      let me = this;
      if (!me.value) {
        for (var i = 0; i < me.item.length; i++) {
          if (me.item[i].isDefault) {
            me.value = me.item[i].value;
          }
        }
      }
    },
    loadDataByKey() {
      let me = this;
      var item = me.findDictItem(me.$store.state.dictData);
      if (item) {
        me.item = item;
      } else {
        console.error("字典项：[" + me.dictKey + "]检索不到对应数据！");
      }
    },
    findDictItem(item) {
      let me = this;
      var tmp = null;
      if (!item || item.length <= 0) {
        return null;
      }
      for (var i = 0; i < item.length; i++) {
        //        最底层无需遍历
        if (!item[i].childDict || item[i].childDict.length <= 0) {
          continue;
        }
        //        如果命中
        if (item[i].key === me.dictKey) {
          tmp = item[i].childDict;
          break;
        }
        //        遍历子元素
        if (!item[i].childDict || item[i].childDict.length <= 0) {
          continue;
        }
        tmp = me.findDictItem(item[i].childDict);
        if (tmp && tmp.length > 0) {
          return tmp;
        }
      }
      return tmp;
    },
  },
  watch: {},
};
</script>

<style scoped>
</style>

<template>
  <span>{{ dictText }}</span>
</template>

<script>
import { getdictListByPid, getdictListByPkey, getDictCache } from "@/api/system/dict";
export default {
  props: ["dictKey", "val"],
  computed: {
    currentValue() {
      return this.model;
    },
  },
  components: {},
  mounted() {
    let me = this;
    me.init();
  },
  data() {
    return {
      item: [],
      dictText: "",
    };
  },
  methods: {
    //	初始化字典缓存
    async init() {
      let me = this;
      me.$store.commit("reloadDictData");
      if (!me.val) {
        //console.error("模块放置字典值为空！");
      }
      if (!me.$store.state.dictData || me.$store.state.dictData.length < 0) {
        await getDictCache(
          {},
          (response) => {
            this.$store.commit("setDictData", response);
            me.initDict();
          },
          (error) => {
            console.warn(error);
            this.$message.error(error);
          }
        );
      } else me.initDict();
    },
    change: function (val) {
      this.$emit("input", val);
    },
    initDict() {
      const me = this;
      if (me.dictKey && me.dictKey.length > 0) {
        //        加载字典项值
        me.loadDataByKey();
        //        TODO 加载默认值
        //          me.initDefault();
        return;
      }
    },
    loadDataByKey() {
      let me = this;
      var item = me.findDictItem(me.$store.state.dictData);
      if (item) {
        //        检测值是否为数值，进行转换
        me.item = item;
        for (var i = 0; i < me.item.length; i++) {
          if (me.val + "" == me.item[i].value) {
            me.dictText = me.item[i].key;
          }
        }
      }
    },
    findDictItem(item) {
      let me = this;
      var tmp = null;
      if (!item || item.length <= 0) {
        return null;
      }
      for (var i = 0; i < item.length; i++) {
        //        最底层无需遍历
        if (!item[i].childDict || item[i].childDict.length <= 0) {
          continue;
        }
        //        如果命中
        if (item[i].key === me.dictKey) {
          tmp = item[i].childDict;
          break;
        }
        //        遍历子元素
        if (!item[i].childDict || item[i].childDict.length <= 0) {
          continue;
        }
        tmp = me.findDictItem(item[i].childDict);
        if (tmp && tmp.length > 0) {
          return tmp;
        }
      }
      return tmp;
    },
  },
  watch: {
    val() {
      for (var i = 0; i < this.item.length; i++) {
        if (this.val + "" == this.item[i].value) {
          this.dictText = this.item[i].key;
        }
      }
    },
  },
};
</script>

<style scoped></style>

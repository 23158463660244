import {
  urlWrapper,
  axiosPostFileWrapper,
  axiosPostWrapper,
  axiosGetWrapper,
  axiosDeleteWrapper,
  axiosPostJsonWrapper,
  axiosPutJsonWrapper,
  axiosPutWrappe
} from '@/api/index';

// 查询表单详情
export const getFormDefinition = (data, success, error, exception) => {
  axiosGetWrapper("/api/formDefinition/getByCodes", data, success, error, exception);
};

// 查询列表数据
export const getList = (data, success, error, exception) => {
  axiosGetWrapper("/api/formDefinition/list", data, success, error, exception);
};

// 新增
export const saveList = (data, success, error, exception) => {
  axiosPostJsonWrapper(
    "/api/formDefinition/save",
    data,
    success,
    error,
    exception
  );
};

// 复制
export const copyList = (data, success, error, exception) => {
  axiosPostJsonWrapper(
    "/api/formDefinition/copy",
    data,
    success,
    error,
    exception
  );
};

// 删除
export const removeList = (data, success, error, exception) => {
  axiosPostWrapper(
    "/api/formDefinition/removeForm",
    data,
    success,
    error,
    exception
  );
};

// 设计
export const designList = (data, success, error, exception) => {
  axiosPostWrapper(
    "/api/formDefinition/saveFormDesign",
    data,
    success,
    error,
    exception
  );
};

// 提交表单
export const saveForm = (data, success, error, exception) => {
  axiosPostWrapper(
    "/api/busObject/updateByInstId",
    data,
    success,
    error,
    exception
  );
};

// 修改表单
export const updateForm = (data, success, error, exception) => {
  axiosPostWrapper(
    "/api/fictitiousData/updateData",
    data,
    success,
    error,
    exception
  );
};

// 获取字段
export const getSyllable = (data, success, error, exception) => {
  axiosGetWrapper(
    "/api/formDefinition/getElementPower",
    data,
    success,
    error,
    exception
  );
};

// 根据流程id获取数据
export const getInstForm = (data, success, error, exception) => {
  axiosGetWrapper(
    "/api/busObject/detailByInstId",
    data,
    success,
    error,
    exception
  );
};

//根据业务对象获取元素接口
export const getFormElementByBo = (data, success, error, exception) => {
  axiosGetWrapper("/api/formDefinition/getFormElementByBo", data, success, error, exception);
};

//根据实体获取元素接口
export const getFormElementByTable = (data, success, error, exception) => {
  axiosGetWrapper("api/formDefinition/getFormElementByTable", data, success, error, exception);
};
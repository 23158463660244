<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="width"
    @close="$emit('close')"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
  >
    <div
      v-loading="loading"
      element-loading-text="拼命加载中..."
      class="tw-ml-20px tw-mr-20px"
    >
      <div class="page-agreement" style="height: 60%; margin: 15px">
        <section class="content-section" style="height: 89%">
          <el-form
            :model="searchForm"
            label-position="right"
            label-width="70px"
          >
            <el-row type="flex" justify="space-between" align="center">
              <el-col
                :span="8"
                v-for="(item, index) in searchField"
                :key="index"
              >
                <el-form-item
                  :label="item.name + ''"
                  style="margin-bottom: 10px"
                >
                  <el-input
                    :placeholder="'请输入' + item.name"
                    :maxlength="20"
                    v-model="searchForm[item.account]"
                    size="small"
                    @keyup.enter.native="searchButtonClicked"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item style="margin-bottom: 10px">
                  <pl-button
                    style="float: right"
                    size="small"
                    @click="
                      searchForm = {
                        page: 1,
                        size: 10,
                      };
                      fetchData();
                    "
                    >重置
                  </pl-button>
                  <pl-button
                    style="float: right; margin-right: 10px"
                    size="small"
                    type="primary"
                    @click="search"
                    >查询
                  </pl-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-table
            ref="opTable"
            :data="tableData"
            v-loading="tableLoading"
            element-loading-text="数据加载中......"
            @selection-change="handleSelectionChange"
            stripe
            border
            size="small"
            class="table-font diy_table_height"
            max-height="300"
          >
            <!-- <el-table-column
              label="#"
              width="100%"
              v-if="choiceType == 'single'"
            >
              <template slot-scope="scope">
                <el-radio
                  :label="scope.row.id"
                  v-model="curSelect"
                  @change.native="
                    handleSingleSelectionChange(scope.$index, scope.row)
                  "
                  >&nbsp;
                </el-radio>
              </template>
            </el-table-column> -->
            <el-table-column
              type="selection"
              width="100%"
              align="center"
            ></el-table-column>
            <!-- <el-table-column
              label="id"
              prop="id"
              width="100%"
              align="center"
            ></el-table-column> -->
            <el-table-column
              v-for="(item, index) in tableColumns"
              :prop="item.code"
              :label="item.name"
              align="center"
              :key="index"
            >
            </el-table-column>
          </el-table>
          <pagination
            v-if="tableData.length > 0"
            v-show="totalCount > 0"
            :total="totalCount"
            :page.sync="searchForm.page"
            :limit.sync="searchForm.size"
            @pagination="fetchData"
          />
          <!-- <div style="margin-top: 10px; margin-left: -5px">
            <el-pagination :page-sizes="[10, 50, 100, 150]" @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-count="pagination.pages" :total="pagination.total" :current-page.sync="pagination.current" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
          </div> -->
        </section>
        <el-row
          style="margin-top: 10px; text-align: right; justify-content: center"
        >
          <pl-button size="small" type="primary" @click="choiceData"
            >确定</pl-button
          >
          <pl-button size="small" @click="$emit('close')">取消</pl-button>
        </el-row>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import Pagination from "@/components/Pagination"
import tableCommon from "@/mixin/tableCommon"
import updateFormCommon from "@/mixin/updateFormCommon"
import { getAPiList, getSqlList } from "@/api/system/common.js"
export default {
  mixins: [tableCommon, updateFormCommon],
  components: { Pagination },
  created () {
    this.searchForm.instId = this.instId
    this.fetchData()
  },
  props: {
    instId:{
      type:String
    },
    //    类型（sql，api）
    type: {
      type: String,
      default: "api",
    },
    //    选择模式（single 单选，multiple 多选）
    choiceType: {
      type: String,
      default: "",
    },
    searchField: {
      type: Array,
      default: [],
    },
    tableColumns: {
      type: Array,
      default: [],
    },
    api: {
      type: String,
      default: "",
    },
    sql: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "30%",
    },
    id: {
      type: String,
      default: "",
    },
    updateType: {
      // 0.新增 1.修改 2.查看
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    select_name: { //选择时的唯一标识，默认为id
      type: String,
      default: 'id'
    }
  },
  data () {
    return {
      tableName: "opTable",
      tableData: [],
      searchForm: {
        page: 1,
        size: 10,
      },
      curSelect: [],
      newCurSelect: [],
      totalCount: 0,
      select_obj: {}, //当前页的
      my_select: {},
      change_page: false,
    }
  },
  watch: {
    change_page () {
      this.my_select = { ...this.my_select, ...this.select_obj }
    }
  },
  methods: {
    search () {
      this.searchButtonClicked()
    },
    getSelect () { //取出选中的值
      let arr = []
      let obj = { ...this.my_select, ...this.select_obj }
      for (var key in obj) {
        arr = [...arr, obj[key]]
      }
      return arr
    },
    handleSelectionChange (row) {
      if (this.change_page) {
        this.change_page = false
        return
      }
      let obj = {}
      row.forEach(item => {
        obj[item[this.select_name]] = item
      })
      this.select_obj = { ...obj }
    },
    initTable (refreshFlag) {
      let me = this
      if (refreshFlag) me.resetSearchButtonClicked()
    },
    choiceData () {
      let me = this
      this.curSelect = this.getSelect()
      if (!me.curSelect || me.curSelect.length <= 0) {
        me.errorMsg("请勾选数据")
        return
      }
      me.$emit("choiced", me.curSelect)
    },
    getData (propName, value) {
      let me = this
      for (var i = 0; i < me.tableData.length; i++) {
        let datum = me.tableData[i]
        if (datum[propName] == value) {
          return datum
        }
      }
      return null
    },
    fetchData () {
      let me = this
      // me.searchForm.size = me.pagination.limit;
      // me.searchForm.page = me.getPaginationSkip();
      me.tableLoading = true
      if (me.type == "api") {
        me.getApiData()
      } else if (me.type == "sql") {
        me.getSqlData()
      }
    },
    async getApiData () {
      let me = this
      if (JSON.stringify(this.select_obj) != "{}") {
        this.change_page = true
      }

      await getAPiList(
        me.api,
        me.searchForm,
        (response) => {
          me.setPaginationTotal(response)
          me.tableData = response.records
          me.totalCount = response.total
          me.tableLoading = false
          this.$nextTick(() => {
            let ids = Object.keys(this.my_select)
            if (ids) {
              let obj = {}
              me.tableData.forEach(item => {
                if (ids.includes(item[this.select_name] + '')) {
                  obj[item[this.select_name]] = item
                  delete this.my_select[item[this.select_name]]
                  this.$refs.opTable.toggleRowSelection(item, true)
                }
              })
              this.select_obj = obj
            }
          })
        },
        (error) => {
          this.$message({
            type: "error",
            message: error,
            duration: 2000,
            onClose: () => { },
          })
          // console.warn(error)
          me.tableLoading = false
          // this.errorMsg(error)
        },
        (exception) => {
          // this.$message({
          //   type: "error",
          //   message: '操作失败！',
          //   duration: 2000,
          //   onClose: () => { },
          // })
          console.log(exception)

          // console.warn(exception)
          me.tableLoading = false
        }
      )
    },
    async getSqlData () {
      let me = this
      await getSqlList(
        me.sql,
        me.searchForm,
        (response) => {
          me.setPaginationTotal(response)
          me.tableData = response.records
          me.totalCount = response.total
          me.tableLoading = false
        },
        (error) => {
          this.$message({
            type: "error",
            message: '操作失败！',
            duration: 2000,
            onClose: () => { },
          })
          // console.warn(error)
          me.tableLoading = false
          // this.errorMsg(error)
        },
        (exception) => {
          // this.$message({
          //   type: "error",
          //   message: '操作失败！',
          //   duration: 2000,
          //   onClose: () => { },
          // })
          console.log(exception)

          // console.warn(exception)
          me.tableLoading = false
        }
      )
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
//主页模块
export const homeArr = [{
  path: '/',
  name: '空白页面',
}, {
  path: '/user/info',
  name: '修改个人资料'
}, {
  path: '/user/password',
  name: '修改登录密码'
}]

//系统管理模块
export const systemArr = [{
  path: '/config',
  name: '参数管理',
  oname: '系统管理'
}, {
  path: '/menu',
  name: '功能树配置',
  oname: '系统管理',
  tname: '权限管理'
}, {
  path: '/role',
  name: '角色管理',
  oname: '系统管理',
  tname: '权限管理'
}, {
  path: '/organ',
  name: '组织管理'
}, {
  path: '/user',
  name: '用户管理',
  oname: '系统管理',
  tname: '权限管理'
}, {
  path: '/login_log',
  name: '登录日志',
  oname: '系统管理',
  tname: '日志管理'
}, {
  path: '/task_log',
  name: '任务日志',
  oname: '系统管理',
  tname: '日志管理'
}, {
  path: '/business_log',
  name: '操作日志',
  oname: '系统管理',
  tname: '日志管理'
}, {
  path: '/exception_log',
  name: '异常日志',
  oname: '系统管理',
  tname: '日志管理'
}, {
  path: '/task',
  name: '定时任务',
  oname: '系统管理',
}, {
  path: '/dict',
  name: '数据字典',
  oname: '系统管理',
}, {
  path: '/system/dataSourceTemplate',
  name: '数据库模板',
  oname: '系统管理',
}, {
  path: '/system/dataSourceManagement',
  name: '数据源管理',
  oname: '系统管理',
}]

//流程模块
export const processArr = [{
  path: '/process/manage/instance',
  name: '流程实例管理',
  oname: '流程管理',
  tname: '流程管理'
}, {
  path: '/process/manage/task',
  name: '任务列表',
  oname: '流程管理',
  tname: '流程管理'
}, {
  path: '/process/manage/list',
  name: '流程列表',
  oname: '流程管理',
  tname: '流程管理'
}, {
  path: '/process/manage/start',
  name: '申请页面'
}, {
  path: '/process/config/dialog',
  name: '对话框管理',
  oname: '流程管理',
  tname: '流程配置'
}, {
  path: '/process/config/serial',
  name: '流水号管理',
  oname: '流程管理',
  tname: '流程配置'
}, {
  path: '/process/config/variable',
  name: '全局变量管理',
  oname: '流程管理',
  tname: '流程配置'
}]

//表单业务模块
export const bussinessArr = [{
  path: '/form/template',
  name: '表单模板',
  oname: '表单管理',
  tname: '表单配置'
}, {
  path: '/form/definition',
  name: '表单定义',
  oname: '表单管理',
  tname: '表单配置'
}, {
  path: '/business/table',
  name: '业务实体管理',
  oname: '表单管理',
  tname: '表单配置'
}, {
  path: '/business/object',
  name: '业务对象管理',
  oname: '表单管理',
  tname: '表单配置'
}]

//工作台模块
export const workbenchArr = [{
  path: "/work/todo",
  name: "事项办理",
  tname: '事项管理',
  oname: "工作台"
}, {
  path: "/work/history",
  name: "办理历史",
  tname: '事项管理',
  oname: "工作台"
}, {
  path: "/workbench/index",
  name: "工作门户",
  oname: "工作台"
}, {
  path: "/workbench/apply/drafts",
  name: "草稿箱",
  tname: '事项申请',
  oname: "工作台"
}, {
  path: "/workbench/apply/phrase",
  name: "常用语",
  tname: '事项申请',
  oname: "工作台"
}, {
  path: "/workbench/manage",
  name: "消息管理",
  oname: "工作台"
}, {
  path: "/workbench/mailList",
  name: "通讯录",
  oname: "工作台"
}, {
  path: "/workbench/apply/initiate",
  name: "发起申请",
  tname: '事项申请',
  oname: "工作台"
}, {
  path: "/workbench/apply/history",
  name: "申请历史",
  tname: '事项申请',
  oname: "工作台"
},]


export const path = [
  ...homeArr,
  ...systemArr,
  ...processArr,
  ...bussinessArr,
  ...workbenchArr
]
import { urlWrapper, axiosPostFileWrapper, axiosPostWrapper, axiosGetWrapper, axiosDeleteWrapper, axiosPostJsonWrapper, axiosPutJsonWrapper, axiosPutWrappe } from '@/api/index';

//查询脚本列表
export const getList = (data, success, error, exception) => {
    axiosGetWrapper("/api/sys/script/list", data, success, error, exception);
};

//查询脚本详情
export const getInfo = (data, success, error, exception) => {
    axiosPostWrapper("/api/sys/script/one", data, success, error, exception);
};

//保存脚本
export const saveScript = (data, success, error, exception) => {
    axiosPostJsonWrapper("/api/sys/script/update", data, success, error, exception);
};

//修改系统脚本
export const scriptEdit = (data, success, error, exception) => {
    axiosPostJsonWrapper("/api/sys/script/edit", data, success, error, exception);
};

//脚本测试
export const testScript = (data, success, error, exception) => {
    axiosPostWrapper("/api/sys/script/testScript", data, success, error, exception);
};

//删除脚本
export const deleteScript = (data, success, error, exception) => {
    axiosPostWrapper("/api/sys/script/del", data, success, error, exception);
};
export const system = [{
    hash: "",
    path: "/transfer",
    name: "空白页",
    component: () => import("@/views/transfer")
  },
  {
    hash: "",
    path: "/menu",
    name: "功能树配置",
    component: () => import("@/views/system/funtionTree/index")
  },
  {
    hash: "",
    path: "/dict",
    name: "数据字典",
    component: () => import("@/views/system/dict/index")
  },
  {
    hash: "",
    path: "/task",
    name: "定时任务",
    component: () => import("@/views/system/job/index")
  },
  {
    hash: "",
    path: "/user",
    name: "用户管理",
    component: () => import("@/views/system/user/index")
  },
  {
    hash: "",
    path: "/role",
    name: "角色管理",
    component: () => import("@/views/system/role/index")
  },
  {
    hash: "",
    path: "/config",
    name: "参数配置",
    component: () => import("@/views/system/config/index")
  },
  {
    hash: "",
    path: "/exception_log",
    name: "异常日志",
    component: () => import("@/views/system/exlog/index")
  },
  {
    hash: "",
    path: "/login_log",
    name: "登录日志",
    component: () => import("@/views/system/loginLog/index")
  },
  {
    hash: "",
    path: "/business_log",
    name: "操作日志",
    component: () => import("@/views/system/oplog/index")
  },
  {
    hash: "",
    path: "/task_log",
    name: "任务日志",
    component: () => import("@/views/system/jobLog/index")
  },
  {
    hash: "",
    path: "/system/script",
    name: "脚本管理",
    component: () => import("@/views/system/script/index")
  },
  {
    hash: "",
    path: "/basic_data/area",
    name: "区域规划",
    component: () => import("@/views/system/areaManage/index")
  },
  {
    hash: "",
    path: "/system/link",
    name: "外链",
    component: () => import("@/views/link")
  },
  {
    hash: "",
    path: "/system/client",
    name: "客户端管理",
    component: () => import("@/views/system/client")
  },
  {
    hash: "",
    path: "/system/dataSourceManagement",
    name: "数据源管理",
    component: () => import("@/views/system/dataSourceManagement")
  },
  {
    hash: "",
    path: "/system/dataSourceTemplate",
    name: "数据库模板",
    component: () => import("@/views/system/dataSourceTemplate")
  },
];

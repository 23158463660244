<template>
  <div class="panel-tab__content">
    <div class="panel-tab__content--title">
      <span>
        <i class="el-icon-menu" style="margin-right: 8px; clolor: #555555"></i
        >脚本设置列表
      </span>
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="dialogConfigOpen"
        >添加脚本设置</el-button
      >
    </div>
    <el-table :data="scriptQueryList" size="small" border>
      <el-table-column label="类型" prop="scriptType">
        <template slot-scope="{ row }">
          <dict-text dictKey="process_script" :val="row.scriptType"></dict-text>
        </template>
      </el-table-column>
      <el-table-column label="脚本" prop="scriptString"
        ><template slot-scope="{ row }">
          <dict-text
            dictKey="process_variable"
            :val="row.scriptString"
          ></dict-text> </template
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-link type="primary" icon="el-icon-edit" @click="handleEdit(row)"
            >编辑</el-link
          >
          <el-link
            type="primary"
            icon="el-icon-delete"
            @click="handleDelete(row)"
            >删除</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="dialogConfig.dialogVisiable"
      :title="dialogConfig.isAdd == true ? '添加脚本设置' : '编辑脚本设置'"
      append-to-body
      :destroy-on-close="true"
    >
      <script-settings-edit
        v-if="dialogConfig.dialogVisiable"
        :script-info="dialogConfig.scriptInfo"
        :script-arr="scriptQueryList"
        @close="dialogConfig.dialogVisiable = false"
        @getScriptEditInfo="getScriptEditInfo"
      ></script-settings-edit>
    </el-dialog>
  </div>
</template>
<script>
import scriptSettingsEdit from "./script-settings-edit.vue"
export default {
  name: "scriptSettings",
  components: { scriptSettingsEdit },
  props: {
    businessObject: Object,
  },
  watch: {
    businessObject: {
      immediate: false,
      handler: function (val) {
        if (val) {
          this.$nextTick(() => this.initData())
        }
      },
    },
  },
  data () {
    return {
      dialogConfig: {
        dialogVisiable: false,
        isAdd: true,
        existsKey: "",
        scriptInfo: {
          scriptType: "",
          scriptString: "",
        },
      },
      scriptQueryList: [],
      scriptInfo: {
        preHandle: "",
        handled: "",
      },
    }
  },
  created () { },
  mounted () {
    this.initData()
  },
  methods: {
    initData () {
      this.bpmnElement = window.bpmnInstances.bpmnElement
      var baseInfo = JSON.parse(
        JSON.stringify(this.bpmnElement.businessObject)
      )
      //只绑定类型为流程的相关信息
      if (baseInfo.$type === "bpmn:UserTask") {
        this.scriptQueryList = []
        if (baseInfo.script && !(baseInfo.script === "")) {
          var script = JSON.parse(baseInfo.script)
          if (script != {}) {
            if (script.preHandle != "") {
              var model = {
                scriptType: "process_script_before",
                scriptString: script.preHandle,
              }
              this.scriptQueryList.push(model)
            }
            if (script.handled != "") {
              var model = {
                scriptType: "process_script_after",
                scriptString: script.handled,
              }
              this.scriptQueryList.push(model)
            }
          }
        }
      }
    },
    //添加脚本设置弹层
    dialogConfigOpen () {
      this.dialogConfig = {
        dialogVisiable: true,
        isAdd: true,
        scriptInfo: { scriptType: "", scriptString: "" },
      }
    },
    //从子表单页获取保存的数据
    getScriptEditInfo (data) {
      // console.log(JSON.stringify(data));
      this.dialogConfig.dialogVisiable = false
      if (this.scriptQueryList != []) {
        this.scriptQueryList.forEach((item) => {
          if (item.scriptType == data.scriptType) {
            this.scriptQueryList.splice(this.scriptQueryList.indexOf(item), 1)
          }
        })
      }
      this.scriptQueryList.push(data)
      this.updateProperties()
      // console.log(this.bpmnElement);
    },
    //编辑
    handleEdit (data) {
      this.dialogConfig = {
        dialogVisiable: true,
        isAdd: false,
        scriptInfo: data,
      }
    },
    //删除
    handleDelete (data) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonClass: "取消",
        type: "warning",
      }).then(() => {
        this.$message({
          type: "success",
          message: "删除成功！",
          duration: 1000,
          onClose: () => {
            this.scriptQueryList.splice(this.scriptQueryList.indexOf(data), 1)
          },
        })
      })
    },
    updateProperties () {
      if (this.scriptQueryList.length > 0) {
        this.scriptQueryList.forEach((item) => {
          if (item.scriptType == "process_script_before") {
            this.scriptInfo.preHandle = item.scriptString //前置
          } else if (item.scriptType == "process_script_after") {
            this.scriptInfo.handled = item.scriptString //后置
          }
        })
        const attrObj = Object.create(null)
        attrObj["script"] = JSON.stringify(this.scriptInfo)
        window.bpmnInstances.modeling.updateProperties(
          this.bpmnElement,
          attrObj
        )
      }
    },
  },
};
</script>

<template>
  <!--节点属性-->
  <div class="panel-tab__content">
    <el-form
      label-position="right"
      label-width="110px"
      :model="elementBaseInfo"
      style="margin: 15px"
      size="small"
      @submit.native.prevent
    >
      <el-row>
        <el-col>
          <el-form-item label="驳回后执行人：" prop="rejectedExecutor">
            <el-select
              v-model="elementBaseInfo.rejectedExecutor"
              @change="updateBaseInfo('rejectedExecutor')"
            >
              <el-option
                v-for="item in rejectedExecutorArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="驳回策略：" prop="rejectedStrategy">
            <el-select
              v-model="elementBaseInfo.rejectedStrategy"
              @change="updateRejectedStrategyChange"
            >
              <el-option
                v-for="item in rejectedStrategyArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        v-if="elementBaseInfo.rejectedStrategy == 1 && selectNodeArr.length > 0"
      >
        <el-col>
          <el-form-item label="选择节点：">
            <el-select
              filterable
              v-model="elementBaseInfo.selectNodeStr"
              @change="updateSelectNodeChange"
            >
              <el-option
                v-for="(item, index) in selectNodeArr"
                :key="index"
                :label="item.label + '/' + item.value"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="撤回策略：" prop="recallStrategy">
            <el-select
              v-model="elementBaseInfo.recallStrategy"
              @change="updateBaseInfo('recallStrategy')"
            >
              <el-option
                v-for="item in recallStrategyArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import convert from "xml-js" //引入json转换与高亮
export default {
  props: {
    businessObject: Object,
    type: String,
    idEditDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      elementBaseInfo: {},
      rejectedExecutorArr: [
        {
          label: "历史节点的处理人",
          value: 0,
        },
        {
          label: "任务节点配置处理主体",
          value: 1,
        },
      ], //驳回后执行人
      rejectedStrategyArr: [
        {
          label: "按照流程图定义",
          value: 0,
        },
        {
          label: "指定驳回节点",
          value: 1,
        },
      ], //驳回策略
      recallStrategyArr: [
        {
          label: "按照全局设置",
          value: 0,
        },
        {
          label: "无法撤回",
          value: 1,
        },
        {
          label: "可撤回",
          value: 2,
        },
      ], //撤回策略
      dialogNodeVisiable: false,
      selectNodeArr: [],
      selectNodeStr: "",
      selectedNode: "",
    }
  },
  watch: {
    businessObject: {
      immediate: true,
      handler: function (data) {
        if (data) {
          this.$nextTick(() => this.resetBaseInfo())
        }
      },
    },
  },
  methods: {
    resetBaseInfo () {
      this.bpmnElement = window?.bpmnInstances?.bpmnElement
      var baseInfo = JSON.parse(
        JSON.stringify(this.bpmnElement.businessObject)
      )
      if (baseInfo.$type == "bpmn:UserTask") {
        this.elementBaseInfo = baseInfo
        if (!baseInfo.rejectedExecutor) {
          this.elementBaseInfo.rejectedExecutor = 1
        }
        if (!baseInfo.rejectedStrategy) {
          this.elementBaseInfo.rejectedStrategy = 0
        } else {
          this.selectNodeArr = []
        }
        this.getNodeArr()

        if (!baseInfo.recallStrategy) {
          this.elementBaseInfo.recallStrategy = 0
        }
        if (!baseInfo.selectNodeStr) {
          this.elementBaseInfo.selectNodeStr = baseInfo.selectNodeStr
        }
      }
    },
    updateRejectedStrategyChange (data) {
      this.selectNodeArr = []
      if (data == 1) {
        this.getNodeArr()
      } else {
        this.elementBaseInfo.selectNodeStr = ""
        this.selectNodeArr = []
        this.updateBaseInfo("selectNodeStr")
      }
      this.updateBaseInfo("rejectedStrategy")
    },
    getNodeArr () {
      window.bpmnInstances.modeler.saveXML({ format: true }).then(({ xml }) => {
        this.selectNodeArr = []
        let xmlToJsonStr = convert.xml2json(xml, { spaces: 2 })
        let obj = JSON.parse(xmlToJsonStr)
        let elements = obj.elements[0].elements
        //let basicAttributeInfo = elements[0].attributes; //从生成的Json串中获取流程属性
        let elementAttributeList = elements[0].elements //从生成的Json串中获取组件属性

        let num = 0

        elementAttributeList.forEach((item) => {
          if (item.name == "bpmn2:userTask") {
            num = num + 1
          }
          if (
            item.name == "bpmn2:userTask" &&
            this.elementBaseInfo.id != item.attributes.id
          ) {
            var model = {
              value: item.attributes.id,
              label: item.attributes.name,
            }
            this.selectNodeArr.push(model)
          }
        })

        if (num > 1) {
          this.rejectedStrategyArr = [
            { label: "按照流程图定义", value: 0 },
            { label: "指定驳回节点", value: 1 },
          ]
        } else {
          this.rejectedStrategyArr = [{ label: "按照流程图定义", value: 0 }]
        }
      })
    },
    //选择节点事件
    updateSelectNodeChange (data) {
      if (this.selectNodeArr != [] && this.selectNodeArr.length > 0) {
        this.selectNodeArr.forEach((item) => {
          if (item.value == data) {
            this.elementBaseInfo.selectNodeStr = item.label + "/" + item.value
            this.updateBaseInfo("selectNodeStr")
          }
        })
      }
    },
    updateBaseInfo (key) {
      const attrObj = Object.create(null)
      attrObj[key] = this.elementBaseInfo[key]
      if (key === "id") {
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
          id: this.elementBaseInfo[key],
          di: { id: this.elementBaseInfo[key] },
        })
      } else {
        window.bpmnInstances.modeling.updateProperties(
          this.bpmnElement,
          attrObj
        )
      }
    },
  },
  beforeDestroy () {
    this.bpmnElement = null
  },
};
</script>

<template>
  <div class="process-panel__container" :style="{ width: `${this.width}px` }">
    <!-- Tabs标签页 -->
    <el-tabs v-model="tabsActiveName">
      <!-- 组件属性 -->
      <el-tab-pane label="组件属性" name="zjsx" :disabled="zjxsDisabled">
        <!-- 折叠面板 -->
        <el-collapse v-model="collapseActiveName">
          <!-- 基础设置 -->
          <el-collapse-item name="basicSetting">
            <div slot="title" class="panel-tab__title">
              <i class="el-icon-info"></i>基础设置
            </div>
            <element-base-info
              :id-edit-disabled="idEditDisabled"
              :business-object="elementBusinessObject"
              :type="elementType"
            />
          </el-collapse-item>
          <!--流转条件-->
          <el-collapse-item name="flowCondition" v-if="conditionFormVisible">
            <div slot="title" class="panel-tab__title">
              <i class="el-icon-s-promotion"></i>流转条件
            </div>
            <flow-condition
              :business-object="elementBusinessObject"
              :type="elementType"
            />
          </el-collapse-item>
          <!-- 处理主体设置 -->
          <el-collapse-item name="processBodySettings" v-if="formVisible">
            <div slot="title" class="panel-tab__title">
              <i class="el-icon-s-comment"></i>处理主体设置
            </div>
            <main-setting
              v-if="formVisible"
              :type="elementType"
              :business-object="elementBusinessObject"
            />
          </el-collapse-item>
          <!-- 表单权限 -->
          <el-collapse-item name="formPermissions" v-if="formVisible">
            <div slot="title" class="panel-tab__title">
              <i class="el-icon-s-order"></i>表单权限
            </div>
            <form-authority
              :type="elementType"
              :business-object="elementBusinessObject"
            />
          </el-collapse-item>
          <!-- 脚本设置 -->
          <el-collapse-item name="scriptSettings" v-if="formVisible" key="task">
            <div slot="title" class="panel-tab__title">
              <i class="el-icon-s-claim"></i>脚本设置
            </div>
            <script-settings :business-object="elementBusinessObject" />
          </el-collapse-item>
          <!-- 节点属性 -->
          <el-collapse-item name="nodeProperties" v-if="formVisible">
            <div slot="title" class="panel-tab__title">
              <i class="el-icon-message-solid"></i>节点属性
            </div>
            <node-pro-perties
              :business-object="elementBusinessObject"
              :type="elementType"
            />
          </el-collapse-item>
          <!-- 扩展属性 -->
          <el-collapse-item
            name="extendedAttributes"
            key="extensions"
            v-if="formVisible"
          >
            <div slot="title" class="panel-tab__title">
              <i class="el-icon-circle-plus"></i>扩展属性
            </div>
            <element-properties :id="elementId" :type="elementType" />
          </el-collapse-item>
        </el-collapse>
      </el-tab-pane>
      <!-- 流程属性 -->
      <el-tab-pane label="流程属性" name="lcsx" :disabled="lcsxDisabled">
        <el-collapse v-model="collapseFlowActiveName">
          <el-collapse-item name="basicSetting">
            <div slot="title" class="panel-tab__title">
              <i class="el-icon-info"></i>基础设置
            </div>
            <process-pro-perties
              :business-object="elementBusinessObject"
              :type="elementType"
              ref="processProperties"
            />
          </el-collapse-item>
          <el-collapse-item name="extendedAttributes">
            <div slot="title" class="panel-tab__title">
              <i class="el-icon-circle-plus"></i>扩展属性
            </div>
            <element-properties :id="elementId" :type="elementType" />
          </el-collapse-item>
        </el-collapse>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import elementBaseInfo from "./base/element-base-info" //基本设置
import FlowCondition from "./flow/flow-condition" //条件流转
import mainSetting from "./main/main-setting" //主体设置
import formAuthority from "./form/form-authority" //表单权限
import scriptSettings from "./script/script-settings" //脚本设置
import nodeProPerties from "./node/node-properties" //节点属性
import elementProperties from "./base/element-Properties" //流程属性-扩展属性
import processProPerties from "./base/process-properties" //流程属性-基础设置

export default {
  name: "MyPropertiesPanel",
  components: {
    elementBaseInfo,
    FlowCondition,
    mainSetting,
    formAuthority,
    scriptSettings,
    nodeProPerties,
    processProPerties,
    elementProperties,
  },
  componentName: "MyPropertiesPanel",
  props: {
    bpmnModeler: Object,
    prefix: {
      type: String,
      default: "activiti", //默认activiti
    },
    width: {
      type: Number,
      default: 480,
    },
    idEditDisabled: {
      type: Boolean,
      default: false,
    },
  },
  provide () {
    return {
      prefix: this.prefix,
      width: this.width,
    }
  },
  data () {
    return {
      tabsActiveName: "zjsx", //Tabs标签页默认值，默认为组件属性
      collapseActiveName: [
        "basicSetting",
      ], //组件属性折叠面板，默认全部展开
      collapseFlowActiveName: ["basicSetting", "extendedAttributes"], //流程属性折叠面板，默认全部展开
      elementId: "",
      elementType: "",
      elementBusinessObject: {}, // 元素 businessObject 镜像，提供给需要做判断的组件使用
      conditionFormVisible: false, // 流转条件设置
      formVisible: false, // 表单配置
      zjxsDisabled: false, //组件tabs是否可点击
      lcsxDisabled: false, //流程tabs是否可点击
    }
  },
  watch: {
    elementId: {
      handler () {
        this.collapseActiveName = [
          "basicSetting",
          "nodeProperties"
        ]
      },
    },
  },
  created () {
    this.initModels()
  },
  methods: {
    initModels () {
      // 初始化 modeler 以及其他 moddle
      if (!this.bpmnModeler) {
        // 避免加载时 流程图 并未加载完成
        this.timer = setTimeout(() => this.initModels(), 10)
        return
      }
      if (this.timer) {
        clearTimeout(this.timer)
      }
      window.bpmnInstances = {
        modeler: this.bpmnModeler,
        modeling: this.bpmnModeler.get("modeling"),
        moddle: this.bpmnModeler.get("moddle"),
        eventBus: this.bpmnModeler.get("eventBus"),
        bpmnFactory: this.bpmnModeler.get("bpmnFactory"),
        elementRegistry: this.bpmnModeler.get("elementRegistry"),
        replace: this.bpmnModeler.get("replace"),
        selection: this.bpmnModeler.get("selection"),
      }
      // this.bpmnModeler.on("self.event", (e, el) => {
      //   console.log("self event", e, el);
      // });
      this.getActiveElement()

      document.getElementsByClassName(
        "bpmn-icon-start-event-none"
      )[0].innerHTML = "<br/><span  class='nodeStyle'>开始节点</span>"

      document.getElementsByClassName("bpmn-icon-end-event-none")[0].innerHTML =
        "<br/><span  class='nodeStyle'>结束节点</span>"

      document.getElementsByClassName("bpmn-icon-gateway-none")[0].innerHTML =
        "<br/><span  class='nodeStyle'>流转网关</span>"

      document.getElementsByClassName("bpmn-icon-user-task")[0].innerHTML =
        "<br/><span  class='nodeStyle'>用户任务</span>"
    },
    getActiveElement () {
      // 初始第一个选中元素 bpmn:Process
      this.initFormOnChanged(null)
      this.bpmnModeler.on("import.done", (e) => {
        this.initFormOnChanged(null)
      })
      // 监听选择事件，修改当前激活的元素以及表单
      this.bpmnModeler.on("selection.changed", ({ newSelection }) => {
        this.initFormOnChanged(newSelection[0] || null)
      })
      this.bpmnModeler.on("element.changed", ({ element }) => {
        // 保证 修改 "默认流转路径" 类似需要修改多个元素的事件发生的时候，更新表单的元素与原选中元素不一致。
        if (element && element.id === this.elementId) {
          this.initFormOnChanged(element)
        }
      })
    },
    // 初始化数据
    initFormOnChanged (element) {
      let activatedElement = element
      if (!activatedElement) {
        activatedElement =
          window.bpmnInstances.elementRegistry.find(
            (el) => el.type === "bpmn:Process"
          ) ??
          window.bpmnInstances.elementRegistry.find(
            (el) => el.type === "bpmn:Collaboration"
          )
      }
      if (!activatedElement) {
        return
      }
      window.bpmnInstances.bpmnElement = activatedElement
      this.bpmnElement = activatedElement
      this.elementId = activatedElement.id
      this.elementType = activatedElement.type.split(":")[1]
      this.elementBusinessObject = JSON.parse(
        JSON.stringify(activatedElement.businessObject)
      )

      // console.log(
      //   "activatedElement.source :" + JSON.stringify(activatedElement.source)
      // );

      //控制网关流转条件
      if (
        this.elementType == "SequenceFlow" &&
        activatedElement.source &&
        activatedElement.source.type.indexOf("ExclusiveGateway") > -1
      ) {
        this.conditionFormVisible = true
      } else {
        this.conditionFormVisible = false
      }

      //控制用户任务属性节点
      if (this.elementType === "UserTask") {
        this.formVisible = true
      } else {
        this.formVisible = false
      }

      //控制tabs显示
      if (this.elementType === "Process") {
        //点击画布，定位到流程属性
        this.tabsActiveName = "lcsx"
        this.zjxsDisabled = true
        this.lcsxDisabled = false
      } else {
        //点击节点，定位到组件属性
        this.tabsActiveName = "zjsx"
        this.lcsxDisabled = true
        this.zjxsDisabled = false
      }
    },
    beforeDestroy () {
      window.bpmnInstances = null
    },
    validateForm () {
      this.$refs.processProperties.validateForm()
    },
  },
};
</script>
<style>
.bjs-powered-by {
  display: none;
}
.bjs-container .djs-palette {
  top: 0px !important;
  width: 80px;
  height: 350px;
}

.djs-palette-entries {
  width: 100%;
  margin: auto;
}

.djs-palette .entry {
  width: 100% !important;
  height: 60px !important;
  line-height: 60px !important;
}
.nodeStyle {
  font-size: 12px;
  position: absolute;
  top: 25px;
  left: 15px;
}
.entry {
  margin-top: 15px;
}
</style>
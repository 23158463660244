import { render, staticRenderFns } from "./choiceTable.vue?vue&type=template&id=1b2e4501&scoped=true"
import script from "./choiceTable.vue?vue&type=script&lang=js"
export * from "./choiceTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b2e4501",
  null
  
)

export default component.exports
/**
 * 比较时间大小 d1比d2大吗
 * @param d1
 * @param d2
 * @returns {boolean}
 * @constructor
 */
export const compareDate = (d1, d2) => {
  return new Date(d1.replace(/-/g, "/")) > new Date(d2.replace(/-/g, "/"));
};

export const compareDate2 = (d1, d2) => {
  return new Date(d1.replace(/-/g, "/")) > d2;
};

export const compareNowDate = d1 => {
  return new Date(d1.replace(/-/g, "/")) > new Date();
};

export const compareNowDate2 = d1 => {
  return new Date(d1) > new Date();
};

export const isToday = date => {
  if (!date) {
    return false;
  }
  var d = new Date(date.toString());
  var todaysDate = new Date();
  if (d.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
    return true;
  } else {
    return false;
  }
};

export const datePickerOption = {
  shortcuts: [
    {
      text: "今天",
      onClick(picker) {
        picker.$emit("pick", new Date());
      }
    },
    {
      text: "昨天",
      onClick(picker) {
        const date = new Date();
        date.setTime(date.getTime() - 3600 * 1000 * 24);
        picker.$emit("pick", date);
      }
    },
    {
      text: "一周前",
      onClick(picker) {
        const date = new Date();
        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit("pick", date);
      }
    }
  ]
};

export const deepClone = data => {
  var type = getObjType(data);
  var obj;
  if (type === "array") {
    obj = [];
  } else if (type === "object") {
    obj = {};
  } else {
    //不再具有下一层次
    return data;
  }
  if (type === "array") {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]));
    }
  } else if (type === "object") {
    for (var key in data) {
      obj[key] = deepClone(data[key]);
    }
  }
  return obj;
};

export const getObjType = obj => {
  var toString = Object.prototype.toString;
  var map = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object"
  };
  if (obj instanceof Element) {
    return "element";
  }
  return map[toString.call(obj)];
};

export const containsNumber = function(str) {
  var reg = /\d/;
  return reg.test(str);
};

/**
 * @desc 计算年龄
 * 计算年份->计算月份->计算天数
 *
 * @date 2015-09-22
 */
export const getDiffYmdBetweenDate = function(sDate1, sDate2) {
  var fixDate = function(sDate) {
    var aD = sDate.split("-");
    for (var i = 0; i < aD.length; i++) {
      aD[i] = fixZero(parseInt(aD[i]));
    }
    return aD.join("-");
  };
  var fixZero = function(n) {
    return n < 10 ? "0" + n : n;
  };
  var fixInt = function(a) {
    for (var i = 0; i < a.length; i++) {
      a[i] = parseInt(a[i]);
    }
    return a;
  };
  var getMonthDays = function(y, m) {
    var aMonthDays = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (y % 400 == 0 || (y % 4 == 0 && y % 100 != 0)) {
      aMonthDays[2] = 29;
    }
    return aMonthDays[m];
  };
  var checkDate = function(sDate) {};
  var y = 0;
  var m = 0;
  var d = 0;
  var sTmp;
  var aTmp;
  sDate1 = fixDate(sDate1);
  sDate2 = fixDate(sDate2);
  if (sDate1 > sDate2) {
    sTmp = sDate2;
    sDate2 = sDate1;
    sDate1 = sTmp;
  }
  var aDate1 = sDate1.split("-");
  aDate1 = fixInt(aDate1);
  var aDate2 = sDate2.split("-");
  aDate2 = fixInt(aDate2);
  //计算相差的年份
  /*aTmp = [aDate1[0]+1,fixZero(aDate1[1]),fixZero(aDate1[2])];
   while(aTmp.join('-') <= sDate2){
   y++;
   aTmp[0]++;
   }*/
  y = aDate2[0] - aDate1[0];
  if (sDate2.replace(aDate2[0], "") < sDate1.replace(aDate1[0], "")) {
    y = y - 1;
  }
  //计算月份
  aTmp = [aDate1[0] + y, aDate1[1], fixZero(aDate1[2])];
  while (true) {
    if (aTmp[1] == 12) {
      aTmp[0]++;
      aTmp[1] = 1;
    } else {
      aTmp[1]++;
    }
    if ([aTmp[0], fixZero(aTmp[1]), aTmp[2]].join("-") <= sDate2) {
      m++;
    } else {
      break;
    }
  }
  //计算天数
  aTmp = [aDate1[0] + y, aDate1[1] + m, aDate1[2]];
  if (aTmp[1] > 12) {
    aTmp[0]++;
    aTmp[1] -= 12;
  }
  while (true) {
    if (aTmp[2] == getMonthDays(aTmp[0], aTmp[1])) {
      aTmp[1]++;
      aTmp[2] = 1;
    } else {
      aTmp[2]++;
    }
    sTmp = [aTmp[0], fixZero(aTmp[1]), fixZero(aTmp[2])].join("-");
    if (sTmp <= sDate2) {
      d++;
    } else {
      break;
    }
  }
  return { y: y, m: m, d: d };
};

/**
 * 清空对象属性
 * @param datas
 * @returns
 */
export const cleanObj = datas => {
  for (var a in datas) {
    if (datas[a] != null && datas[a] instanceof Array) {
      datas[a] = [];
    } else {
      datas[a] = "";
    }
  }
  return datas;
};

/**
 * 格式化时间戳
 * @param time
 * @returns {string}
 */
export const parseTimestamp = time => {
  let date = new Date(time);
  return (
    date.toLocaleDateString().replace(/\//g, "-") +
    " " +
    date.toTimeString().substr(0, 8)
  );
};

export const isEmpty = val => {
  return !val || val == "undefined" || val == null || val.length <= 0;
};

export const thousandBitSeparator = num => {
  return (
    num &&
    (num.toString().indexOf(".") != -1
      ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function($0, $1) {
          return $1 + ",";
        })
      : num.toString().replace(/(\d)(?=(\d{3}))/g, function($0, $1) {
          return $1 + ",";
        }))
  );
};

export const parseAryParam = ary => {
  var str = "";
  ary.forEach(m => {
    str += m + ",";
  });
  return str;
};

export const parseAryParamByKey = (ary, key) => {
  var str = "";
  ary.forEach(m => {
    str += m[key] + ",";
  });
  return str.substring(0, str.length - 1);
};

export const splitStrToAry = (str, splitStr) => {
  var ary = str.split(splitStr);
  for (var i = 0; i < ary.length; i++) {
    if (isEmpty(ary[i])) ary.splice(i, 1);
  }
  return ary;
};

export const aryToStr = (ary, splitStr) => {
  var str = "";
  for (var i = 0; i < ary.length; i++) {
    if (!isEmpty(ary[i])) str += ary + splitStr;
  }
  return str;
};

// 判断一个字符串是否都为数字
export const isDigit = strNum => {
  var match = strNum.match(/^\d+$/); //考虑小数写法 ^\d+(\.\d+)?$
  return match;
};

export const parseParam = (param, key) => {
  var paramStr = "";
  if (
    typeof param === "string" ||
    typeof param === "number" ||
    typeof param === "boolean"
  ) {
    paramStr += "&" + key + "=" + encodeURIComponent(param);
  } else {
    for (var i in param) {
      var k =
        key == null
          ? i
          : key + (param instanceof Array ? "[" + i + "]" : "." + i);
      if (param[i]) {
        //参数如果为空，则抛弃
        paramStr += "&" + parseParam(param[i], k);
      }
    }
  }
  return paramStr.substr(1);
};

/**
 * 数组元素去重
 */
export const uniqueAry = arr => {
  var res = [arr[0]];
  for (var i = 1; i < arr.length; i++) {
    var repeat = false;
    for (var j = 0; j < res.length; j++) {
      if (arr[i] === res[j]) {
        repeat = true;
        break;
      }
    }
    if (!repeat) {
      res.push(arr[i]);
    }
  }
  return res;
};

export const formatDate = (date, fmt) => {
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

/**
 * 构建操作对象
 * @param updateObj
 * @param route
 */
export const buildUpdateObj = (updateObj, route) => {
  if (!updateObj) {
    console.error("操作对象为空，构建操作对象失败！！");
    return;
  }
  updateObj.updateType = parseInt(route.query.updateType);
  if (updateObj.updateType == null) {
    console.error("路由参数有误，构建操作对象失败！！");
    return;
  }
  if (updateObj.updateType != 0) {
    updateObj.id = route.query.updateId;
  }
  switch (updateObj.updateType) {
    case 0: //新增
      break;
    case 1: //修改
      break;
    case 2: //查看
      break;
  }
};

/**
 * 格式化操作对象
 * @type {string}
 */
export const parseUpdateObj = updateObj => {
  for (var key in updateObj) {
    // debugger
    if (typeof updateObj[key] === "boolean") {
      if (updateObj[key]) {
        updateObj[key] = 1;
      } else {
        updateObj[key] = 0;
      }
    }
  }
  return updateObj;
};

//如果数字含有小数部分，那么可以将小数部分单独取出
//将小数部分的数字转换为字符串的方法：

var chnNumChar = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
var chnUnitSection = ["", "万", "亿", "万亿", "亿亿"];
var chnUnitChar = ["", "十", "百", "千"];

var numToChn = function(num) {
  var index = num.toString().indexOf(".");
  if (index != -1) {
    var str = num.toString().slice(index);
    var a = "点";
    for (var i = 1; i < str.length; i++) {
      a += chnNumChar[parseInt(str[i])];
    }
    return a;
  } else {
    return;
  }
};

//定义在每个小节的内部进行转化的方法，其他部分则与小节内部转化方法相同
function sectionToChinese(section) {
  var str = "",
    chnstr = "",
    zero = false,
    count = 0; //zero为是否进行补零， 第一次进行取余由于为个位数，默认不补零
  while (section > 0) {
    var v = section % 10; //对数字取余10，得到的数即为个位数
    if (v == 0) {
      //如果数字为零，则对字符串进行补零
      if (zero) {
        zero = false; //如果遇到连续多次取余都是0，那么只需补一个零即可
        chnstr = chnNumChar[v] + chnstr;
      }
    } else {
      zero = true; //第一次取余之后，如果再次取余为零，则需要补零
      str = chnNumChar[v];
      str += chnUnitChar[count];
      chnstr = str + chnstr;
    }
    count++;
    section = Math.floor(section / 10);
  }
  return chnstr;
}

//定义整个数字全部转换的方法，需要依次对数字进行10000为单位的取余，然后分成小节，按小节计算，当每个小节的数不足1000时，则需要进行补零
export const transformToChinese = num => {
  var a = numToChn(num);
  num = Math.floor(num);
  var unitPos = 0;
  var strIns = "",
    chnStr = "";
  var needZero = false;

  if (num === 0) {
    return chnNumChar[0];
  }
  while (num > 0) {
    var section = num % 10000;
    if (needZero) {
      chnStr = chnNumChar[0] + chnStr;
    }
    strIns = sectionToChinese(section);
    strIns += section !== 0 ? chnUnitSection[unitPos] : chnUnitSection[0];
    chnStr = strIns + chnStr;
    needZero = section < 1000 && section > 0;
    num = Math.floor(num / 10000);
    unitPos++;
  }

  return (chnStr + a + "").replace("undefined", "");
};

/**
 * 获取天数相差
 * @param dateStart
 * @param dateEnd
 * @returns {number}
 */
export const getDifferDayCount = (dateStart, dateEnd) => {
  var difValue =
    (new Date(dateEnd) - new Date(dateStart)) / (1000 * 60 * 60 * 24);
  return parseInt(difValue) + 1;
};

/**
 * 日期减天数
 * @param date
 * @param subtractDays
 */
export const subtractDay = (dd, subtractDays) => {
  var a = new Date(dd);
  a = a.valueOf();
  a = a + parseInt(subtractDays) * 24 * 60 * 60 * 1000;
  a = new Date(a);
  return a;
};

/*
  构建组织数据
 */
export const buildOrgansObj = (organTreeData, value) => {
  var organObj = { orgName: "", orgCode: "", orgPath: "", orgFullName: "" };
  for (var i = 0; i < organTreeData.length; i++) {
    if (value[0] === organTreeData[i].code) {
      organObj.orgFullName += organTreeData[i].name + "/";
      organObj.orgPath += organTreeData[i].code + "_";

      for (var j = 0; j < organTreeData[i].children.length; j++) {
        var item = organTreeData[i].children[j];
        if (value[1] === item.code) {
          organObj.orgFullName += item.name;
          organObj.orgPath += item.code;
          organObj.orgName = item.name;
          organObj.orgCode = item.code;
          break;
        }
      }
      break;
    }
  }
  return organObj;
};
/**
 * 合并两个对象的属性与值
 * sourceObj:复制的源对象（源对象中有的属性，目标对象无时，目标对象添加此属性，值为目标对象的值）
 * targetObj：复制后的目标对象（源对象中没有的属性，目标对象有时，目标对象此属性与值不变化）
 * flagBool:目标对象是否添加属性与值（源对象中有的属性，目标对象无时，目标对象添加此属性，值为源对象的值）
 * 注：只有当源对象与目标对象的key值与类型一致时，目标对象才会复制源对象的值
 */
export const objectCopy = (sourceObj, targetObj, flagBool) => {
  if (
    Object.prototype.toString.call(sourceObj) == "[object Object]" &&
    Object.prototype.toString.call(targetObj) == "[object Object]" &&
    Object.prototype.toString.call(flagBool) == "[object Boolean]"
  ) {
    for (var sourceKey in sourceObj) {
      var flag = flagBool;
      for (var targetkey in targetObj) {
        if (sourceKey == targetkey) {
          if (
            Object.prototype.toString.call(sourceObj[sourceKey]) ==
            Object.prototype.toString.call(targetObj[targetkey])
          ) {
            targetObj[targetkey] = sourceObj[sourceKey];
          }
          flag = false;
          break;
        }
      }
      if (flag) {
        targetObj[sourceKey] = sourceObj[sourceKey];
      }
    }
  }
  return targetObj;
};

export const dateDifference = function(sDate1) {
  var dateSpan, tempDate, iDays;
  sDate1 = Date.parse(sDate1);
  dateSpan = new Date() - sDate1;
  dateSpan = Math.abs(dateSpan);
  iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
  return iDays.toFixed(1);
};
/**
 * 对象拷贝
 * @param p
 * @param c
 * @returns {*|{}}
 */
export const deepCopy = (p, c) => {
  c = c || {};
  for (var i in p) {
    if (p.hasOwnProperty(i)) {
      if (typeof p[i] === "object") {
        c[i] = Array.isArray(p[i]) ? [] : {};
        deepCopy(p[i], c[i]);
      } else {
        c[i] = p[i];
      }
    }
  }
  return c;
};

/**
 * 获取字典项
 * @param item
 * @returns {*}
 */
export const findDictItem = item => {
  let me = this;
  var tmp = null;
  if (!item || item.length <= 0) {
    return null;
  }
  for (var i = 0; i < item.length; i++) {
    //        最底层无需遍历
    if (!item[i].childDict || item[i].childDict.length <= 0) {
      continue;
    }
    //        如果命中
    if (item[i].key === me.dictKey) {
      tmp = item[i].childDict;
      break;
    } else {
      if (!item[i].childDict || item[i].childDict.length <= 0) {
        continue;
      }
      tmp = findDictItem(item[i].childDict);
    }
  }
  return tmp;
};

export const idCardNoUtil = {
  /*省,直辖市代码表*/
  provinceAndCitys: {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    91: "国外"
  },

  /*每位加权因子*/
  powers: [
    "7",
    "9",
    "10",
    "5",
    "8",
    "4",
    "2",
    "1",
    "6",
    "3",
    "7",
    "9",
    "10",
    "5",
    "8",
    "4",
    "2"
  ],

  /*第18位校检码*/
  parityBit: ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"],

  /*性别*/
  genders: { male: "男", female: "女" },

  /*校验地址码*/
  checkAddressCode: function(addressCode) {
    var check = /^[1-9]\d{5}$/.test(addressCode);
    if (!check) return false;
    if (idCardNoUtil.provinceAndCitys[parseInt(addressCode.substring(0, 2))]) {
      return true;
    } else {
      return false;
    }
  },

  /*校验日期码*/
  checkBirthDayCode: function(birDayCode) {
    var check = /^[1-9]\d{3}((0[1-9])|(1[0-2]))((0[1-9])|([1-2][0-9])|(3[0-1]))$/.test(
      birDayCode
    );
    if (!check) return false;
    var yyyy = parseInt(birDayCode.substring(0, 4), 10);
    var mm = parseInt(birDayCode.substring(4, 6), 10);
    var dd = parseInt(birDayCode.substring(6), 10);
    var xdata = new Date(yyyy, mm - 1, dd);
    if (xdata > new Date()) {
      return false; //生日不能大于当前日期
    } else if (
      xdata.getFullYear() == yyyy &&
      xdata.getMonth() == mm - 1 &&
      xdata.getDate() == dd
    ) {
      return true;
    } else {
      return false;
    }
  },

  /*计算校检码*/
  getParityBit: function(idCardNo) {
    var id17 = idCardNo.substring(0, 17);
    /*加权 */
    var power = 0;
    for (var i = 0; i < 17; i++) {
      power += parseInt(id17.charAt(i), 10) * parseInt(idCardNoUtil.powers[i]);
    }
    /*取模*/
    var mod = power % 11;
    return idCardNoUtil.parityBit[mod];
  },

  /*验证校检码*/
  checkParityBit: function(idCardNo) {
    var parityBit = idCardNo.charAt(17).toUpperCase();
    if (idCardNoUtil.getParityBit(idCardNo) == parityBit) {
      return true;
    } else {
      return false;
    }
  },

  /*校验15位或18位的身份证号码*/
  checkIdCardNo: function(idCardNo) {
    //15位和18位身份证号码的基本校验
    var check = /^\d{15}|(\d{17}(\d|x|X))$/.test(idCardNo);
    if (!check) return false;
    //判断长度为15位或18位
    if (idCardNo.length == 15) {
      return idCardNoUtil.check15IdCardNo(idCardNo);
    } else if (idCardNo.length == 18) {
      return idCardNoUtil.check18IdCardNo(idCardNo);
    } else {
      return false;
    }
  },

  //校验15位的身份证号码
  check15IdCardNo: function(idCardNo) {
    //15位身份证号码的基本校验
    var check = /^[1-9]\d{7}((0[1-9])|(1[0-2]))((0[1-9])|([1-2][0-9])|(3[0-1]))\d{3}$/.test(
      idCardNo
    );
    if (!check) return false;
    //校验地址码
    var addressCode = idCardNo.substring(0, 6);
    check = idCardNoUtil.checkAddressCode(addressCode);
    if (!check) return false;
    var birDayCode = "19" + idCardNo.substring(6, 12);
    //校验日期码
    return idCardNoUtil.checkBirthDayCode(birDayCode);
  },

  //校验18位的身份证号码
  check18IdCardNo: function(idCardNo) {
    //18位身份证号码的基本格式校验
    var check = /^[1-9]\d{5}[1-9]\d{3}((0[1-9])|(1[0-2]))((0[1-9])|([1-2][0-9])|(3[0-1]))\d{3}(\d|x|X)$/.test(
      idCardNo
    );
    if (!check) return false;
    //校验地址码
    var addressCode = idCardNo.substring(0, 6);
    check = idCardNoUtil.checkAddressCode(addressCode);
    if (!check) return false;
    //校验日期码
    var birDayCode = idCardNo.substring(6, 14);
    check = idCardNoUtil.checkBirthDayCode(birDayCode);
    if (!check) return false;
    //验证校检码
    return idCardNoUtil.checkParityBit(idCardNo);
  },

  formateDateCN: function(day) {
    var yyyy = day.substring(0, 4);
    var mm = day.substring(4, 6);
    var dd = day.substring(6);
    return yyyy + "-" + mm + "-" + dd;
  },

  //获取信息
  getIdCardInfo: function(idCardNo) {
    var idCardInfo = {
      gender: "", //性别
      birthday: "" // 出生日期(yyyy-mm-dd)
    };
    if (idCardNo.length == 15) {
      var aday = "19" + idCardNo.substring(6, 12);
      idCardInfo.birthday = idCardNoUtil.formateDateCN(aday);
      if (parseInt(idCardNo.charAt(14)) % 2 == 0) {
        idCardInfo.gender = idCardNoUtil.genders.female;
      } else {
        idCardInfo.gender = idCardNoUtil.genders.male;
      }
    } else if (idCardNo.length == 18) {
      var aday = idCardNo.substring(6, 14);
      idCardInfo.birthday = idCardNoUtil.formateDateCN(aday);
      if (parseInt(idCardNo.charAt(16)) % 2 == 0) {
        idCardInfo.gender = idCardNoUtil.genders.female;
      } else {
        idCardInfo.gender = idCardNoUtil.genders.male;
      }
    }
    return idCardInfo;
  },

  /*18位转15位*/
  getId15: function(idCardNo) {
    if (idCardNo.length == 15) {
      return idCardNo;
    } else if (idCardNo.length == 18) {
      return idCardNo.substring(0, 6) + idCardNo.substring(8, 17);
    } else {
      return null;
    }
  },

  /*15位转18位*/
  getId18: function(idCardNo) {
    if (idCardNo.length == 15) {
      var id17 = idCardNo.substring(0, 6) + "19" + idCardNo.substring(6);
      var parityBit = idCardNoUtil.getParityBit(id17);
      return id17 + parityBit;
    } else if (idCardNo.length == 18) {
      return idCardNo;
    } else {
      return null;
    }
  }
};

/* 获取随机数 */
export const getRandom = len => {
  var code = "";
  var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9);
  for (var i = 0; i < len; i++) {
    var index = Math.floor(Math.random() * 9);
    code += random[index];
  }
  return code;
};

// 将图片转换为base64编码

export const imgToBase64 = url => {
  return new Promise(resolve => {
    const image = new Image();
    // 先设置图片跨域属性
    image.setAttribute('crossOrigin', 'anonymous');
    image.crossOrigin = 'anonymous'
    // 再给image赋值src属性，先后顺序不能颠倒
    image.src = url;
    image.onload = function() {
      const canvas = document.createElement("CANVAS");
      // 设置canvas宽高等于图片实际宽高
      canvas.width = image.width;
      canvas.height = image.height;
      canvas.getContext("2d").drawImage(image, 0, 0);
      // toDataUrl可以接收2个参数，参数一：图片类型，参数二： 图片质量0-1（不传默认为0.92）
      const dataURL = canvas.toDataURL("image/jpeg");
      resolve(dataURL);
    };
    image.onerror = () => {
      resolve({ message: "相片处理失败" });
    };
  });
};

// 这里我单独写一个函数返回一个
export const getBase64 = url=> { // file为我们上传钩子函数获取到的file对象
	return new Promise(function(resolve, reject) {
    let reader = new FileReader()
    let imgResult = '' // imgResult最终转换后的base64的值
    reader.readAsDataURL(url)
    reader.onload = function() {
      imgResult = reader.result + ''
    }
    reader.onerror = function(error) {
      reject(error)
    }
    reader.onloadend = function() {
      // 成功后调用resolve回传值
      resolve(imgResult)
    }
  })
}


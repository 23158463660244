<template>
  <!--处理主体设置-->
  <div class="panel-tab__content">
    <!--主体列表-->
    <el-button size="small" type="primary" @click="selectCurrentUser"
      >发起人</el-button
    >
    <el-button size="small" type="primary" @click="dialogUserVisible = true"
      >选择用户主体</el-button
    >
    <el-button size="small" type="primary" @click="dialogRoleVisible = true"
      >选择角色主体</el-button
    >
    <el-table :data="ruleList" size="small" border style="margin-top: 10px">
      <el-table-column label="主体类型" prop="rule" width="80">
        <template slot-scope="{ row }">
          {{ row.rule == "user" ? "用户" : "角色" }}
        </template>
      </el-table-column>
      <el-table-column label="包含主体" prop="description"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="{ row }">
          <el-link
            type="primary"
            v-if="currentUserVisible"
            icon="el-icon-edit"
            @click="
              row.rule == 'user'
                ? (dialogUserVisible = true)
                : (dialogRoleVisible = true)
            "
            >编辑</el-link
          >
          <el-link
            type="primary"
            icon="el-icon-delete"
            @click="handleDelete(row)"
            >删除</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <!--无处理人-->
    <!-- <el-form label-position="right" label-width="110px" size="small" style="margin-top: 15px">
      <el-row>
        <el-col>
          <el-form-item label="无处理人时：">
            <el-select v-model="elementInfo.noHandlePerson" @change="updatePersonChange">
              <el-option v-for="item in noHandlePersonArr" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form> -->
    <!--节点操作-->
    <div class="panel-tab__content--title">
      <span
        ><i class="el-icon-menu" style="margin-right: 8px; color: #555555"></i
        >节点操作</span
      >
    </div>
    <el-form size="small" label-position="right" label-width="110px">
      <el-row>
        <el-col>
          <el-form-item label="功能按钮：">
            <el-tag
              v-for="(item, index) in showChoosedTags"
              :key="index"
              :type="item.type"
              closable
              @close="handleChoosedTagClose(item)"
            >
              {{ item.label }}</el-tag
            >
            <el-tooltip
              content="功能按钮管理"
              placement="bottom"
              effect="light"
            >
              <el-button
                type="primary"
                icon="el-icon-plus"
                size="mini"
                style="margin-left: 10px"
                @click="handleDialogFuncButton()"
              ></el-button>
            </el-tooltip>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!--选择用户主体-->
    <user-choice
      :choiceType="choiceType"
      :dialogVisible="dialogUserVisible"
      @dialogVisibleClose="dialogUserVisible = false"
      @choiced="selectUserData"
    ></user-choice>
    <!--选择角色主体-->
    <role-choice
      :choiceType="choiceType"
      :dialogVisible="dialogRoleVisible"
      @dialogVisibleClose="dialogRoleVisible = false"
      @choiced="selectRoleData"
    ></role-choice>
    <!--添加/修改功能按钮弹层-->
    <el-dialog
      :visible.sync="dialogFuncButtonConfig.dialogVisiable"
      title="功能按钮管理"
      width="600px"
      append-to-body
    >
      <el-form
        style="margin: 15px"
        label-position="right"
        label-width="110px"
        size="small"
      >
        <el-row>
          <el-col>
            <el-form-item label="功能按钮：">
              <el-tag
                v-for="(item, index) in this.dialogFuncButtonConfig.allTags"
                :key="index"
                :type="item.type"
                @click="chooseTags(item)"
              >
                {{ item.label }}</el-tag
              >
              <br />
              <span style="color: orange">请点击相应按钮进行选择</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="已选功能按钮：">
              <el-tag
                v-for="(item, index) in this.dialogFuncButtonConfig.choosedTags"
                :key="index"
                :type="item.type"
                closable
                @close="cancelTags(item)"
              >
                {{ item.label }}</el-tag
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item style="text-align: right">
              <el-button type="primary" @click="dialogFuncButtonSave()"
                >确定</el-button
              >
              <el-button @click="dialogFuncButtonCancel()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import userChoice from "@/components/choice/userChoice.vue";
import roleChoice from "@/components/choice/roleChoice.vue";
import { getStor } from "@/utils/stor.js";
export default {
  components: { userChoice, roleChoice },
  props: {
    businessObject: Object,
  },
  data() {
    return {
      ruleList: [],
      choiceType: "multiple",
      mainTypeArr: [
        { label: "用户", value: "user" },
        { label: "角色", value: "role" },
      ], //主体类型集合
      elementInfo: { noHandlePerson: "" },
      noHandlePersonArr: [
        {
          label: "无处理",
          value: "0",
        },
        {
          label: "自动跳过当前节点",
          value: "1",
        },
      ],
      dialogConfig: {
        dialogVisiable: false, //控制添加主体设置弹层显示或隐藏
        isAdd: true, //是否添加，控制弹层标题
      }, //弹层配置
      dialogInfo: {}, //弹层内部表单model
      dialogFuncButtonConfig: {
        dialogVisiable: false,
        isAdd: true,
        allTags: [
          { name: "handle", label: "办理", type: "" },
          { name: "agree", label: "同意", type: "" },
          { name: "turnDown", label: "驳回", type: "" },
          { name: "shutdown", label: "终止", type: "" },
          { name: "assign", label: "指派处理人", type: "" },
          //{ name: "recycle", label: "撤回", type: "" },
          //{ name: "approvalHistory", label: "审批历史", type: "" },
          //{ name: "flowChart", label: "流程图", type: "" },
          //{ name: "saveDraft", label: "保存草稿", type: "" },
        ],
        choosedTags: [], //已选
      }, //添加功能按钮弹层配置
      showChoosedTags: [], //最后要显示的功能按钮
      //节点操作按钮
      btnList: {
        handle: 0, //办理
        agree: 1, //同意
        turnDown: 1, //驳回
        shutdown: 0, //终止
        assign: 0, //指派处理人
        //recycle: 0, //撤回
        //approvalHistory: 0, //审批历史
        //flowChart: 0, //流程图
        //saveDraft: 0, //保存草稿
      },
      dialogUserVisible: false,
      dialogRoleVisible: false,
      currentUserVisible: true,
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    businessObject: {
      immediate: false,
      handler: function (val) {
        if (val) {
          this.$nextTick(() => this.initData());
        }
      },
    },
  },
  methods: {
    //初始化页面
    initData() {
      this.bpmnElement = window.bpmnInstances.bpmnElement;
      var baseInfo = JSON.parse(
        JSON.stringify(this.bpmnElement.businessObject)
      );

      //只绑定类型为流程的相关信息
      if (baseInfo.$type === "bpmn:UserTask") {
        //处理主体设置
        if (baseInfo.ruleList && baseInfo.ruleList != "") {
          this.ruleList = JSON.parse(baseInfo.ruleList);
          this.ruleList.forEach((item) => {
            if (item.description.indexOf("发起人") > -1) {
              this.currentUserVisible = false;
            } else {
              this.currentUserVisible = true;
            }
          });
        } else {
          this.ruleList = [];
        }

        //处理无处理人时
        // if (baseInfo.executeSubjectEmpty) {
        //   this.elementInfo.noHandlePerson = baseInfo.executeSubjectEmpty;
        // } else {
        //   this.elementInfo.noHandlePerson = "";
        // }

        this.dialogFuncButtonConfig.choosedTags = [];
        this.showChoosedTags = [];

        //处理按钮授权
        if (baseInfo.btnList && !(baseInfo.btnList === "")) {
          this.btnList = JSON.parse(baseInfo.btnList);
          let newArr = [];
          this.dialogFuncButtonConfig.allTags.forEach((item) => {
            if (this.btnList["" + item.name + ""] == 1) {
              newArr.push(item); //将选中的按钮添加到已选集合和显示集合中
            }
          });
          this.dialogFuncButtonConfig.choosedTags =
            this.dialogFuncButtonConfig.choosedTags.concat(newArr);
          this.showChoosedTags = this.showChoosedTags.concat(newArr);
        } else {
          this.resetBtnList();
        }
      }
    },
    //选择当前登录用户主体
    selectCurrentUser() {
      this.currentUserVisible = false;
      this.ruleList = []; //先清空原来的集合
      let { UserName, LoginCode, UserCode } = getStor("sysUserInfo");
      let ruleInfo = {
        rule: "user",
        description: "[用户]发起人",
        source: [
          {
            code: LoginCode,
            name: UserName,
            type: "user",
          },
        ],
      };
      this.ruleList.push(ruleInfo);
      this.updateProperties(2);
    },
    //返回选择的用户主体数据
    selectUserData(data) {
      this.currentUserVisible = true;
      var userData = [];
      userData = data;
      if (userData != [] && userData.length > 0) {
        var ruleInfo = {
          rule: "user",
          description: "",
          source: [],
        };
        var userList = [];
        userData.forEach((item) => {
          ruleInfo.description = ruleInfo.description + item.name + ",";
          let { account, name, identityType } = item;
          var userInfo = {
            code: account,
            name: name,
            type: identityType,
          };
          userList.push(userInfo);
        });
        ruleInfo.description = "[用户]" + ruleInfo.description;
        ruleInfo.source = userList;
        this.checkRuleList(ruleInfo);
      }
      this.dialogUserVisible = false;
    },
    //返回选择的角色主体数据
    selectRoleData(data) {
      this.currentUserVisible = true;
      var roleData = [];
      roleData = data;
      if (roleData != [] && roleData.length > 0) {
        var ruleInfo = {
          rule: "role",
          description: "",
          source: [],
        };
        var roleList = [];
        roleData.forEach((item) => {
          ruleInfo.description = ruleInfo.description + item.name + ",";
          let { code, name, identityType } = item;
          var roleInfo = {
            code: code,
            name: name,
            type: identityType,
          };
          roleList.push(roleInfo);
        });
        ruleInfo.description = "[角色]" + ruleInfo.description;
        ruleInfo.source = roleList;
        this.checkRuleList(ruleInfo);
      }
      this.dialogRoleVisible = false;
    },
    //主体列表删除
    handleDelete(data) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.ruleList.splice(this.ruleList.indexOf(data), 1);
          this.updateProperties(2);
          // if (this.disabledUser && this.disabledRole) {
          //   //删除当前选定用户，删除完成后，恢复该按钮为可操作
          //   this.disabledUser = false;
          //   this.disabledRole = false;
          // } else {
          //   this.disabledCurrentUser = false;
          // }
        })
        .catch(() => {});
    },
    //验证主体列表
    checkRuleList(data) {
      var flag = false;
      if (this.ruleList != [] && this.ruleList.length > 0) {
        for (let item of this.ruleList) {
          if (item.rule == data.rule) {
            item.rule = data.rule;
            item.description = data.description;
            item.source = data.source;
            flag = true;
            break;
          }
        }
      }
      if (!flag) {
        this.ruleList.push(data);
      }
      this.updateProperties(2);
    },
    //打开功能按钮弹层
    handleDialogFuncButton() {
      this.dialogFuncButtonConfig.dialogVisiable = true;
      if (this.dialogFuncButtonConfig.choosedTags.length > 0) {
        this.dialogFuncButtonConfig.isAdd = false;
      } else {
        this.dialogFuncButtonConfig.isAdd = true;
      }
    },
    //移除功能按钮Tags
    handleChoosedTagClose(data) {
      this.$confirm("确定要移除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //判断当前功能按钮数量，如果仅剩一个，则提示至少保留一个操作按钮
        if (this.showChoosedTags.length == 1) {
          this.$message({
            type: "warning",
            message: "至少保留一项功能按钮！",
            duration: 2000,
          });
        } else {
          this.$message({
            type: "success",
            message: "移除成功!",
            duration: 1000,
            onClose: () => {
              this.showChoosedTags.splice(
                this.showChoosedTags.indexOf(data),
                1
              );
              this.dialogFuncButtonConfig.choosedTags = [];
              let arr = [];
              this.showChoosedTags.forEach((item) => {
                arr.push(item);
              });
              this.dialogFuncButtonConfig.choosedTags = arr;
              this.btnList["" + data.name + ""] = 0; //将移除的按钮置为0
              this.updateProperties(1);
            },
          });
        }
      });
    },
    //【功能按钮弹层】选择功能按钮事件
    chooseTags(data) {
      let { choosedTags } = this.dialogFuncButtonConfig;
      if (choosedTags.indexOf(data) > -1) {
        if (choosedTags.length == 1) {
          this.$message({
            type: "warning",
            message: "至少保留一项功能按钮！",
            duration: 2000,
          });
        } else {
          choosedTags.splice(choosedTags.indexOf(data), 1); //存在则删除
        }
      } else {
        choosedTags.push(data); //不存在则添加
      }
    },
    //【功能按钮弹层】点击已选择功能按钮事件、移除
    cancelTags(data) {
      let { choosedTags } = this.dialogFuncButtonConfig;
      if (choosedTags.length == 1) {
        this.$message({
          type: "warning",
          message: "至少保留一项功能按钮！",
          duration: 2000,
        });
      } else {
        choosedTags.splice(choosedTags.indexOf(data), 1);
      }
    },
    //【功能按钮弹层】保存按钮事件
    dialogFuncButtonSave() {
      if (this.dialogFuncButtonConfig.choosedTags.length > 0) {
        //首先清空最终要显示的 已选择按钮 集合
        this.showChoosedTags = [];

        //然后把 btnlist 中的按钮属性全部置为未选择
        this.btnList.handle = 0;
        this.btnList.turnDown = 0;
        this.btnList.agree = 0;
        this.btnList.shutdown = 0;
        this.btnList.assign = 0;

        //然后根据已选择的按钮，添加到最终要显示的集合中，并重新复制给 btnList
        this.dialogFuncButtonConfig.choosedTags.forEach((item) => {
          this.showChoosedTags.push(item);
          this.btnList["" + item.name + ""] = 1; //1：表示已选择，0：未选择
        });
        this.updateProperties(1);
      } else {
        this.resetBtnList();
      }
      this.dialogFuncButtonConfig.dialogVisiable = false;
    },
    //【功能按钮弹层】取消按钮事件
    dialogFuncButtonCancel() {
      this.dialogFuncButtonConfig.dialogVisiable = false;
      if (this.showChoosedTags.length <= 0) {
        this.dialogFuncButtonConfig.choosedTags = []; //清空已选中内容
      }
    },
    //【功能按钮弹层】重置
    resetBtnList() {
      this.btnList.handle = 0;
      this.btnList.turnDown = 1;
      this.btnList.agree = 1;
      this.btnList.shutdown = 0;
      this.btnList.assign = 0;
      // this.btnList.recycle = 0;
      // this.btnList.approvalHistory = 0;
      // this.btnList.flowChart = 0;
      // this.btnList.saveDraft = 0;
      this.updateProperties(1);
    },
    updateProperties(type) {
      const attrObj = Object.create(null);
      if (type == 1) {
        //按钮权限设置
        attrObj["btnList"] = JSON.stringify(this.btnList);
      } else if (type == 2) {
        //主体设置
        if (this.ruleList != [] && this.ruleList.length > 0) {
          attrObj["ruleList"] = JSON.stringify(this.ruleList);
        } else {
          attrObj["ruleList"] = "";
        }
      } else if (type == 3) {
        //无处理人时
        attrObj["executeSubjectEmpty"] = this.elementInfo.noHandlePerson;
      }
      window.bpmnInstances.modeling.updateProperties(this.bpmnElement, attrObj);
    },
    //无处理人时
    updatePersonChange() {
      this.updateProperties(3);
    },
  },
};
</script>
<style>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-tag {
  cursor: pointer;
  margin-top: 5px;
}
</style>

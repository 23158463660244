import {
  urlWrapper,
  axiosPostFileWrapper,
  axiosPostWrapper,
  axiosGetWrapper,
  axiosDeleteWrapper,
  axiosPostJsonWrapper,
  axiosPutJsonWrapper,
  axiosPutWrappe
} from '@/api/index'
import axios from 'axios'

import {
  Base64
} from "js-base64"

export const getList = (data, success, error, exception) => {
  axiosGetWrapper('/api/sys/job/log/list', data, success, error, exception)
}

export const getAPiList = (api, data, success, error, exception) => {
  axiosGetWrapper(api, data, success, error, exception)
}

export const getSqlList = (sql, data, success, error, exception) => {
  axiosPostWrapper('api/sys/customDialog/exec/sql', {
    sql: Base64.encode(sql),
    ...data
  }, success, error, exception)
}

export const getAreaTreeData = (data, success, error, exception) => {
  axiosGetWrapper('/api/sys/area/getAreaTree', data, success, error, exception)
}

export const getEmplList = (data, success, error, exception) => {
  axiosGetWrapper('/api/external/data/employee', data, success, error, exception)
}

export const getApprovalRecord = (data, success, error, exception) => {
  axiosGetWrapper('/api/zyz/data/getList', data, success, error, exception)
}

export const getEmplList1 = (data, success, error, exception) => {
  axiosGetWrapper('/api/fictitiousData/getList/biz_person_list', data, success, error, exception)
}

export const delEmplList = (data, success, error, exception) => {
  axiosPostWrapper('/api/fictitiousData/deleteTableData/biz_person_list', data, success, error, exception)
}

export const cancelMessage = (data, success, error, exception) => {
  axiosPostWrapper('/api/zyz/data/startCancellation', data, success, error, exception)
}

export const revokeMessage = (data, success, error, exception) => {
  axiosPostWrapper('/api/zyz/data/startRevoke', data, success, error, exception)
}

// 保存反馈信息
export const getProblem = (data, success, error, exception) => {
  axiosPostWrapper('/problem/save', data, success, error, exception)
}
import { urlWrapper, axiosPostFileWrapper, axiosPostWrapper, axiosGetWrapper, axiosDeleteWrapper, axiosPostJsonWrapper, axiosPutJsonWrapper, axiosPutWrappe } from '@/api/index'

//【流程定义】添加
export const saveInfo = (data, success, error, exception) => {
  axiosPostJsonWrapper('/api/bpm/definition/save', data, success, error, exception)
}

//流程复制
export const saveCopyInfo = (data, success, error, exception) => {
  axiosPostJsonWrapper('/api/bpm/definition/copy', data, success, error, exception)
}

//获取流程列表
export const getList = (data, success, error, exception) => {
  axiosGetWrapper("/api/bpm/definition/list", data, success, error, exception)
}

//获取流程详情
export const getInfo = (data, success, error, exception) => {
  axiosPostWrapper("/api/bpm/definition/get", data, success, error, exception)
}

//【流程列表】删除一条数据
export const deleteInfo = (data, success, error, exception) => {
  axiosPostWrapper("/api/bpm/definition/remove", data, success, error, exception)
}

//【流程列表/流程授权】设置流程权限
export const setProcessAuthority = (data, success, error, exception) => {
  axiosPostJsonWrapper("/api/bpm/definition/auth/processManagement", data, success, error, exception)
}

//【流程列表/流程授权】获取已授权流程主体
export const getProcessAuthList = (data, success, error, exception) => {
  axiosGetWrapper("/api/bpm/definition/auth/list", data, success, error, exception)
}

//【流程列表/流程授权】删除已授权的流程主体
export const deleteProcessAuth = (data, success, error, exception) => {
  axiosPostWrapper("/api/bpm/definition/auth/remove", data, success, error, exception)
}

//【流程列表】发布流程
export const publishProcess = (data, success, error, exception) => {
  axiosPostWrapper("/api/bpm/model/" + data + "/deploy", data, success, error, exception)
}

//【流程列表】启动流程
export const startProcess = (data, success, error, exception) => {
  axiosPostJsonWrapper("/api/bpm/process/start", data, success, error, exception)
}

//【流程列表/流程版本列表】获取流程版本列表
export const getProcessVersionList = (data, success, error, exception) => {
  var model = { page: data.page, size: data.size }
  axiosGetWrapper("/api/bpm/definition/version/list/" + data.code + "", model, success, error, exception)
}

//【流程列表/流程版本列表】设置为主版本
export const setMainVersion = (data, success, error, exception) => {
  axiosPostWrapper("/api/bpm/definition/enableVersion", data, success, error, exception)
}

//【流程列表】获取流程模型图
export const getProcessDiagram = (data, success, error, exception) => {
  axiosGetWrapper("/api/bpm/model/diagram", data, success, error, exception)
}

//【流程实例列表、任务列表公用】获取流程模型图
export const getTaskDiagram = (data, success, error, exception) => {
  axiosGetWrapper("api/bpm/process/taskDiagram", data, success, error, exception)
}

//【流程列表/流程设计器/表单权限管理】获取表单类型
export const getProcessSheet = (data, success, error, exception) => {
  axiosGetWrapper("api/formDefinition/selectProcesssheet", data, success, error, exception)
}

//【流程列表/流程设计器/表单权限管理】获取表单字段
export const getFormElementList = (data, success, error, exception) => {
  axiosGetWrapper("api/formDefinition/getFormElement", data, success, error, exception)
}

//【流程列表/流程设计器】保存流程设计模型
export const saveProcessModel = (data, success, error, exception) => {
  // console.log("saveProcessModel data:"+JSON.stringify(data));
  let { modelId, info } = data
  axiosPostJsonWrapper("/api/bpm/model/" + modelId + "/save", info, success, error, exception)
}

//【流程列表/流程设计器】验证流程设计模型
export const xmlValidate = (data, success, error, exception) => {
  axiosPostWrapper("/api/bpm/model/xmlValidate", data, success, error, exception)
}

//【流程列表/流程设计器】获取设计器详情
export const getProcessModelDetails = (data, success, error, exception) => {
  axiosGetWrapper("/api/bpm/model/" + data + "/get", data, success, error, exception)
}

// 复制流程
export const startTask = (data, success, error, exception) => {
  axiosPostWrapper("/api/bpm/task/running/copyTask", data, success, error, exception)
}

// 删除流程
export const delTaskList = (data, success, error, exception) => {
  axiosPostWrapper("/api/bpm/task/running/delTask", data, success, error, exception)
}
// 获取实例id
export const getTaskId = (data, success, error, exception) => {
  axiosPostWrapper("/api/bpm/task/todo/detail", data, success, error, exception)
}
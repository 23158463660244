<template>
  <div v-loading="loading" element-loading-text="拼命加载中...">
    <el-form label-position="right" label-width="100px" size="small">
      <div v-if="globalFormType != ''">
        <el-row>
          <el-col>
            <el-form-item label="选择表单：">
              <el-select
                v-model="formTypeInfo.code"
                @change="updateFormTypeInfo"
                clearable
              >
                <el-option
                  v-for="(item, index) in formTypeArr"
                  :key="index"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="radioList.length > 0">
          <el-tabs type="border-card">
            <el-tab-pane label="字段授权">
              <el-row>
                <el-col>
                  <el-form-item label="批量操作">
                    <el-radio-group v-model="radioData" @change="radioChange()">
                      <el-radio :label="1">全部不使用</el-radio>
                      <el-radio :label="2">全部隐藏</el-radio>
                      <el-radio :label="3">全部可写</el-radio>
                      <el-radio :label="4">全部只读</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12" v-for="(item, i) in radioList" :key="i">
                  <el-form-item :label="item.name">
                    <!-- <span v-if="item.type=='tabs'" style="cursor:pointer;color:blue" @click="setChildForm(item)">子表单配置</span> -->
                    <!-- v-else -->
                    <el-radio-group
                      v-model="item.status"
                      @change="radioChange2(item)"
                    >
                      <el-radio :label="1">不使用</el-radio>
                      <el-radio :label="2">隐藏</el-radio>
                      <el-radio :label="3">可写</el-radio>
                      <el-radio :label="4">只读</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="按钮授权">
              <el-row>
                <el-col>
                  <el-form-item label="按钮权限：">
                    <el-checkbox-group v-model="checkList">
                      <el-checkbox
                        v-if="formTypeInfo.formType == 0"
                        label="save"
                        >保存</el-checkbox
                      >
                      <el-checkbox
                        v-if="formTypeInfo.formType == 1"
                        label="insert"
                        >新增</el-checkbox
                      >
                      <el-checkbox
                        v-if="formTypeInfo.formType == 1"
                        label="update"
                        >修改</el-checkbox
                      >
                      <el-checkbox
                        v-if="formTypeInfo.formType == 1"
                        label="delete"
                        >删除</el-checkbox
                      >
                      <el-checkbox
                        v-if="formTypeInfo.formType == 1"
                        label="insert_batch"
                        >批量新增</el-checkbox
                      >
                      <el-checkbox
                        v-if="formTypeInfo.formType == 1"
                        label="adopt"
                        >通过</el-checkbox
                      >
                      <el-checkbox
                        v-if="formTypeInfo.formType == 1"
                        label="print"
                        >打印</el-checkbox
                      >
                      <el-checkbox v-if="formTypeInfo.formType == 1" label="see"
                        >查看</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
          <el-row class="btn-position-right" style="margin-top: 10px">
            <el-col>
              <el-form-item>
                <el-button type="primary" @click="handleSave()"
                  >保存当前表单</el-button
                >
                <el-button type="primary" @click="handleReset()"
                  >重置</el-button
                >
                <el-button @click="handleCancel()">关闭</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <el-row v-else>
        <el-col>
          <el-form-item>
            <span style="color: red; font-size: 24px">请先选择全局表单！</span>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!--子表单配置弹层-->
    <el-dialog
      :title="formChildConfig.name"
      :visible.sync="formChildConfig.dialogVisiable"
      append-to-body
      :destroy-on-close="true"
    >
      <form-child-authority
        v-if="formChildConfig.dialogVisiable"
        :loading="true"
        :id="formChildConfig.id"
        :code="formChildConfig.code"
        :child-list="formChildConfig.childList"
        @getChildList="getChildList"
        @close="formChildConfig.dialogVisiable = false"
      ></form-child-authority>
    </el-dialog>
  </div>
</template>
<script>
import {
  getProcessSheet,
  getFormElementList,
} from "@/api/process/manage/process"
import {
  getFormElementByBo,
  getFormElementByTable,
} from "@/api/form/definition"
import formChildAuthority from "./form-child-authority"
import { getInfo } from "@/api/system/script"
export default {
  name: "formAuthority",
  components: { formChildAuthority },
  props: {
    globalFormType: String,
    formAuthorityList: Array,
  },
  // watch: {
  //   formAuthorityList: {
  //     immediate: false,
  //     handler: function (data) {
  //       console.log("watch formAuthorityList:" + JSON.stringify(data));
  //     },
  //   },
  // },
  data () {
    return {
      loading: true,
      elementBaseInfo: {},
      radioData: "",
      radioList: [],
      radioAllList: [], //选择的表单所有集合
      formTypeArr: [], //表单分类集合
      globalFormArr: [], //全局表单集合
      formTypeInfo: {}, //当前表单信息
      FormElement: [], //表单字段集合
      //子表单配置节点
      formChildConfig: {
        dialogVisiable: false, //子表单显示或隐藏
        id: "", //子表单Id
        code: "", //子表单code
        name: "", //子表单标题
        childList: [], //子表单列表
      },
      checkList: [], //已选授权按钮
    }
  },
  created () {
    this.initData()
  },
  methods: {
    //页面初始化
    initData () {
      this.findFormTypeList(this.globalFormType)
      if (this.formAuthorityList != [] && this.formAuthorityList.length > 0) {
        //绑定字段授权
        this.radioAllList = this.formAuthorityList
        var info = this.formAuthorityList[0] //默认绑定第一项表单集合

        // console.log(JSON.stringify(info));
        this.formTypeInfo = {
          id: info.id,
          code: info.code,
          name: info.name,
          boCode: info.boCode,
          boName: info.boName,
          formType: info.formType,
        }

        this.radioList = info.childList
        this.radioList.forEach((item) => {
          this.radioChange2(item)
        }) //反绑批量操作按钮

        //绑定按钮授权
        this.checkList = info.btnList
        // console.log(JSON.stringify(this.formTypeInfo));
        // console.log(JSON.stringify(this.checkList));
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    //查询表单下拉列表
    // async findFormTypeList(fromCode) {
    //   await getProcessSheet(
    //     {
    //       fromCode: fromCode,
    //     },
    //     (res) => {
    //       if (fromCode == "") {
    //         this.globalFormArr = res;
    //       } else {
    //         this.formTypeArr = res;
    //       }
    //     },
    //     (error) => {
    //       console.warn(error);
    //       this.$message({
    //         type: "error",
    //         message: error,
    //         duration: 2000,
    //         onClose: () => {},
    //       });
    //       console.log("error");
    //     },
    //     (exception) => {
    //       console.log("error");
    //       console.warn(exception);
    //     }
    //   );
    // },
    findFormTypeList (formListJson) {
      this.formTypeArr = formListJson == "" ? [] : JSON.parse(formListJson)
    },
    //表单类型选择事件
    updateFormTypeInfo (data) {
      this.radioData = ""
      this.radioList = []
      this.checkList = []

      this.formTypeArr.forEach((item) => {
        if (item.code == data) {
          this.formTypeInfo = {
            id: item.id,
            code: item.code,
            name: item.name,
            boCode: item.boCode,
            boName: item.boName,
            formType: item.formType,
          }
        }
      })
      //判断是否存在父级列表
      var flag = false
      this.formAuthorityList.forEach((item) => {
        if (item.name == this.formTypeInfo.name) {
          flag = true
        }
      })
      if (flag) {
        this.formAuthorityList.forEach((m) => {
          if (m.name == this.formTypeInfo.name) {
            this.radioList = m.childList
            this.radioList.forEach((n) => {
              this.radioChange2(n)
            })
            this.checkList = m.btnList
          }
        })
      } else {
        let { formType } = this.formTypeInfo
        let dataCode = this.formTypeInfo.boCode
        if (formType == 0) {
          this.getFormElementByBoAsync(dataCode)
          if (this.checkList == [] || this.checkList.length <= 0) {
            this.checkList = ["save"]
          }
        } else if (formType == 1) {
          this.getFormElementByTableAsync(dataCode)
          if (this.checkList == [] || this.checkList.length <= 0) {
            this.checkList = ["insert", "update", "delete"]
          }
        }
        //this.findFormElementList(data);
      }
    },
    //根据业务对象获取元素接口
    async getFormElementByBoAsync (data) {
      await getFormElementByBo(
        {
          boCode: data,
        },
        (res) => {
          if (res != [] && res.length > 0) {
            res.forEach((item) => {
              var model = {
                id: item.id,
                name: item.name,
                code: item.code,
                type: item.type,
              }
              model.status = ""
              if (item.type == "tabs") {
                model.childList = item.childList
              }
              this.radioList.push(model)
            })
            //console.log("radioList:" + JSON.stringify(this.radioList));
          } else {
            this.$message({
              type: "warning",
              message: "暂未获取到相关表单元素信息！",
              duration: 2000,
            })
          }
        },
        (error) => {
          console.warn(error)
          this.$message({
            type: "error",
            message: error,
            duration: 2000,
          })
          console.log("error")
        },
        (exception) => {
          console.log("error")
          console.warn(exception)
        }
      )
    },
    //根据实体获取元素接口
    async getFormElementByTableAsync (data) {
      await getFormElementByTable(
        {
          tableCode: data,
        },
        (res) => {
          if (res != [] && res.length > 0) {
            res.forEach((item) => {
              var model = {
                id: item.id,
                name: item.name,
                code: item.code,
                type: item.type,
              }
              model.status = ""
              if (item.type == "tabs") {
                model.childList = item.childList
              }
              this.radioList.push(model)
            })
            //console.log("radioList:" + JSON.stringify(this.radioList));
          } else {
            this.$message({
              type: "warning",
              message: "暂未获取到相关表单元素信息！",
              duration: 2000,
            })
          }
        },
        (error) => {
          console.warn(error)
          this.$message({
            type: "error",
            message: error,
            duration: 2000,
          })
          console.log("error")
        },
        (exception) => {
          console.log("error")
          console.warn(exception)
        }
      )
    },
    //已废弃
    async findFormElementList (data) {
      await getFormElementList(
        { formCode: data },
        (res) => {
          if (res != [] && res.length > 0) {
            res.forEach((item) => {
              var model = {
                id: item.id,
                name: item.name,
                code: item.code,
                type: item.type,
              }
              model.status = ""
              if (item.type == "tabs") {
                model.childList = item.childList
              }
              this.radioList.push(model)
            })
            //console.log("radioList:" + JSON.stringify(this.radioList));
          } else {
            this.$message({
              type: "warning",
              message: "暂未获取到相关表单元素信息！",
              duration: 2000,
            })
          }
        },
        (error) => {
          console.warn(error)
          this.$message({
            type: "error",
            message: error,
            duration: 2000,
          })
          console.log("error")
        },
        (exception) => {
          console.log("error")
          console.warn(exception)
        }
      )
    },
    //批量操作按钮事件
    radioChange () {
      this.radioList.forEach((item) => {
        item.status = this.radioData
      })
    },
    //非批量操作按钮事件
    radioChange2 (item) {
      this.radioData = ""
      var flag = 0
      this.radioList.forEach((data) => {
        if (data.status == item.status) {
          flag = flag + 1
        }
      })
      if (flag == this.radioList.length) {
        this.radioData = item.status
      }
      //console.log(JSON.stringify(this.radioList));
    },
    //保存
    handleSave () {
      //验证当前表单是否选择了权限，如果没选择，不允许点击保存
      var ckNumber = 0
      this.radioList.forEach((item) => {
        if (item.status != "") {
          ckNumber = ckNumber + 1
        }
      })
      //验证表单字段授权是否完整
      if (this.radioList.length != ckNumber) {
        this.$message({
          type: "warning",
          message: "当前表单字段授权不完整！",
          duration: 2000,
        })
        return
      }

      //验证表单按钮授权是否完整（验证暂时去掉，应该不需要）
      // if (this.checkList == [] || this.checkList.length <= 0) {
      //   this.$message({
      //     type: "warning",
      //     message: "当前表单按钮授权不完整！",
      //     duration: 2000,
      //   });
      //   return;
      // }

      //定义存放当前表单字段（全）的集合，包括已选未选
      var list = []
      this.radioList.forEach((item) => {
        list.push(item)
      })

      //封装当前表单基本信息、表单字段授权列表表单按钮授权列表
      var model = {
        id: this.formTypeInfo.id,
        code: this.formTypeInfo.code,
        name: this.formTypeInfo.name,
        formType: this.formTypeInfo.formType,
        childList: list,
        btnList: this.checkList,
      }

      //判断最终返回的表单权限集合
      if (this.radioAllList != [] && this.radioAllList.length > 0) {
        var flag = false //标识是否存在某个表单类型

        //通过遍历集合，标识当前表单类型是否存在
        this.radioAllList.forEach((item) => {
          if (item.id == model.id) {
            flag = true
          }
        })

        if (flag) {
          //如果当前表单类型存在，则将当前修改后的表单字段更新到集合中对应的列表下
          this.radioAllList.forEach((item) => {
            if (item.id == model.id) {
              item.childList = model.childList
              item.btnList = model.btnList
            }
          })
        } else {
          //当前表单类型不存在，则添加当前表单权限信息至集合
          this.radioAllList.push(model)
        }
      } else {
        this.radioAllList.push(model) //第一次保存，走这
      }

      this.$message({
        type: "success",
        message: "当前表单权限保存成功！",
        duration: 1000,
        onClose: () => {
          //当前表单保存成功后，将数据传给父级接收，窗体关闭后，更新父级表单权限列表
          this.$emit("getRadioAllList", this.radioAllList)
        },
      })
    },
    //重置
    handleReset () {
      this.$confirm("确定要重置当前表单权限吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.radioData = ""
          this.radioList.forEach((m) => {
            m.status = ""
            if (m.childList && m.childList != []) {
              m.childList.forEach((n) => {
                n.status = ""
              })
            }
          })
          this.resetCheckList()
        })
        .catch(() => { })
    },
    resetCheckList () {
      this.checkList =
        this.formTypeInfo == 0 ? ["insert", "update", "delete"] : ["save"]
    },
    //取消
    handleCancel () {
      this.$emit("close")
    },
    setChildForm (data) {
      this.formChildConfig = {
        dialogVisiable: true,
        id: data.id,
        code: data.code,
        name: "配置子表单权限：" + data.name + "",
        childList: data.childList,
      }
    },
    //关闭弹层并刷新列表
    getChildList (data) {
      //console.log("getChildList childList:" + JSON.stringify(data.childList));
      this.radioList.forEach((item) => {
        if (item.id == data.id) {
          //更新子表单元素到父级列表中
          item.childList = data.childList
        }
      })
      this.formChildConfig.dialogVisiable = false
      //console.log("getChildList radioList" + JSON.stringify(this.radioList));
    },
    updateBaseInfo (key) { },
  },
};
</script>

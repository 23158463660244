<template>
  <div class="panel-tab__content">
    <el-form>
      <el-row>
        <el-col>
          <el-form-item>
            <codemirror
              ref="script"
              :code-str="scriptInfo.scriptString"
              @getCodeStr="getCodeStr"
              style="height: 350px"
            ></codemirror>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item>
            <el-button size="small" type="primary" @click="handleScriptTest"
              >脚本测试</el-button
            >
            <!-- <el-button size="small" type="primary" @click="handleSave">保存</el-button> -->
            <el-button size="small" @click="handleReset">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import codemirror from "@/components/codemirror"
import { testScript } from "@/api/system/script"
export default {
  components: { codemirror },
  props: {
    businessObject: {
      type: Object,
      default: {}
    },
    type: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      scriptInfo: {
        scriptString: "",
      },
    }
  },
  watch: {
    businessObject: {
      immediate: false,
      handler: function (val) {
        if (val) {
          this.$nextTick(() => this.initData())
        }
      },
    },
  },
  created () {
    this.initData()
  },
  methods: {
    //初始化页面
    initData () {
      this.bpmnElement = window.bpmnInstances.bpmnElement
      // console.log(window.bpmnInstances.bpmnElement)
      var baseInfo = JSON.parse(
        JSON.stringify(this.bpmnElement.businessObject)
      )
      // console.log("baseInfo:" + JSON.stringify())
      if (
        this.type == "SequenceFlow" &&
        this.bpmnElement.source &&
        this.bpmnElement.source.type.indexOf("ExclusiveGateway") > -1
      ) {
        if (baseInfo.conditionExpression) {
          let { scriptString } = baseInfo.conditionExpression
          this.scriptInfo.scriptString = scriptString
        }
      }
    },
    //获取代码编译器内容
    getCodeStr (data) {
      // console.log(data)
      this.scriptInfo.scriptString = data
      this.updateProperties()
    },
    //脚本测试
    handleScriptTest () {
      if (this.scriptInfo.scriptString == "") {
        this.$message({
          type: "error",
          message: "脚本不能为空！",
          duration: 2000,
        })
      } else {
        let Base64 = require("js-base64").Base64
        var base64Str = Base64.encode(this.scriptInfo.scriptString)
        this.testScriptAsync(base64Str)
      }
    },
    async testScriptAsync (base64Str) {
      await testScript(
        { script: base64Str },
        (res) => {
          this.$message({
            type: "success",
            message: "测试通过！",
            duration: 1000,
            onClose: () => { },
          })
        },
        (error) => {
          console.warn(error)
          this.$message({
            type: "error",
            message: error,
            duration: 2000,
          })
          console.log("error")
        },
        (exception) => {
          console.log("error")
          console.warn(exception)
        }
      )
    },
    //保存
    // handleSave() {
    //   if (this.scriptInfo.scriptString == "") {
    //     this.$message({
    //       type: "error",
    //       message: "请填写脚本！",
    //       duration: 1000,
    //     });
    //   } else {
    //     this.$message({
    //       type: "success",
    //       message: "保存成功！",
    //       duration: 1000,
    //       onClose: () => {
    //         this.updateProperties();
    //       },
    //     });
    //   }
    // },
    //重置
    handleReset () {
      this.scriptInfo.scriptString = ""
      this.$refs["script"].code = ""
      this.updateProperties()
    },
    //修改流程设计器基础数据
    updateProperties () {
      let { scriptString } = this.scriptInfo
      let condition = window.bpmnInstances.moddle.create(
        "bpmn:FormalExpression",
        { scriptString: scriptString }
      )
      window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
        conditionExpression: condition,
      })
    },
  },
};
</script>
export const process = [{
  hash: "",
  path: "/process/config/variable",
  name: "全局变量管理",
  component: () => import("@/views/process/config/global/index")
},
{
  hash: "",
  path: "/process/config/dialog",
  name: "对话框管理",
  component: () => import("@/views/process/config/dialog-box")
},
{
  hash: "",
  path: "/process/config/serial",
  name: "流水号管理",
  component: () => import("@/views/process/config/serial/index")
},
{
  hash: "",
  path: "/process/manage/list",
  name: "流程列表",
  component: () => import("@/views/process/manage/process-list")
},
{
  hash: "",
  path: "/process/manage/task",
  name: "任务列表",
  component: () => import("@/views/process/manage/task-list")
},
{
  hash: "",
  path: "/process/manage/instance",
  name: "流程实例管理",
  component: () => import("@/views/process/manage/process-instance")
},
{
  hash: "",
  path: "/process/manage/start",
  name: "发起申请单",
  component: () => import("@/views/process/manage/components/process/process-start")
},
{
  hash: "",
  path: "/process/manage/tasktodo",
  name: "流程任务处理",
  component: () => import("@/views/process/manage/components/process/process-task-todo")
},
{
  hash: "",
  path: "/process/config/category",
  name: "流程分类管理",
  component: () => import("@/views/process/config/category/index")
},
];
<template>
  <choice-table
    width="60%"
    api="/api/sys/user/list"
    :choiceType="choiceType"
    :searchField="[{ type: 'input', account: 'account', name: '用户编码' }]"
    :tableColumns="[
      { code: 'userId', name: '用户编码' },
      { code: 'account', name: '账号' },
      { code: 'name', name: '姓名' },
      { code: 'deptName', name: '部门名称' },
      { code: 'phone', name: '手机号码' },
      { code: 'createDate', name: '创建日期' },
    ]"
    title="请选择用户"
    @close="$emit('dialogVisibleClose', false)"
    @choiced="cs"
    :dialogVisible="dialogVisible"
  >
  </choice-table>
</template>
<script>
import ChoiceTable from "@/components/choice/choiceTable"
export default {
  components: { ChoiceTable },
  mounted () {
    let me = this
    me.init()
  },
  props: {
    choiceType: String,
    dialogVisible: false,
  },
  data () {
    return {}
  },
  methods: {
    cs (e) {
      this.$emit('choiced', e)
    },
    init () { },
  },
  watch: {},
};
</script>
<style lang="less" scoped>
</style>
<template>
  <div class="panel-tab__content">
    <el-form
      label-position="right"
      label-width="120px"
      :model="elementBaseInfo"
      :rules="rules"
      ref="ruleForm"
      size="small"
    >
      <el-row>
        <el-col>
          <el-form-item label="流程编码：" prop="id">
            <el-input
              type="text"
              :disabled="
                idEditDisabled || elementBaseInfo.$type === 'bpmn:Process'
              "
              v-model="elementBaseInfo.id"
              @change="updateBaseInfo('id')"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="流程名称：" prop="name">
            <el-input
              type="text"
              v-model="elementBaseInfo.name"
              @change="updateBaseInfo('name')"
              :disabled="true"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row>
        <el-col>
          <el-form-item label="观察者：">
            <ProcessSetting />
          </el-form-item>
        </el-col>
      </el-row> -->
      <div class="panel-tab__content--title">
        <span>
          <i class="el-icon-menu" style="margin-right: 8px; clolor: #555555"></i
          >观察者
        </span>
      </div>
      <ProcessSetting />
      <div class="panel-tab__content--title">
        <span>
          <i class="el-icon-menu" style="margin-right: 8px; clolor: #555555"></i
          >流程脚本
        </span>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-edit-outline"
          @click="dialogScriptOpen"
          >全局脚本管理</el-button
        >
      </div>
      <div class="panel-tab__content--title">
        <span>
          <i class="el-icon-menu" style="margin-right: 8px; clolor: #555555"></i
          >全局表单列表
        </span>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-edit-outline"
          @click="dialogFormChoiceVisible = true"
          >全局表单管理</el-button
        >
      </div>
      <el-table :data="formList" size="small" border>
        <el-table-column prop="code" label="表单code"></el-table-column>
        <el-table-column prop="name" label="表单name"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-link
              type="primary"
              icon="el-icon-delete"
              @click="handleDelete(row)"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-row>
        <el-col>
          <el-form-item label="标题规则：" prop="titleRule">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4 }"
              v-model="elementBaseInfo.titleRule"
              @change="updateBaseInfo('titleRule')"
              placeholder="{发起人:userName}在{发起时间:startDate}发起{流程标题:title}"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="状态：">
            <el-select
              v-model="elementBaseInfo.status"
              @change="updateBaseInfo('status')"
              :disabled="true"
            >
              <el-option
                v-for="item in statusArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row>
        <el-col>
          <el-form-item label="任务跳过策略：" prop="taskSkip">
            <el-select v-model="elementBaseInfo.taskSkip" @change="updateBaseInfo('taskSkip')">
              <el-option v-for="item in taskSkipArr" :key="item.name" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row> -->
      <el-row>
        <el-col>
          <el-form-item label="描述：">
            <el-input
              type="textarea"
              :disabled="true"
              :autosize="{ minRows: 4 }"
              v-model="elementBaseInfo.remark"
              @change="updateBaseInfo('remark')"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="允许撤回流程：">
            <el-switch
              v-model="elementBaseInfo.isAllowBaskProcess"
              active-value="1"
              inactive-value="0"
              @change="updateBaseInfo('isAllowBaskProcess')"
            ></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="是否必填意见：">
            <el-switch
              v-model="elementBaseInfo.isRequiredComments"
              active-value="1"
              inactive-value="0"
              @change="updateBaseInfo('isRequiredComments')"
            ></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="display: none">
        <el-col>
          <el-form-item>
            <el-button type="primray" @click="validateForm()"
              >表单验证</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!--全局表单管理-->
    <el-dialog
      :visible.sync="dialogFormChoiceVisible"
      title="全局表单管理"
      append-to-body
      width="60%"
    >
      <form-choice
        v-if="dialogFormChoiceVisible"
        @choiced="getFormList"
        @close="dialogFormChoiceVisible = false"
      ></form-choice>
    </el-dialog>
    <!--全局脚本管理-->
    <el-dialog
      :visible.sync="dialogScriptVisible"
      title="全局脚本管理"
      append-to-body
      width="70%"
    >
      <script-manage
        v-if="dialogScriptVisible"
        :script-list="scriptList"
        :import-script="importScript"
        @getCodeStr="getScriptStr"
        @close="dialogScriptVisible = false"
      ></script-manage>
    </el-dialog>
  </div>
</template>
<script>
import ProcessSetting from "../main/process-setting.vue" //主体设置
import { getProcessSheet } from "@/api/process/manage/process"
import formChoice from "../form/form-choice.vue"
import scriptManage from "../script/script-manage.vue"
export default {
  name: "processProperties",
  components: { formChoice, scriptManage, ProcessSetting },
  inject: ["returnValidate"],
  data () {
    return {
      elementBaseInfo: {
        remark: "",
        isAllowBaskProcess: "0",
        isRequiredComments: "0",
      },
      globalFormArr: [],
      statusArr: [
        { label: "草稿", value: "0" },
        { label: "正式", value: "1" },
      ], //状态（draft：草稿，release：已发布）
      taskSkipArr: [
        { name: "firstNodeSkip", label: "跳过首个节点", value: 1 },
        { name: "none", label: "不处理", value: 2 },
      ], //任务跳过策略（firstNodeSkip：跳过首个节点，none：不处理）
      dialogFormChoiceVisible: false,
      formList: [], //选中的表单列表
      dialogScriptVisible: false, //全局脚本管理
      scriptList: [],
      importScript: "",
      rules: {
        id: [
          {
            required: true,
            message: "请输入流程编码！",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入流程名称！",
            trigger: "change",
          },
        ],
        globalForm: [
          {
            required: true,
            message: "请选择全局表单！",
            trigger: "change",
          },
        ],
        titleRule: [
          {
            required: true,
            message: "请输入标题规则！",
            trigger: "blur",
          },
        ],
        // status: [
        //   {
        //     required: true,
        //     message: "请选择状态！",
        //     trigger: "change",
        //   },
        // ],
        // taskSkip: [
        //   {
        //     required: true,
        //     message: "请选择任务跳过策略！",
        //     trigger: "change",
        //   },
        // ],
      },
    }
  },
  props: {
    businessObject: Object,
    type: String,
    idEditDisabled: {
      type: Boolean,
      default: true,
    },
  },
  created () {
    //this.findFormList();
  },
  watch: {
    businessObject: {
      immediate: false,
      handler: function (val) {
        if (val) {
          this.$nextTick(() => this.resetBaseInfo())
        }
      },
    },
  },
  methods: {
    //查询全局表单列表
    async findFormList () {
      await getProcessSheet(
        {},
        (res) => {
          this.globalFormArr = res
        },
        (error) => {
          console.warn(error)
          this.$message({
            type: "error",
            message: error,
            duration: 2000,
          })
          console.log("error")
        },
        (exception) => {
          console.log("error")
          console.warn(exception)
        }
      )
    },
    //获取选中的表单列表
    getFormList (data) {
      this.formList = []
      data.forEach((item) => {
        var model = {
          id: item.id,
          name: item.name,
          code: item.code,
          boCode: item.formType == 0 ? item.boCode : item.tableCode,
          boName: item.formType == 0 ? item.boName : item.tableName,
          formType: item.formType,
        }
        this.formList.push(model)
      })
      this.elementBaseInfo.globalForm = JSON.stringify(this.formList)
      this.updateBaseInfo("globalForm")
      this.dialogFormChoiceVisible = false
    },
    //【表单列表】删除一条数据
    handleDelete (data) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.formList.splice(this.formList.indexOf(data), 1)
          this.elementBaseInfo.globalForm = JSON.stringify(this.formList)
          this.updateBaseInfo("globalForm")
        })
        .catch(() => { })
    },
    resetBaseInfo () {
      this.bpmnElement = window.bpmnInstances.bpmnElement
      var baseInfo = JSON.parse(
        JSON.stringify(this.bpmnElement.businessObject)
      )
      //只绑定类型为流程的相关信息
      if (baseInfo.$type === "bpmn:Process") {
        if (baseInfo.remark == "undefined") {
          baseInfo.remark = ""
        }
        if (baseInfo.globalForm && baseInfo.globalForm != "") {
          this.formList = JSON.parse(baseInfo.globalForm)
        }
        this.elementBaseInfo = baseInfo

        //   if (this.scriptList == [] || this.scriptList.length <= 0) {
        //     let model = {
        //       code: this.elementBaseInfo.id,
        //       name: this.elementBaseInfo.name,
        //       type: "process",
        //       beforeCode: "",
        //       afterCode: "",
        //     };
        //     this.scriptList.push(model);
        //   }
        // } else {
        //   let { id, name, $type } = baseInfo;
        //   if ($type == "bpmn:UserTask" || $type == "bpmn:ExclusiveGateway") {
        //     let model = {
        //       code: id,
        //       name: name,
        //       type: $type,
        //       beforeCode: "",
        //       afterCode: "",
        //     };
        //     let flag = false;
        //     if (this.scriptList != [] && this.scriptList.length > 0) {
        //       this.scriptList.forEach((item) => {
        //         if (item.code == model.code) {
        //           flag = true;
        //         }
        //       });
        //     }
        //     if (!flag) {
        //       console.log(JSON.stringify(model));

        //       this.scriptList.push(model);
        //     }

        //     console.log("scriptList:" + JSON.stringify(this.scriptList));
        //   }
      }
    },
    updateBaseInfo (key) {
      const attrObj = Object.create(null)
      attrObj[key] = this.elementBaseInfo[key]
      if (key === "id") {
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
          id: this.elementBaseInfo[key],
          di: { id: this.elementBaseInfo[key] },
        })
      } else if (key === "globalForm") {
        window.bpmnInstances.modeling.updateProperties(
          this.bpmnElement,
          attrObj
        )
      } else if (key === "taskSkip") {
        window.bpmnInstances.modeling.updateProperties(
          this.bpmnElement,
          attrObj
        )
        this.taskSkipArr.forEach((item) => {
          if (item.value == this.elementBaseInfo[key]) {
            console.log(item.value + "," + this.elementBaseInfo[key])
            attrObj["taskSkipKey"] = item.name
            window.bpmnInstances.modeling.updateProperties(
              this.bpmnElement,
              attrObj
            )
            return
          }
        })
      } else {
        window.bpmnInstances.modeling.updateProperties(
          this.bpmnElement,
          attrObj
        )
      }
    },
    //表单验证
    validateForm () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.returnValidate(true) //返回爷爷层页面
        } else {
          this.returnValidate(false) //返回爷爷层页面
          this.$alert("流程属性表单验证未通过！", "提示", {
            confirmButtonText: "确定",
            type: "error",
          })
          return false
        }
      })
    },
    //打开全局脚本弹层
    dialogScriptOpen () {
      //debugger;
      var elementList = [] //定义存放当前流程设计器中存在的元件集合

      //取出父级流程属性
      let processBpmnElement = window.bpmnInstances.elementRegistry.find(
        (el) => el.type === "bpmn:Process"
      )

      //先找出流程基本属性id和type，放入集合中
      let { id, type, children } = processBpmnElement
      let model = { id: id, type: type }
      elementList.push(model)

      //再找出其它元件
      if (children != [] && children.length > 0) {
        //存在元件信息，循环找到 用户任务、网关分支的节点
        children.forEach((item) => {
          let { id, type } = item
          if (type == "bpmn:UserTask" || type == "bpmn:ExclusiveGateway") {
            let model = { id: id, type: type }
            elementList.push(model)
          }
        })
      }

      //取出已经保存到 xml 中的脚本Json串
      //debugger;
      let scriptArrJson = ""
      if (
        this.elementBaseInfo.scriptArrJson != undefined &&
        this.elementBaseInfo.scriptArrJson != ""
      ) {
        scriptArrJson = this.elementBaseInfo.scriptArrJson
      }
      if (
        this.elementBaseInfo.importScript != undefined &&
        this.elementBaseInfo.importScript != ""
      ) {
        this.importScript = this.elementBaseInfo.importScript //导包 import 部分
      } else {
        this.importScript = ""
      }

      let scriptArr = []
      if (scriptArrJson != "") {
        scriptArr = JSON.parse(scriptArrJson)
        //转为Json集合
      }

      let elementEndList = [] //定义最后要保存的元件集合

      if (scriptArr != [] && scriptArr.length > 0) {
        elementList.forEach((m) => {
          let model = {
            code: m.id,
            type: m.type,
            beforeCode: "\n\n",
            afterCode: "\n\n",
          }
          scriptArr.forEach((n) => {
            if (m.id == n.code) {
              model.beforeCode = n.beforeCode
              model.afterCode = n.afterCode
            }
          })
          elementEndList.push(model)
        })
      } else {
        elementList.forEach((item) => {
          let model = {
            code: item.id,
            type: item.type,
            beforeCode: "\n\n",
            afterCode: "\n\n",
          }
          elementEndList.push(model)
        })
      }

      //先保存当前设计器的元件集合Json串
      this.scriptList = elementEndList //封装好的集合传给脚本弹层页内
      this.elementBaseInfo.scriptArrJson = JSON.stringify(elementEndList)
      this.updateBaseInfo("scriptArrJson")

      this.dialogScriptVisible = true
    },
    //获取返回的脚本
    getScriptStr (res) {
      //debugger;
      let { scriptString, importScript } = res
      let data = scriptString
      let scriptList = []
      var arr = data.toString().split("void ")
      arr.forEach((item) => {
        if (item != "") {
          let code = "" // item.toString().slice(0, item.toString().lastIndexOf("_before")); //找到脚本标识code
          let type = ""

          let codefront = item
            .toString()
            .slice(0, item.toString().indexOf("(")) //截取第一段小括号前的字符串：方法名

          if (codefront.indexOf("_before") > -1) {
            //前置
            code = item.toString().slice(0, item.toString().indexOf("_before"))
            type = "before"
          }
          if (codefront.indexOf("_after") > -1) {
            //后置
            code = item.toString().slice(0, item.toString().indexOf("_after"))
            type = "after"
          }
          let script = item
            .toString()
            .slice(
              item.toString().indexOf("{") + 1,
              item.toString().lastIndexOf("}")
            ) //截取脚本
          let model = { code: code, type: type, script: script }
          scriptList.push(model)
        }
      })

      this.scriptList.forEach((m) => {
        scriptList.forEach((n) => {
          if (m.code == n.code) {
            if (n.type == "before") {
              m.beforeCode = n.script
            } else if (n.type == "after") {
              m.afterCode = n.script
            }
          }
        })
      })

      this.elementBaseInfo.scriptStrings = data
      this.updateBaseInfo("scriptStrings")
      if (this.scriptList != [] && this.scriptList.length > 0) {
        this.elementBaseInfo.scriptArrJson = JSON.stringify(this.scriptList)
        this.updateBaseInfo("scriptArrJson")
      }
      this.importScript = arr[0] //导包 import 部分
      this.elementBaseInfo.importScript = arr[0] //导包 import 部分
      this.updateBaseInfo("importScript")
    },
  },
};
</script>
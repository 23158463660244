export default [
  {
      hash: "",
      path: "/401",
      name: "Error401",
      component: () => import("@/views/error/401.vue")
  },
  {
      hash: "",
      path: "/404",
      name: "Error404",
      component: () => import("@/views/error/404.vue")
  }
];
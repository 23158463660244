import { render, staticRenderFns } from "./DictTableColumn.vue?vue&type=template&id=5dc3dfdd&scoped=true"
import script from "./DictTableColumn.vue?vue&type=script&lang=js"
export * from "./DictTableColumn.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dc3dfdd",
  null
  
)

export default component.exports
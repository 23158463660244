<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
export default {
  name: "app",
  computed: {},
  created () {
    document.body.removeChild(document.getElementById("system_loading"))
  },
};
</script>

<style lang="less">
body * {
  /* 滚动条 */
  &::-webkit-scrollbar-track-piece {
    background-color: #fff; /* 滚动条的背景颜色 */
    -webkit-border-radius: 0; /* 滚动条的圆角宽度 */
  }
  &::-webkit-scrollbar {
    width: 10px; /* 滚动条的宽度 */
    height: 8px; /* 滚动条的高度 */
  }
  &::-webkit-scrollbar-thumb:vertical {
    /* 垂直滚动条的样式 */
    height: 50px;
    background-color: rgba(153, 153, 153, 0.5);
    border: 2px solid #fff;
    -webkit-border-radius: 4px;
    outline: 2px solid #fff;
    outline-offset: -2px;
  }
  &::-webkit-scrollbar-thumb {
    /* 滚动条的hover样式 */
    background-color: rgba(159, 159, 159, 0.3);
    -webkit-border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    /* 滚动条的hover样式 */
    background-color: rgba(159, 159, 159, 0.5);
    -webkit-border-radius: 4px;
  }
}
</style>

/**
 *  多session封装
 **/
export const setStor = (str, data) => {
  window.sessionStorage.setItem(str, JSON.stringify(data))
}

export const getStor = (str) => {
  return JSON.parse(window.sessionStorage.getItem(str))
}

export const removeStor = (str) => {
  window.sessionStorage.removeItem(str)
}
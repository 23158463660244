<template>
  <div class="panel-tab__content">
    <!-- @submit.native.prevent
        .native 表示对一个组件绑定系统原生事件
        .prevent 表示提交以后不刷新页面
    -->
    <el-form
      label-width="110px"
      :model="elementBaseInfo"
      :rules="rules"
      size="small"
      @submit.native.prevent
    >
      <el-form-item label="节点标识：" prop="id">
        <el-input
          v-model="elementBaseInfo.id"
          :disabled="idEditDisabled || elementBaseInfo.$type === 'bpmn:Process'"
          clearable
          @change="updateBaseInfo('id')"
        />
      </el-form-item>
      <el-form-item label="节点名称：" prop="name">
        <el-input
          v-model="elementBaseInfo.name"
          clearable
          @change="updateBaseInfo('name')"
        />
      </el-form-item>
      <el-form-item label="节点别名：">
        <el-input
          v-model="elementBaseInfo.remark"
          clearable
          @change="updateBaseInfo('remark')"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "ElementBaseInfo",
  props: {
    businessObject: Object,
    type: String,
    idEditDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      elementBaseInfo: { id: "", name: "", remark: "" },
      rules: {
        id: [
          {
            required: true,
            message: "请输入节点标识！",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入节点名称！",
            trigger: "blur",
          },
        ],
        // remark: [
        //   {
        //     required: true,
        //     message: "请输入节点描述！",
        //     trigger: "blur",
        //   },
        // ],
      },
    }
  },
  watch: {
    businessObject: {
      immediate: false,
      handler: function (val) {
        if (val) {
          this.$nextTick(() => this.resetBaseInfo())
        }
      },
    },
  },
  methods: {
    resetBaseInfo () {
      this.bpmnElement = window?.bpmnInstances?.bpmnElement
      var baseInfo = JSON.parse(
        JSON.stringify(this.bpmnElement.businessObject)
      )
      //只有类型不等于流程属性时，才绑定相关信息
      if (!(baseInfo.$type === "bpmn:Process")) {
        this.elementBaseInfo.id = baseInfo.id
        if (baseInfo.$type == "bpmn:StartEvent") {
          if (baseInfo.name) {
            this.elementBaseInfo.name = baseInfo.name
          } else {
            this.elementBaseInfo.name = "开始节点"
            this.updateBaseInfo("name")
          }
        } else if (baseInfo.$type == "bpmn:UserTask") {

          //正常显示XML文件中的节点属性
          if (baseInfo.name && baseInfo.remark) {
            let name = baseInfo.name
            let remark = baseInfo.remark

            this.elementBaseInfo.name = remark
            this.elementBaseInfo.remark = name
          } else {
            if (baseInfo.name && !baseInfo.remark) {
              //name有值，remark无值
              this.elementBaseInfo.name = baseInfo.name
              this.elementBaseInfo.remark = ""
              //this.updateBaseInfo("remark");
            }
            else if (baseInfo.remark && !baseInfo.name) {
              //name无值，remark有值
              this.elementBaseInfo.name = baseInfo.remark
              this.elementBaseInfo.remark = baseInfo.remark
              //this.updateBaseInfo("name");
            }
            else {
              this.elementBaseInfo.name = "用户任务"
              this.updateBaseInfo("name")
            }
          }

        } else if (baseInfo.$type == "bpmn:ExclusiveGateway") {
          if (baseInfo.name) {
            this.elementBaseInfo.name = baseInfo.name
          } else {
            this.elementBaseInfo.name = "流转网关判断"
            this.updateBaseInfo("name")
          }
        } else if (baseInfo.$type == "bpmn:EndEvent") {
          if (baseInfo.name) {
            this.elementBaseInfo.name = baseInfo.name
          } else {
            this.elementBaseInfo.name = "结束节点"
            this.updateBaseInfo("name")
          }
        } else if (baseInfo.$type == "bpmn:SequenceFlow") {
          if (baseInfo.name) {
            this.elementBaseInfo.name = baseInfo.name
          } else {
            this.elementBaseInfo.name = "流转线"
            this.updateBaseInfo("name")
          }
        }
      }
    },
    updateBaseInfo (key) {
      const attrObj = Object.create(null)
      //attrObj[key] = this.elementBaseInfo[key]
      if (key === "id") {
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
          id: this.elementBaseInfo.id,
          di: { id: `${this.elementBaseInfo.id}_di` },
        })
      } else {

        let name = ""
        let remark = ""

        if (this.elementBaseInfo.name && this.elementBaseInfo.remark) {
          name = this.elementBaseInfo.remark
          remark = this.elementBaseInfo.name
        } else {
          if (this.elementBaseInfo.name && !this.elementBaseInfo.remark) {
            //name有值，remark无值
            name = this.elementBaseInfo.name
          }
          else if (this.elementBaseInfo.remark && !this.elementBaseInfo.name) {
            //name无值，remark有值
            name = this.elementBaseInfo.remark
            remark = this.elementBaseInfo.remark
          }
        }

        attrObj["name"] = name
        attrObj["remark"] = remark

        window.bpmnInstances.modeling.updateProperties(
          this.bpmnElement,
          attrObj
        )
      }
    },
  },
  beforeDestroy () {
    this.bpmnElement = null
  },
};
</script>
<template>
  <choice-table width="60%" api="/api/sys/role/list" :choiceType="choiceType" :searchField="[{ type: 'input', code: 'code', name: '角色编码' }]" :tableColumns="[
        { code: 'code', name: '角色编码' },
        { code: 'name', name: '名称' },
        { code: 'groupName', name: '分组名称' },
        { code: 'descs', name: '描述' },
      ]" title="请选择角色" @close="$emit('dialogVisibleClose',false)" @choiced="$emit('choiced',$event)" :dialogVisible="dialogVisible">
  </choice-table>
</template>
<script>
import ChoiceTable from "@/components/choice/choiceTable";
export default {
  components: { ChoiceTable },
  mounted() {
    let me = this;
    me.init();
  },
  props: {
    choiceType: String,
    dialogVisible: false,
  },
  data() {
    return {};
  },
  methods: {
    init() {},
  },
  watch: {},
};
</script>
<style lang="less" scoped>
</style>
/**
 * Created by DELL on 2019/3/2.
 */
import {
  parseParam,
  cleanObj
} from "@/util/util";
export default {
  created() {
    this.getSysUserInfo();
  },
  data() {
    return {
      // tableName:'',
      importTable: [],
      tableLoading: false,
      loadingText: '数据加载中......',
      pagination: {
        current: 1,
        limit: 10,
        total: 0,
        pages: 0,
        max: 1,
        next: 1
      },
      accessToken: '',
      searchForm: {
        size: 10,
        page: 1
      },
      multipleSelection: [],
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    successMsg(msg) {
      this.$message({
        type: "success",
        message: msg == null ? '操作成功' : msg,
        duration: 2000,
        onClose: () => {}
      });
    },
    errorMsg(error) {
      this.$message({
        type: "error",
        message: error == null ? '操作异常' : error,
        duration: 2000,
        onClose: () => {}
      });
    },
    warnMsg(error) {
      this.$message({
        type: "warning",
        message: error == null ? '操作异常' : error,
        duration: 2000,
        onClose: () => {}
      });
    },
    jump(path, query) {
      this.$router.push({
        path: path,
        query: query
      });
    },
    getSysUserInfo() {
      this.$store.dispatch('getSysUserInfo');
      this.accessToken = this.$store.state.sysUserInfo.accessToken;
    },
    downloadTemplate(url) {
      this.path = window.location.origin;
      window.location.href = this.path + url;
    },
    resetSearchButtonClicked() {
      cleanObj(this.searchForm);
      this.pagination = {
        current: 1,
        limit: 10,
        total: 0,
        pages: 0,
        max: 1,
        next: 1
      };
      this.fetchData();
    },
    parseEnable(row, column, cellValue, index) {
      return row.state == 0 ? '禁用' : '可用';
    },
    //      当前分页改变
    async handleCurrentChange(val) {
      this.setPaginationNextPage(val);
      this.fetchData();
    },
    searchButtonClicked() {
      this.pagination = {
        current: 1,
        limit: 10,
        total: 0,
        pages: 0,
        max: 1,
        next: 1
      };
      this.fetchData();
    },
    setPaginationNextPage(next) {
      this.pagination.next = next;
    },
    //获取分页跳转数
    getPaginationSkip() {
      return Math.max(1, this.pagination.next);
    },
    // searchButtonClicked() {
    //   this.pagination.current = 1;
    //   this.pagination.next = 1;
    //   this.fetchData();
    // },
    fetchData() {
      //  todo 业务获取数据
    },
    //设置分页总数
    setPaginationTotal(page) {
      this.pagination.total = page.total;
      this.pagination.pages = page.pages;
    },
    checkRow(row, event) {
      this.$refs[this.tableName].toggleRowSelection(row);
    },
    initTable(refreshFlag) {
      let me = this;
      if (refreshFlag) me.tableLoading = true;
      me.ctlId = '';
      if (refreshFlag) me.resetSearchButtonClicked();
    },
    openAlert(msg) {
      this.$alert(msg, '提示', {
        confirmButtonText: '确定',
        callback: action => {

        }
      });
    },
    handleSizeChange(res) {
      this.pagination.limit = res;
      this.pagination.next = 1;
      this.fetchData();
    },
    letsJump(url) {
      if (url) {
        window.open(url);
      }
    },
  },
  deactivated() {
    this.$destroy();
  },
}
<template>
  <!--表单权限-->
  <div class="panel-tab__content">
    <div class="panel-tab__content--title">
      <span>
        <i class="el-icon-menu" style="margin-right: 8px; clolor: #555555"></i
        >表单权限列表
      </span>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-edit-outline"
        @click="dialogFormOpen"
        >表单权限管理</el-button
      >
    </div>
    <el-table :data="formAuthorityShowList" size="small" border>
      <el-table-column label="表单名称" prop="name"></el-table-column>
      <el-table-column label="表单Code" prop="code"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <!-- <el-link type="primary" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-link> -->
          <el-link
            type="primary"
            icon="el-icon-delete"
            @click="handleDelete(row)"
            >删除</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <!--表单权限管理-->
    <el-dialog
      :visible.sync="dialogFieldVisiable"
      title="表单权限管理"
      append-to-body
      destroy-on-close
      :before-close="dialogBeforeClose"
    >
      <form-field-authority
        v-if="dialogFieldVisiable"
        :global-form-type="globalFormType"
        :form-authority-list="formAuthorityList"
        @getRadioAllList="getRadioAllList"
        @close="dialogBeforeClose"
      ></form-field-authority>
    </el-dialog>
    <!--编辑表单权限-->
    <el-dialog
      :visible.sync="dialogEditVisiable"
      title="编辑表单权限"
      append-to-body
      destroy-on-close
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <template>
        <div>
          <el-form
            label-position="right"
            label-width="100px"
            style="margin: 15px"
            size="small"
          >
            <el-row>
              <el-col>
                <el-form-item :label="dialogEditConfig.name">
                  <el-radio-group v-model="dialogEditConfig.status">
                    <el-radio :label="1">不使用</el-radio>
                    <el-radio :label="2">隐藏</el-radio>
                    <el-radio :label="3">可写</el-radio>
                    <el-radio :label="4">只读</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item>
                  <el-button @click="dialogEditVisiable = false"
                    >取消</el-button
                  >
                  <el-button type="primary" @click="handleSave()"
                    >保存</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import formFieldAuthority from "./form-field-authority.vue";
export default {
  components: { formFieldAuthority },
  props: {
    businessObject: Object,
    type: String,
  },
  data() {
    return {
      formAuthorityShowList: [],
      formAuthorityList: [], //表单列表集合
      formAuthorityInfo: {},
      modelVisible: false,
      modelType: "",
      modelObjectForm: {},
      dialogFieldVisiable: false, //控制表单权限显示或隐藏
      dialogEditVisiable: false,
      dialogEditConfig: {
        name: "",
        status: "",
      },
      globalFormType: "",
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    businessObject: {
      immediate: false,
      handler: function (val) {
        if (val) {
          this.$nextTick(() => this.initData());
        }
      },
    },
  },
  methods: {
    initData() {
      this.bpmnElement = window.bpmnInstances.bpmnElement;
      var baseInfo = JSON.parse(
        JSON.stringify(this.bpmnElement.businessObject)
      );
      //只绑定类型为流程的相关信息
      if (baseInfo.$type === "bpmn:UserTask") {
        if (baseInfo.formPermission && !(baseInfo.formPermission === "")) {
          this.formAuthorityShowList = JSON.parse(baseInfo.formPermission);
          this.formAuthorityList = this.formAuthorityShowList;
        } else {
          this.formAuthorityShowList = [];
          this.formAuthorityList = [];
        }
      }

      //取出流程属性值，获取全局表单值
      let processBpmnElement = window.bpmnInstances.elementRegistry.find(
        (el) => el.type === "bpmn:Process"
      );
      let processBaseInfo = JSON.parse(
        JSON.stringify(processBpmnElement.businessObject)
      );
      if (processBaseInfo.globalForm && processBaseInfo.globalForm != "") {
        this.globalFormType = processBaseInfo.globalForm;
      }
    },
    dialogFormOpen() {
      if (this.globalFormType == "") {
        this.$alert("请先选择全局表单！", "提示", {
          confirmButtonText: "确定",
          type: "warning",
        });
      } else {
        this.dialogFieldVisiable = true;
      }
    },
    //获取从子页面选中的数据集
    getRadioAllList(data) {
      this.formAuthorityList = data;
    },
    //列表编辑
    handleEdit(data) {
      this.dialogEditVisiable = true;
      this.dialogEditConfig.name = data.name;
      this.dialogEditConfig.status = data.status;
    },
    //列表删除
    handleDelete(data) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonClass: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功！",
            duration: 1000,
            onClose: () => {
              this.formAuthorityList.splice(
                this.formAuthorityList.indexOf(data),
                1
              );
              this.formAuthorityShowList = this.formAuthorityList;
              this.updateProperties();
            },
          });
        })
        .catch(() => {});
    },
    handleSave() {
      this.$message({
        type: "success",
        message: "修改成功！",
        duration: 1000,
        onClose: () => {
          this.formAuthorityList.forEach((item) => {
            if (item.name == this.dialogEditConfig.name) {
              item.status = this.dialogEditConfig.status;
            }
          });
          this.dialogEditVisiable = false;
        },
      });
    },
    //表单权限页面关闭触发
    dialogBeforeClose() {
      this.dialogFieldVisiable = false;
      this.formAuthorityShowList = this.formAuthorityList;
      this.updateProperties();
    },
    updateProperties() {
      const attrObj = Object.create(null);
      attrObj["formPermission"] = JSON.stringify(this.formAuthorityShowList);
      window.bpmnInstances.modeling.updateProperties(this.bpmnElement, attrObj);
    },
  },
};
</script>
import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import "@/assets/icon/iconfont.css"
import "@/styles/index.less"
import dictCmp from "@/components/dict"
import commonCmp from "@/components/common/index.js"
import AuthAction from "@/components/common/auth.action.js"
import AtlCtrlForET99 from "@/utils/et99"
import dongle from "@/utils/dongle"
import ht from "@/utils/ht"
import 'babel-polyfill'

// 引入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
// require styles 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)
// 自定义按钮
import plButton from './components/pl-button'
Vue.component('plButton', plButton)

// 表单设计器
import FormMaking from "@/assets/FormMaking/FormMaking.umd"
Vue.use(FormMaking)

Vue.config.productionTip = false
Vue.use(ElementUI, {
  size: 'small'
})
//基础字典组件
Vue.use(dictCmp)
//操作权限指令组件
Vue.use(AuthAction)
//公用组件
Vue.use(commonCmp)

import {
  vuePlugin
} from "@/views/process/manage/components/highlight"
import "highlight.js/styles/atom-one-dark-reasonable.css"
Vue.use(vuePlugin)

import MyPD from "@/views/process/manage/components/package/index.js"
Vue.use(MyPD)
import "@/views/process/manage/components/package/theme/index.scss"

//附件查看器
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer)

import axios from 'axios'
import Avue from '@smallwei/avue'
import '@smallwei/avue/lib/index.css'

global.AtlCtrlForET99 = AtlCtrlForET99
global.dongle = dongle
global.ht = ht

Vue.use(Avue, {
  axios
})
//配置项
Viewer.setDefaults({
  zIndexInline: 9999
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
/**
 * Created by DELL on 2019/3/2.
 */
import {
  isEmpty
} from "@/util/util"
export default {
  components: {},
  created () { },
  mounted () {
    var me = this
    // me.init();
    if (me.updateType == 1 || me.updateType == 2) {
      if (isEmpty(me.id)) {
        return
      }
      me.getData()
    }
  },
  computed: {
    isView () {
      return this.updateType == 2
    },
    isEdit () {
      return this.updateType == 1
    }
  },
  props: {
    width: {
      type: String,
      default: "60%"
    },
    id: {
      type: String,
      default: ""
    },
    updateType: {
      // 0.新增 1.修改 2.查看
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: '',
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      item: {},
      loading: false,
      rule: {},
    }
  },
  methods: {
    init () {
      let me = this
      me.reset(me.item)
    },
    // getData() {},
    onSubmit (formName) {
      var me = this
      this.$refs[formName].validate(valid => {
        if (!valid) {
          // console.log("error submit!!");
          this.errorMsg("请按照提示完善表单后进行提交！")
          return false
        }
        if (me.updateType == 1) {
          me.editAgreement()
        } else {
          me.addAgreement()
        }
      })
    },
    reset (obj) {
      let me = this
      for (let key in obj) {
        if (obj[key] && typeof obj[key] === "object") {
          obj[key] = Array.isArray(obj[key]) ? [] : {}
        } else if (obj[key] && typeof data === "string") {
          obj[key] = ""
        } else if (obj[key] && typeof data === "number") {
          obj[key] = 0
        }
      }
    },
    successMsg (msg) {
      this.$message({
        type: "success",
        message: msg == null ? "操作成功" : msg,
        duration: 1000,
        onClose: () => { }
      })
    },
    errorMsg (error) {
      this.$message({
        type: "error",
        message: error == null ? "操作异常" : error,
        duration: 2000,
        onClose: () => { }
      })
    }
  },
  watch: {
    id () {
      this.id = this.id + ""
      this.init()
      if (
        (this.updateType == 1 || this.updateType == 2) &&
        this.id &&
        this.id.length > 0
      ) {
        this.getData()
      }
    }
  }
}
import Vue from "vue"
import VueRouter from "vue-router"
import {
  routes
} from "./module/main.js"

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes
})

router.beforeEach((to, from, next) => {
  const sysUserInfo = JSON.parse(sessionStorage.getItem("sysUserInfo"))
  const screen = JSON.parse(sessionStorage.getItem('screen'))
  if (screen) {
    return next({
      path: '/screen'
    })
  }

  if (from.name != "锁屏" && to.name == "login") {
    if (sysUserInfo != {} && sysUserInfo != undefined && sysUserInfo != null) {
      return next({
        path: "/autologin",
      })
    }
    return next()
  }

  if (sysUserInfo == {} || sysUserInfo == undefined || sysUserInfo == null) {
    if (to.name == "autologin") {
      return next()
    }
    if (to.name == "jiangxi") {
      return next()
    }
    if (to.name == "jiangsu") {
      return next()
    }
    if (to.name == "chongqing") {
      return next()
    }
    if (to.name == "yunnan") {
      return next()
    }
    if (to.name == "hainan") {
      return next()
    }
    if (to.name == "shanghai") {
      return next()
    }
    if (to.name == "guizhou") {
      return next()
    }
    if (to.name == "hunan") {
      return next()
    }
    if (to.name == "sichuan") {
      return next()
    }
    if (to.name == "xizang") {
      return next()
    }
    if (to.name == "neimeng") {
      return next()
    }
    if (to.name == "hebei") {
      return next()
    }
    if (to.name == "shandong") {
      return next()
    }
    if (to.name == "qinghai") {
      return next()
    }
    if (to.name == "liaoning") {
      return next()
    }
    if (to.name == "xinjiang") {
      return next()
    }
    if (to.name == "guangdong") {
      return next()
    }
    if (to.name == "ningxia") {
      return next()
    }
    if (to.name == "fujian") {
      return next()
    }
    if (to.name == "jilin") {
      return next()
    }
    if (to.name == "zhejiang") {
      return next()
    }
    if (to.name == "tianjin") {
      return next()
    }
    if (to.name == "shanxi") {
      return next()
    }
    if (to.name == "shaanxi") {
      return next()
    }
    if (to.name == "heilongjiang") {
      return next()
    }
    if (to.name == "guangxi") {
      return next()
    }
    if (to.name == "hubei") {
      return next()
    }
    if (to.name == "anhui") {
      return next()
    }
    if (to.name == "henan") {
      return next()
    }
    if (to.name == "gansu") {
      return next()
    }
    if (to.name == "bingtuan") {
      return next()
    }
    if (to.name == "beijing") {
      return next()
    }
    if (to.name == 'zyzinfor') {
      return next()
    }
    if (to.name == 'Query.aspx') {
      return next()
    }
    if (to.name == 'jiangsuzyz') {
      return next()
    }
    if (to.name == 'jgptzyz') {
      return next()
    }
    if (to.name == 'seach') {
      return next()
    }
    return next({
      path: "/login"
    })
  }
  return next()

})




export default router
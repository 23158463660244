/**
 * Created by DELL on 2018/9/4.
 */
import Textarea from './Textarea.vue';

export default {
  install: (Vue) => {
    Vue.component('s-textarea',Textarea);
  }
}

<template>
  <el-select
    :placeholder="placeholder ? placeholder : '请选择'"
    :value="value"
    @input="change($event)"
    @change="$emit('change')"
    :size="size"
    :multiple="multiple"
    :collapse-tags="collapseTags"
    :disabled="disabled"
    :filterable="filterable"
    :clearable="clearable"
  >
    <el-option
      v-for="(it, index) in item"
      :label="it.key"
      :value="it.value"
      :key="index"
    ></el-option>
  </el-select>
</template>

<script>
import { getdictListByPid, getdictListByPkey, getDictCache } from "@/api/system/dict"
import { isEmpty } from "@/util/util"
import commonReq from "@/api/system/common.js"
export default {
  name: "DictSelect",
  props: [
    "placeholder",
    "dictKey",
    "value",
    "disabled",
    "multiple",
    "collapseTags",
    "filterable",
    "clearable",
    "size",
    "retainItem", //保留项
    "parentKey",
    "hideList",
  ],
  computed: {
    currentValue () {
      return this.model
    },
  },
  components: {},
  mounted () {
    let me = this
    me.init()
  },
  data () {
    return {
      item: [],
    }
  },
  methods: {
    //	初始化字典缓存
    addItem (childItem) {
      var me = this
      me.item.push(childItem)
    },
    removeItem (index) {
      var me = this
      me.item.splice(index, 1)
    },
    async init () {
      let me = this
      if (!me.value) {
        //console.error("模块放置字典值为空！");
      }
      me.initDict()
    },
    change: function (val) {
      this.$emit("input", val)
    },
    //    获取当前的字典集
    getItem () {
      return this.item
    },
    initDict () {
      const me = this
      if (me.dictKey && me.dictKey.length > 0) {
        //        加载字典项值
        commonReq("dictItem", { key: me.dictKey }, false)
          .then((response) => {
            //          检查保留项
            let selectItem = me.retainSelectItem(response.data)
            for (var i = 0; i < selectItem.length; i++) {
              selectItem[i].value = isNaN(selectItem[i].value)
                ? selectItem[i].value
                : parseInt(selectItem[i].value)
            }
            if (me.parentKey) {
              for (let i = selectItem.length - 1; i >= 0; i--) {
                const element = selectItem[i]
                if (element.value.indexOf(me.parentKey) == -1) {
                  selectItem.splice(i, 1)
                }
              }
            }
            if (me.hideList && me.hideList.length > 0) {
              me.hideList.forEach((item) => {
                for (let i = selectItem.length - 1; i >= 0; i--) {
                  const element = selectItem[i]
                  if (element.value.indexOf(item) != -1) {
                    selectItem.splice(i, 1)
                  }
                }
              })
            }
            if (me.value) {
              let flag = false
              selectItem.forEach((element) => {
                if (element.value.indexOf(me.value) > -1) {
                  flag = true
                }
              })
              if (!flag) {
                me.value = me.multiple ? [] : ""
              }
            }
            me.item = selectItem
          })
          .catch((error) => {
            console.warn(error)
          })
        //        TODO 加载默认值
        //          me.initDefault();
        return
      }
    },
    //    获取默认值
    getDefaultVal () {
      let me = this
      for (var i = 0; i < me.item.length; i++) {
        if (me.item[i].isDefault) {
          return me.item[i].value
        }
      }
    },
    initDefault () {
      let me = this
      if (!me.value) {
        for (var i = 0; i < me.item.length; i++) {
          if (me.item[i].isDefault) {
            me.value = me.item[i].value
          }
        }
      }
    },
    retainSelectItem (deepAry) {
      let me = this
      if (!me.retainItem || me.retainItem.length <= 0) {
        return deepAry
      }
      var tmp = []
      for (var i = 0; i < deepAry.length; i++) {
        if (me.isRetainItem(deepAry[i].value)) {
          tmp.push(deepAry[i])
        }
      }
      return tmp
    },
    isRetainItem (val) {
      let me = this
      for (var i = 0; i < me.retainItem.length; i++) {
        if (me.retainItem[i] == val) {
          return true
        }
      }
      return false
    },
  },
  watch: {
    parentKey (res) {
      // console.log("父级改变！")
      this.init()
    },
  },
};
</script>

<style scoped></style>

export const workbench = [{
  hash: '',
  path: '/workbench/apply/phrase',
  name: '常用语',
  component: () => import('@/views/workbench/apply/phrase')
},
{
  hash: '',
  path: '/workbench/apply/drafts',
  name: '草稿箱',
  component: () => import('@/views/workbench/apply/drafts')
},
{
  hash: '',
  path: '/workbench/manage',
  name: '消息管理',
  component: () => import('@/views/workbench/manage')
},
{
  hash: '',
  path: '/workbench/index',
  name: '工作门户',
  component: () => import('@/views/workbench/index')
},
{
  hash: '',
  path: '/workbench/mailList',
  name: '通讯录',
  component: () => import('@/views/workbench/mailList')
},
{
  hash: '',
  path: '/work/todo',
  name: '事项办理',
  component: () => import('@/views/workbench/mywork/worktodo')
},
{
  hash: '',
  path: '/work/history',
  name: '办理历史',
  component: () => import('@/views/workbench/mywork/workhistory')
},
{
  hash: '',
  path: '/workbench/apply/initiate',
  name: '发起申请',
  component: () => import('@/views/workbench/apply/initiate')
}, {
  hash: '',
  path: '/workbench/apply/history',
  name: '申请历史',
  component: () => import('@/views/workbench/apply/history')
}, {
  hash: '',
  path: '/work/data',
  name: '历史记录',
  component: () => import('@/views/workbench/mywork/workdata')
},
{
  hash: '',
  path: '/workbench/fankui',
  name: '问题反馈',
  component: () => import('@/views/workbench/fankui/index')
},
]
<template>
  <div>
    <el-form label-position="right" label-width="100px" size="small">
      <el-row>
        <el-col :span="6">
          <el-form-item label="类型：">
            <dict-select
              dict-key="process_formType"
              v-model="listConfig.listQuery.type"
              @input="fetchData"
              @keyup.enter.native="search"
              :clearable="true"
            ></dict-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="表单code：">
            <el-input
              v-model="listConfig.listQuery.formCode"
              @keyup.enter.native="search"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="表单名称：">
            <el-input
              v-model="listConfig.listQuery.formName"
              @keyup.enter.native="search"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button
            size="small"
            type="primary"
            class="btn-position-left-10"
            @click="search"
            >查询</el-button
          >
          <el-button size="small" @click="resetInput">重置</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      row-key="id"
      :data="listConfig.queryList"
      @selection-change="handleSelectionChange"
      size="small"
      border
    >
      <el-table-column
        type="selection"
        reserve-selection
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="id"
        label="表单id"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="formType"
        label="表单类型"
        width="80"
        align="center"
      >
        <template slot-scope="{ row }">
          {{ row.formType == 0 ? "表单" : "列表" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="code"
        label="表单编码"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="表单名称"
        align="center"
      ></el-table-column>
      <el-table-column prop="boCode" label="业务对象key" align="center">
        <template slot-scope="{ row }">
          {{ row.formType == 0 ? row.boCode : row.tableCode }}
        </template>
      </el-table-column>
      <el-table-column prop="boName" label="业务对象名称" align="center">
        <template slot-scope="{ row }">
          {{ row.formType == 0 ? row.boName : row.tableName }}
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-if="listConfig.queryList.length > 0"
      v-show="listConfig.totalCount > 0"
      :total="listConfig.totalCount"
      :page.sync="listConfig.listQuery.page"
      :limit.sync="listConfig.listQuery.size"
      @pagination="findList"
    />
    <el-row style="text-align: right">
      <el-button size="small" type="primary" @click="handleSave"
        >确定</el-button
      >
      <el-button size="small" @click="handleCancel">取消</el-button>
    </el-row>
  </div>
</template>
<script>
import { getProcessSheet } from "@/api/process/manage/process.js";
import pagination from "@/components/Pagination";
export default {
  components: { getProcessSheet, pagination },
  data() {
    return {
      loading: true,
      listConfig: {
        listQuery: {
          page: 1,
          size: 10,
          formType: "",
          formCode: "",
          formName: "",
        },
        queryList: [],
        totalCount: 0,
      },
      multipleSelection: [],
    };
  },
  created() {
    this.findList();
  },
  methods: {
    //获取表单列表
    async findList() {
      await getProcessSheet(
        this.listConfig.listQuery,
        (res) => {
          this.listConfig.queryList = res.records;
          this.listConfig.totalCount = res.total;
        },
        (error) => {
          console.warn(error);
          this.$message({
            type: "error",
            message: error,
            duration: 2000,
          });
          console.log("error");
        },
        (exception) => {
          console.log("error");
          console.warn(exception);
        }
      );
    },
    fetchData(data) {
      this.listConfig.listQuery.formType = data;
    },
    //查询
    search() {
      this.findList();
    },
    //重置查询条件
    resetInput() {
      this.listConfig.listQuery = {
        page: 1,
        size: 10,
        formType: "",
        formCode: "",
        formName: "",
      };
    },
    //复选框事件
    handleSelectionChange(data) {
      this.multipleSelection = data;
    },
    //确定
    handleSave() {
      if (!this.multipleSelection || this.multipleSelection.length <= 0) {
        this.$message({
          type: "warning",
          message: "至少选择一行！",
        });
        return;
      }
      this.$emit("choiced", this.multipleSelection);
    },
    //取消
    handleCancel() {
      this.$emit("close");
    },
  },
};
</script>
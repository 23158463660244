<template>
  <el-button v-bind="$attrs" @click.prevent="handleClick">
    <slot />
  </el-button>
</template>

<script>
export default {
  name: "pl-button",
  props: {
    time: {
      type: Number,
      default: 500,
    },
  },
  data () {
    return {
      record: 0,
    }
  },
  methods: {
    handleClick () {
      let newTime = new Date()
      if (newTime.getTime() - this.record > this.time) {
        this.$emit("click")
      }
      this.record = new Date().getTime()
    },
  },
};
</script>

<style lang="less" scoped>
</style>
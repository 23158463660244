<template>
  <div>
    <codemirror
      class="codemirror-main"
      v-model="code"
      :options="options"
      style="height: 100%"
    ></codemirror>
  </div>
</template>
<script>
import { codemirror } from "vue-codemirror"
//导入使用的语言语法定义文件
require("codemirror/mode/clike/clike.js")
require("codemirror/addon/edit/closebrackets.js")
//导入选中的theme文件
require("codemirror/theme/darcula.css")
//导入自动提示核心文件及样式
require("codemirror/addon/hint/show-hint.css")
require("codemirror/addon/hint/show-hint.js")
//导入指定语言的提示文件
require("codemirror/addon/hint/javascript-hint.js")
import "codemirror/lib/codemirror.css" // 核心样式

//代码补全提示
import "codemirror/mode/clike/clike.js"
import "codemirror/mode/sql/sql.js"
import "codemirror/addon/edit/matchbrackets.js"
import "codemirror/addon/comment/comment.js"
import "codemirror/addon/dialog/dialog.js"
import "codemirror/addon/dialog/dialog.css"
import "codemirror/addon/search/searchcursor.js"
import "codemirror/addon/search/search.js"
import "codemirror/keymap/sublime.js"

export default {
  props: {
    codeStr: {
      type: String,
      default: ''
    }
  },
  components: { codemirror },
  data () {
    return {
      code: "", //编辑器绑定的值
      //默认配置
      options: {
        mode: "text/x-java", //实现Java代码高亮
        tabSize: 4, //缩进格式
        theme: "darcula", //主题
        lineNumbers: true, //显示行号
        line: true,
        styleActiveLine: true, //高亮选中行
        hintOptions: {
          completeSingle: true, //当匹配只有一项的时候是否自动补全
        },
        lineWrapping: true, //是否代码折叠
        matchBrackets: true, //括号匹配
        lint: true,
        gutters: [
          "CodeMirror-linenumbers",
          "CodeMirror-foldgutter",
          "CodeMirror-lint-markers",
        ],
        extraKeys: { Ctrl: "autocomplete" },
      },
    }
  },
  watch: {
    codeStr: {
      immediate: true,
      handler: function (data) {
        this.code = data
      },
    },
    code: {
      immediate: true,
      handler: function (data) {
        this.$emit("getCodeStr", data)
      },
    },
  },
  created () {
    if (this.codeStr != "") {
      this.code = this.codeStr
    }
  },
};
</script>
<style>
.codemirror-main {
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #eee;
}
.cm-s-darcula.CodeMirror {
  height: 100%;
}
</style>

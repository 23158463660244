<template>
  <div
    v-loading="loading"
    element-loading-text="拼命加载中......"
    style="height: 650px"
  >
    <codemirror
      ref="script"
      :codeStr="scriptString"
      @getCodeStr="getCodeStr"
      style="height: 95%"
    ></codemirror>
    <br />
    <div class="btn-position-right">
      <el-button type="primary" @click="handleSave">保存</el-button>
      <el-button @click="handleCancel">取消</el-button>
    </div>
  </div>
</template>
<script>
import codemirror from "@/components/codemirror"
export default {
  props: {
    scriptList: Array,
    importScript: String,
  },
  components: { codemirror },
  data () {
    return {
      loading: true,
      scriptString: "",
      returnType: {
        voidStr: "void",
        booleanStr: "Boolean",
        stringStr: "String",
      },
      importScriptString: "",
    }
  },
  created () {
    setTimeout(() => {
      this.initData()
      this.loading = false
    }, 1000)
  },
  methods: {
    initData () {
      //自动生成流程前置脚本和流程后置脚本
      let { voidStr, booleanStr, stringStr } = this.returnType

      this.importScriptString = this.importScript

      let script = ""
      let funType = voidStr

      // console.log("importScriptString:" + this.importScriptString);
      if (this.importScriptString == "") {
        this.importScriptString +=
          "import cn.cehuifuwu.approval.common.util.AssertUtil\n"
        this.importScriptString += "import cn.hutool.core.map.MapUtil\n"
        this.importScriptString += "import cn.hutool.core.util.StrUtil\n"
        this.importScriptString +=
          "import cn.hutool.extra.spring.SpringUtil\n\n"
      }
      script += this.importScriptString

      if (this.scriptList != [] && this.scriptList.length > 0) {
        this.scriptList.forEach((item) => {
          script +=
            funType +
            " " +
            item.code +
            "_before() {" +
            item.beforeCode +
            "}\n\n"
          script +=
            funType + " " + item.code + "_after() {" + item.afterCode + "}\n\n"
        })

        this.scriptString = script
        this.$emit("getCodeStr", {
          scriptString: this.scriptString,
          importScript: this.importScriptString,
        })
      }
    },
    getCodeStr (data) {
      this.scriptString = data
    },
    handleSave () {
      this.$message({
        type: "success",
        message: "保存成功！",
        duration: 1000,
        onClose: () => {
          this.$emit("getCodeStr", {
            scriptString: this.scriptString,
            importScript: this.importScriptString,
          })
          this.handleCancel()
        },
      })
    },
    handleCancel () {
      this.$emit("close")
    },
  },
};
</script>
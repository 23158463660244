<template>
  <div element-loading-text="拼命加载中...">
    <el-form
      label-position="right"
      label-width="100px"
      style="margin: 15px"
      size="small"
    >
      <el-row>
        <el-col>
          <el-form-item label="批量操作">
            <el-radio-group v-model="radioData" @change="radioChange()">
              <el-radio :label="1">全部不使用</el-radio>
              <el-radio :label="2">全部隐藏</el-radio>
              <el-radio :label="3">全部可写</el-radio>
              <el-radio :label="4">全部只读</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" v-for="(item, index) in radioList" :key="index">
          <el-form-item :label="item.name">
            <el-radio-group v-model="item.status" @change="radioChange2(item)">
              <el-radio :label="1">不使用</el-radio>
              <el-radio :label="2">隐藏</el-radio>
              <el-radio :label="3">可写</el-radio>
              <el-radio :label="4">只读</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item>
            <el-button type="primary" @click="handleBackToLastForm"
              >返回上一级</el-button
            >
            <el-button type="primary" @click="handleSave">确定</el-button>
            <el-button type="primary" @click="handleReset">重置</el-button>
            <el-button @click="handleBackToLastForm">取消</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    id: String,
    code: String,
    childList: Array,
  },
  data() {
    return {
      radioData: "",
      radioList: [],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    //初始化子表单
    initData() {
      if (this.childList != [] && this.childList.length > 0) {
        this.childList.forEach((item) => {
          var model = {
            id: item.id,
            code: item.code,
            name: item.name,
            status: item.status,
          };
          this.radioList.push(model);
          this.radioChange2(item);
        });
      } else {
        this.$message({
          type: "warning",
          message: "暂未获取到相关表单元素信息！",
          duration: 2000,
        });
      }
    },
    //批量操作
    radioChange() {
      this.radioList.forEach((item) => {
        item.status = this.radioData;
      });
    },
    //子表单元素操作
    radioChange2(item) {
      this.radioData = "";
      var flag = 0;
      this.radioList.forEach((data) => {
        if (data.status == item.status) {
          flag = flag + 1;
        }
      });
      if (flag == this.radioList.length) {
        this.radioData = item.status;
      }
    },
    //返回上一级
    handleBackToLastForm() {
      this.$emit("close");
    },
    //保存
    handleSave() {
      this.$emit("getChildList", { id: this.id, childList: this.radioList });
    },
    //重置
    handleReset() {
      this.radioData = "";
      this.radioList.forEach((item) => {
        item.status = "";
      });
    },
  },
};
</script>
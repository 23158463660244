import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const getSysUserInfoFromStorage = () => {
  const sysUserInfo = JSON.parse(sessionStorage.getItem('sysUserInfo'))
  return sysUserInfo
}

export default new Vuex.Store({
  state: {
    sysUserInfo: {}, // 用户数据
    active: '', // 被选中的一级菜单
    dictData: [],
    isCollapse: false,
  },
  mutations: {
    setSysUserInfo (state, sysUserInfo) {
      sessionStorage.setItem('sysUserInfo', JSON.stringify(sysUserInfo))
      state.sysUserInfo = sysUserInfo
    },
    getSysUserInfo (state) {
      if (!state.sysUserInfo || !state.sysUserInfo.accessToken) {
        state.sysUserInfo = getSysUserInfoFromStorage()
      }
    },
    setActive (state, active) {
      state.active = active
    },
    //设置字典缓存
    setDictData (state, dictData) {
      localStorage.setItem("dictData", JSON.stringify(dictData))
      state.dictData = dictData
    },
    reloadDictData (state) {
      const dictData = JSON.parse(localStorage.getItem('dictData'))
      state.dictData = dictData
    },
    toggleCollapse (state, flag) {
      state.isCollapse = flag ? flag : !state.isCollapse
    },
  },
  actions: {
    getSysUserInfo ({
      commit
    }) {
      commit('getSysUserInfo')
    },
  },
})
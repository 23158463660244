if (typeof Object.id == "undefined") {
    var id = 0;
    Object.id = function(o) {
        if (typeof o.__uniqueid == "undefined") {
            Object.defineProperty(o, "__uniqueid", {
                value: ++id,
                enumerable: false,
                // This could go either way, depending on your 
                // interpretation of what an "id" is
                writable: false
            });
        }
        return o.__uniqueid;
    };
}

let HashTable = {    
    size:0,
    entry:new Object(),		
    add(key , value){
        if(!this.containsKey(key)){
            this.size ++ ;
        }
        this.entry[key] = value;
    },
    getValue:function(key){
        return this.containsKey(key) ? this.entry[key] : null;
	},
	remove:function(key){
        if(this.containsKey(key) && (delete this.entry[key])){
            this.size --;
        }
    },
    containsKey:function(key){
        return (key in this.entry);
    },
    containsValue:function(value){
        for(var prop in this.entry){
            if(this.entry[prop] == value){
                return true;
            }
        }
        return false;
    },
    getValues:function(){
        var values = new Array();
        for(var prop in entry){
            values.push(this.entry[prop]);
        }
        return values;
    },
    getKeys:function (){
        var keys = new Array();
        for(var prop in this.entry){
            keys.push(prop);
        }
        return keys;
    },
    getSize:function(){
        return size;
    },
    clear:function(){
        size = 0;
        entry = new Object();
    }
}

class AtlCtrlForET99 {	
     AtlCtrlForET99 = function(clsid) {
        _classCallCheck(this, AtlCtrlForET99);
        this.clsid = clsid;
    } 
    clsid="{7356CC90-5909-4f44-A07E-329D0BD3FF81}";  
	pid= 'ffc5eb78';
	et99index = 1;
    ready_func_ = null;
    callbacks_ = HashTable;
	module_ = "JS_IActiveXCtrl";
    ws = null;
    initWS=()=>{
        if ('WebSocket' in window && !this.ws) {
            this.ws = new WebSocket("ws://127.0.0.1:7321");
            this.ws.onerror = function () {}
            this.ws.onopen = this.loadModule.bind(this);
            this.ws.onclose = this.closeModule.bind(this);
            this.ws.onclose = function(){};
            this.callbacks_.add("LoadModule", this.loadModuleCallBack);
        }
        else{
            throw "WebSocket not supported";
        }
    }   
	loadModule=function() {
    	var msg = JSON.stringify({
        	"MsgId": "LoadModule",
        	"Module": this.module_});
        this.ws.onmessage = this._callback.bind(this);        
    	this.ws.send(msg);
    }
    closeModule=function(e){       
    }
	loadModuleCallBack=function(result, response) {
   	 	if (!result) throw result.Response;
    	if (this.ready_func_) ready_func_();
	}.bind(this)
	exec=function(func, param, callback) {
        var msg_id = Object.id(callback).toString();
        this.callbacks_.add(msg_id, callback);
        var param_ = {
            "MsgId": msg_id,
            "Method": func + "|" + this.clsid
        };
        if (param) {
            param_["Param"] = JSON.stringify(param);
        }
        var msg = JSON.stringify(param_);
        this.ws.send(msg);
    }
    ready=function(func) {
        this.ready_func_ = func;    
    }
    _callback=function(response) {
        var r = JSON.parse(response.data);
        var msg_id = r.MsgId;
        if(r.Result){
        }else{
        }
        if(!this.callbacks_.containsKey(msg_id)){
            return;
        }
        var callback = this.callbacks_.getValue(msg_id);
        callback(r.Result, r.Response);     
        this.callbacks_.remove(msg_id);
    }
    ET99_FindToken=function(callback){
        this.exec("ET99_FindToken", [this.pid], callback);
    }
    ET99_OpenToken=function(callback){
        this.exec("ET99_OpenToken", [this.pid,parseInt(this.et99index)], callback);
    }
    ET99_GetSN=function(callback){
        this.exec("ET99_GetSN", null, callback);
    }
    ET99_Verify=function(callback){
        this.exec("ET99_Verify", [this.usertype,this.userpin], callback);
    }
    ET99_GenRandom=function(callback){
        this.exec("ET99_GenRandom", null, callback);
    }
    ET99_SetKey=function(callback){
     this.exec("ET99_SetKey", [parseInt(this.keyid),this.keybuff], callback);
    }
    ET99_HMAC_MD5=function(callback){
        this.exec("ET99_HMAC_MD5", [parseInt(this.keyid),this.md5input.length,this.md5input], callback);
    }
    ET99_CloseToken=function(callback){
        this.exec("ET99_CloseToken", null, callback);
    }
    ET99_Read=function(callback){
        this.exec("ET99_Read",[parseInt(this.roffset),parseInt(this.rlen)], callback);
    }
    ET99_Write=function(callback){
        this.exec("ET99_Write", [parseInt(this.woffset),parseInt(this.wlen),this.databuf], callback);
    }
    GetMacAddress=function(callback){
        this.exec("GetMacAddress", null, callback);
    }
    ET99_ChangeUserPIN=function(callback){
        this.exec("ET99_ChangeUserPIN", [this.oldpin,this.newpin],callback);
    }
    ET99_GenPID=function(callback){
        this.exec("ET99_GenPID",[this.pidseed],callback);
    }
}

export default AtlCtrlForET99;

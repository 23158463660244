import { urlWrapper, axiosPostFileWrapper, axiosPostWrapper, axiosGetWrapper, axiosDeleteWrapper, axiosPostJsonWrapper, axiosPutJsonWrapper, axiosPutWrappe } from '@/api/index';

export const getList = (data, success, error, exception) => {
  axiosGetWrapper('/api/sys/dict/list', data, success, error, exception);
};
export const getListByPid = (data, success, error, exception) => {
  axiosGetWrapper('/api/sys/dict/list', data, success, error, exception);
};

export const getDictRootNode = (data, success, error, exception) => {
  axiosGetWrapper('/api/sys/dict/tree', data, success, error, exception);
};

export const getDataById = (data, success, error, exception) => {
  axiosGetWrapper('/api/sys/dict/get', data, success, error, exception);
};

export const add = (data, success, error, exception) => {
  axiosPostJsonWrapper('/api/sys/dict/add', data, success, error, exception);
};

export const reloadGloadDictCache = (data, success, error, exception) => {
  axiosPostWrapper('/api/sys/dict/reloadDictCache', data, success, error, exception);
};

export const edit = (data, success, error, exception) => {
  axiosPutJsonWrapper('/api/sys/dict/edit', data, success, error, exception);
};

export const deleteByid = (data, success, error, exception) => {
  axiosDeleteWrapper('/api/sys/dict/del', data, success, error, exception);
};

export const getdictListByPid = (data, success, error, exception) => {
  axiosGetWrapper('/api/sys/dict/dataByPid', data, success, error, exception);
};

export const getdictListByPkey = (data, success, error, exception) => {
  axiosGetWrapper('/api/sys/dict/dataByPKey', data, success, error, exception);
};

export const getDictCache = (data, success, error, exception) => {
  axiosGetWrapper('/api/sys/dict/getDictCache', data, success, error, exception);
};

export const changeEnable = (data, success, error, exception) => {
  axiosPostWrapper('/api/sys/dict/changeEnable', data, success, error, exception);
};

export const getDictItem = (data, success, error, exception) => {
  axiosPostWrapper('/api/sys/dict/item', data, success, error, exception, false);
};


export const exportDict = (data) => {
  return urlWrapper('/api/sys/dict/exportDict', data);
};

export const exportDictItem = (data) => {
  return urlWrapper('/api/sys/dict/exportDictItem', data);
};
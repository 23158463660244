<template>
  <el-select
    placeholder="请选择"
    :value="value"
    @input="change($event)"
    @change="changeHandler"
    :size="size"
    :multiple="multiple"
    :disabled="disabled"
    :clearable="clearable"
  >
    <el-option
      v-for="(it, index) in item"
      :label="it.key"
      :value="it.value"
      :key="index"
    ></el-option>
  </el-select>
</template>

<script>
import { getdictListByPid, getdictListByPkey, getDictCache } from "@/api/system/dict"
import { isEmpty } from "@/util/util"
export default {
  name: "DictSelect",
  props: [
    "dictKey",
    "value",
    "disabled",
    "multiple",
    "clearable",
    "size",
    "retainItem", //保留项
  ],
  computed: {
    currentValue () {
      return this.model
    },
  },
  components: {},
  mounted () {
    let me = this
    me.init()
  },
  data () {
    return {
      item: [],
    }
  },
  methods: {
    changeHandler (val) {
      var me = this
      var data = me.item.filter((d) => d.value == val)
      me.$emit("change", data && data.length > 0 ? data[0] : null)
    },
    //	初始化字典缓存
    addItem (childItem) {
      var me = this
      me.item.push(childItem)
    },
    removeItem (index) {
      var me = this
      me.item.splice(index, 1)
    },
    async init () {
      let me = this
      me.$store.commit("reloadDictData")
      if (!me.value) {
        //console.error("模块放置字典值为空！");
      }
      if (!me.$store.state.dictData || me.$store.state.dictData.length < 0) {
        await getDictCache(
          {},
          (response) => {
            this.$store.commit("setDictData", response)
            me.initDict()
          },
          (error) => {
            console.warn(error)
            this.$message.error(error)
          }
        )
      } else me.initDict()
    },
    change: function (val) {
      this.$emit("input", val)
    },
    //    获取当前的字典集
    getItem () {
      return this.item
    },
    initDict () {
      const me = this
      if (me.dictKey && me.dictKey.length > 0) {
        //        加载字典项值
        me.loadDataByKey()
        //        TODO 加载默认值
        //          me.initDefault();
        return
      }
    },
    //    获取默认值
    getDefaultVal () {
      let me = this
      for (var i = 0; i < me.item.length; i++) {
        if (me.item[i].isDefault) {
          return me.item[i].value
        }
      }
    },
    initDefault () {
      let me = this
      if (!me.value) {
        for (var i = 0; i < me.item.length; i++) {
          if (me.item[i].isDefault) {
            me.value = me.item[i].value
          }
        }
      }
    },
    loadDataByKey () {
      let me = this
      var item = me.findDictItem(me.$store.state.dictData)
      if (item) {
        //        检测值是否为数值，进行转换
        var deepAry = JSON.parse(JSON.stringify(item))
        for (var i = 0; i < deepAry.length; i++) {
          if (!isNaN(deepAry[i].value)) {
            deepAry[i].value = parseInt(deepAry[i].value)
          }
        }
        //        检查保留项
        me.item = me.retainSelectItem(deepAry)
      }
    },
    retainSelectItem (deepAry) {
      let me = this
      if (!me.retainItem || me.retainItem.length <= 0) {
        return deepAry
      }
      var tmp = []
      for (var i = 0; i < deepAry.length; i++) {
        if (me.isRetainItem(deepAry[i].value)) {
          tmp.push(deepAry[i])
        }
      }
      return tmp
    },
    isRetainItem (val) {
      let me = this
      for (var i = 0; i < me.retainItem.length; i++) {
        if (me.retainItem[i] == val) {
          return true
        }
      }
      return false
    },
    findDictItem (item) {
      let me = this
      var tmp = null
      if (!item || item.length <= 0) {
        return null
      }
      for (var i = 0; i < item.length; i++) {
        //        最底层无需遍历
        if (!item[i].childDict || item[i].childDict.length <= 0) {
          continue
        }
        //        如果命中
        if (item[i].key === me.dictKey) {
          tmp = item[i].childDict
          break
        }
        //        遍历子元素
        if (!item[i].childDict || item[i].childDict.length <= 0) {
          continue
        }
        tmp = me.findDictItem(item[i].childDict)
        if (tmp && tmp.length > 0) {
          return tmp
        }
      }
      return tmp
    },
  },
  watch: {},
};
</script>

<style scoped>
</style>

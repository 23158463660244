<template>
  <div v-loading="loading" element-loading-text="拼命加载中...">
    <el-form
      label-position="right"
      label-width="100px"
      style="margin: 15px"
      size="small"
      :model="variableInfo"
      ref="ref_variableInfo"
      :rules="rules"
    >
      <el-row>
        <el-col>
          <el-form-item label="脚本类型：" prop="scriptType">
            <el-select v-model="variableInfo.scriptType" clearable>
              <el-option
                v-for="(item, index) in scriptTypeArr"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <!-- <dict-select size="small" dict-key="process_script" v-model="variableInfo.scriptType" :clearable="true"></dict-select> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="变量选择：">
            <el-button type="primary">常用脚本</el-button>
            <el-button type="primary">流程变量</el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="脚本：" prop="scriptString">
            <codemirror
              ref="script"
              :codeStr="this.scriptInfo.scriptString"
              @getCodeStr="getCodeStr"
            ></codemirror>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="提示："
            ><span
              >任务创建时候执行，可以使用[variableScope]操作流程变量，eg:variableScope.setVariable("total",100);等</span
            ></el-form-item
          >
        </el-col>
      </el-row>
      <el-row style="text-align: right">
        <el-col>
          <el-form-item>
            <el-button type="primary" @click="handleSave">保存</el-button>
            <el-button type="primary" @click="handleScriptTest"
              >脚本测试</el-button
            >
            <el-button size="small" @click="handleReset">重置</el-button>
            <el-button @click="handleCancel">取消</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import codemirror from "@/components/codemirror";
import { testScript } from "@/api/system/script";
export default {
  components: {
    codemirror,
  },
  props: {
    scriptInfo: Object,
  },
  data() {
    return {
      loading: true,
      variableInfo: {
        scriptType: "process_script_before",
        scriptString: "",
      },
      scriptTypeArr: [
        { label: "前置", value: "process_script_before" },
        { label: "后置", value: "process_script_after" },
      ],
      rules: {
        scriptType: [
          { required: true, message: "请选择脚本类型！", trigger: "change" },
        ],
        scriptString: [
          { required: true, message: "请输入脚本代码！", trigger: "change" },
        ],
      },
    };
  },
  created() {
    if (this.scriptInfo != {}) {
      this.getInfo();
    }
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  methods: {
    getInfo() {
      const { scriptType, type, scriptString } = this.scriptInfo;
      this.variableInfo = {
        scriptType: scriptType == "" ? "process_script_before" : scriptType,
        scriptString: scriptString,
      };
    },
    //获取脚本
    getCodeStr(data) {
      this.variableInfo.scriptString = data;
    },
    //脚本测试
    handleScriptTest() {
      if (this.variableInfo.scriptString == "") {
        this.$message({
          type: "error",
          message: "脚本不能为空！",
          duration: 2000,
        });
      } else {
        let Base64 = require("js-base64").Base64;
        var base64Str = Base64.encode(this.variableInfo.scriptString);
        this.testScriptAsync(base64Str);
      }
    },
    async testScriptAsync(base64Str) {
      await testScript(
        { script: base64Str },
        (res) => {
          this.$message({
            type: "success",
            message: "测试通过！",
            duration: 1000,
            onClose: () => {},
          });
        },
        (error) => {
          console.warn(error);
          this.$message({
            type: "error",
            message: error,
            duration: 2000,
          });
          console.log("error");
        },
        (exception) => {
          console.log("error");
          console.warn(exception);
        }
      );
    },
    //保存
    handleSave() {
      this.$refs["ref_variableInfo"].validate((valid) => {
        if (valid) {
          this.$message({
            type: "success",
            message: "保存成功！",
            duration: 1000,
            onClose: () => {
              this.$emit("getScriptEditInfo", this.variableInfo);
            },
          });
        }
      });
    },
    //脚本重置
    handleReset() {},
    //取消
    handleCancel() {
      this.$emit("close");
    },
  },
};
</script>

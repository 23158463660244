
/*
ht.js
作者：baifu 2020年8月～
*/
const setAttr = function (attr, val) {
    let obj = document.createAttribute(attr);
    obj.value = val;
    return obj;
}

class htDog {
    // 实现jquery里的append方法
    domAppend(body, id) {
        let obj = document.createElement("OBJECT");
        let objId = ''
        if (id) {
            objId = setAttr("id", id);
        } else {
            objId = setAttr("id", "ht"); 
        }
        obj.setAttributeNode(objId);
        let objClassid = setAttr(
            "classid",
            "clsid:FB4EE423-43A4-4AA9-BDE9-4335A6D3C74E"//
        );

        obj.setAttributeNode(objClassid);

        //如果电脑中没有安装插件，此属性会在IE浏览器下自动提示下载
        let codebase = setAttr("codebase", "/download/ocx") 
        obj.setAttributeNode(codebase);

        let objWidth = setAttr("width", 0);
        obj.setAttributeNode(objWidth);

        let objHeight = setAttr("height", 0);
        obj.setAttributeNode(objHeight);

        let objhspace = setAttr("hspace", 0);
        obj.setAttributeNode(objhspace);

        let objvspace = setAttr("vspace", 0);
        obj.setAttributeNode(objvspace);

        body.appendChild(obj);
    }
}

const ht = new htDog()
export default ht
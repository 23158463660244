<template>
  <el-tree :load="loadNode" :props="props" @node-click="handleNodeClick" highlight-current lazy node-key="value" v-bind="$attrs" v-on="$listeners"></el-tree>
</template>

<script>
import { getdictListByPid, getdictListByPkey, getDictCache } from "@/api/system/dict";
import { isEmpty } from "@/util/util";
import commonReq from "@/api/system/common.js";
export default {
  name: "DictTree",
  props: {
    dictKeys: {
      type: Array,
      required: true,
    },
  },
  computed: {
    currentValue() {
      return this.model;
    },
  },
  watch: {},
  components: {},
  mounted() {},
  data() {
    return {
      props: {
        label: "key",
        children: "children",
        isLeaf: "leaf",
      },
    };
  },
  methods: {
    loadNode(node, resolve) {
      const dictKey = this.dictKeys[node.level];
      const value = node.data ? node.data.value : null;
      // 加载字典项值
      commonReq("dictItem", { key: dictKey }, false)
        .then((response) => {
          // console.log(node, value);
          let data = response.data || [];
          if (value) {
            data = data.filter((item) => item.value.startsWith(value));
          }

          if (node.level == this.dictKeys.length - 1) {
            data.forEach((item) => {
              item.leaf = true;
            });
          }

          resolve(data);
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    handleNodeClick(data, node) {
      if (data.leaf) this.$emit("on-leaf-select", data, node);
    },
  },
};
</script>

<style scoped>
</style>

export const form = [
  {
    hash: "",
    path: "/form/definition",
    name: "表单定义",
    component: () => import("@/views/form/formDefinition/index")
  },
  {
    hash: "",
    path: "/form/template",
    name: "表单模板",
    component: () => import("@/views/form/formTemplate/index")
  }
]

export const business = [
  {
    hash: "",
    path: "/business/table",
    name: "业务实体",
    component: () => import("@/views/form/busTable/index")
  },
  {
    hash: "",
    path: "/business/table/design",
    name: "表单列表设计",
    component: () => import("@/views/form/busTable/tableDesign/design.vue")
    // component: () => import("@/views/form/formDefinition/multiFormView.vue")
  },
  {
    hash: "",
    path: "/business/object",
    name: "业务对象",
    component: () => import("@/views/form/busObject/index")
  }
]
<template>
  <!--处理主体设置-->
  <div class="panel-tab__content">
    <el-button size="small" type="primary" @click="dialogUserVisible = true"
      >选择用户主体</el-button
    >
    <el-button size="small" type="primary" @click="dialogRoleVisible = true"
      >选择角色主体</el-button
    >
    <el-table :data="ruleList" size="small" border style="margin-top: 10px">
      <el-table-column label="主体类型" prop="rule" width="80">
        <template slot-scope="{ row }">
          {{ row.rule == "user" ? "用户" : "角色" }}
        </template>
      </el-table-column>
      <el-table-column label="包含主体" prop="description"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="{ row }">
          <el-link
            type="primary"
            v-if="currentUserVisible"
            icon="el-icon-edit"
            @click="
              row.rule == 'user'
                ? (dialogUserVisible = true)
                : (dialogRoleVisible = true)
            "
            >编辑</el-link
          >
          <el-link
            type="primary"
            icon="el-icon-delete"
            @click="handleDelete(row)"
            >删除</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <!--选择用户主体-->
    <user-choice
      :choiceType="choiceType"
      :dialogVisible="dialogUserVisible"
      @dialogVisibleClose="dialogUserVisible = false"
      @choiced="selectUserData"
    ></user-choice>
    <!--选择角色主体-->
    <role-choice
      :choiceType="choiceType"
      :dialogVisible="dialogRoleVisible"
      @dialogVisibleClose="dialogRoleVisible = false"
      @choiced="selectRoleData"
    ></role-choice>
  </div>
</template>
<script>
import userChoice from "@/components/choice/userChoice.vue"
import roleChoice from "@/components/choice/roleChoice.vue"
// import { getStor } from "@/utils/stor.js"
export default {
  components: { userChoice, roleChoice },
  props: {
    businessObject: Object,
  },
  data () {
    return {
      userList: [],
      rolesList: [],
      ruleList: [],
      choiceType: "multiple",
      dialogUserVisible: false,
      dialogRoleVisible: false,
      currentUserVisible: true,
    }
  },
  mounted () {
    this.initData()
  },
  watch: {
    businessObject: {
      immediate: false,
      handler: function (val) {
        if (val) {
          this.$nextTick(() => this.initData())
        }
      },
    },

  },
  methods: {
    //初始化页面
    initData () {
      if (this.ruleList != []) {
        let user = localStorage.getItem('c_user')
        if (user) {
          user = JSON.parse(user)
        }
        let role = localStorage.getItem('c_role')
        if (role) {
          role = JSON.parse(role)
        }
        if (user) {
          this.userList.push(user)
          var userData = []
          userData = user
          if (userData != [] && userData.length > 0) {
            var ruleInfo = {
              rule: "user",
              description: "",
              source: [],
            }
            var userList = []
            userData.forEach((item) => {
              ruleInfo.description = ruleInfo.description + item.name + ","
              let { account, name, identityType } = item
              var userInfo = {
                code: account,
                name: name,
                type: identityType,
              }
              userList.push(userInfo)
            })
            ruleInfo.description = "[用户]" + ruleInfo.description
            ruleInfo.source = userList
            this.checkRuleList(ruleInfo)
          }

        }
        if (role) {
          var roleData = []
          roleData = role
          this.rolesList.push(role)
          if (roleData != [] && roleData.length > 0) {
            var ruleInfo = {
              rule: "role",
              description: "",
              source: [],
            }
            var roleList = []
            roleData.forEach((item) => {
              ruleInfo.description = ruleInfo.description + item.name + ","
              let { code, name, identityType } = item
              var roleInfo = {
                code: code,
                name: name,
                type: identityType,
              }
              roleList.push(roleInfo)
            })
            ruleInfo.description = "[角色]" + ruleInfo.description
            ruleInfo.source = roleList
            this.checkRuleList(ruleInfo)
          }
        }
      }
    },
    //返回选择的用户主体数据
    selectUserData (data) {
      this.currentUserVisible = true
      var userData = []
      userData = data
      localStorage.setItem('c_user', JSON.stringify(data))
      this.userList.push(data)
      if (userData != [] && userData.length > 0) {
        var ruleInfo = {
          rule: "user",
          description: "",
          source: [],
        }
        var userList = []
        userData.forEach((item) => {
          ruleInfo.description = ruleInfo.description + item.name + ","
          let { account, name, identityType } = item
          var userInfo = {
            code: account,
            name: name,
            type: identityType,
          }
          userList.push(userInfo)
        })
        ruleInfo.description = "[用户]" + ruleInfo.description
        ruleInfo.source = userList
        this.checkRuleList(ruleInfo)
      }
      this.dialogUserVisible = false

    },
    //返回选择的角色主体数据
    selectRoleData (data) {
      this.currentUserVisible = true
      var roleData = []
      roleData = data
      localStorage.setItem('c_role', JSON.stringify(data))
      this.rolesList.push(data)
      if (roleData != [] && roleData.length > 0) {
        var ruleInfo = {
          rule: "role",
          description: "",
          source: [],
        }
        var roleList = []
        roleData.forEach((item) => {
          ruleInfo.description = ruleInfo.description + item.name + ","
          let { code, name, identityType } = item
          var roleInfo = {
            code: code,
            name: name,
            type: identityType,
          }
          roleList.push(roleInfo)
        })
        ruleInfo.description = "[角色]" + ruleInfo.description
        ruleInfo.source = roleList
        this.checkRuleList(ruleInfo)
      }

      this.dialogRoleVisible = false
    },
    //主体列表删除
    handleDelete (data) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (data.rule == 'user') {
            localStorage.setItem('c_user', null)
          } else if (data.rule == 'role') {
            localStorage.setItem('c_role', JSON.stringify(null))
          }
          this.ruleList.splice(this.ruleList.indexOf(data), 1)
          this.updateProperties(2)
          // if (this.disabledUser && this.disabledRole) {
          //   //删除当前选定用户，删除完成后，恢复该按钮为可操作
          //   this.disabledUser = false;
          //   this.disabledRole = false;
          // } else {
          //   this.disabledCurrentUser = false;
          // }
        })
        .catch(() => { })
    },
    //验证主体列表
    checkRuleList (data) {
      var flag = false
      if (this.ruleList != [] && this.ruleList.length > 0) {
        for (let item of this.ruleList) {
          if (item.rule == data.rule) {
            item.rule = data.rule
            item.description = data.description
            item.source = data.source
            flag = true
            break
          }
        }
      }
      if (!flag) {
        this.ruleList.push(data)
      }
    },

  },
};
</script>
<style>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-tag {
  cursor: pointer;
  margin-top: 5px;
}
</style>

<!--流程按钮顶部操作按钮行-->
<template>
  <div class="my-process-designer">
    <div class="my-process-designer__header" style="position: relative">
      <slot name="control-header"></slot>
      <template v-if="!$slots['control-header']">
        <!-- <span style="margin-left: 20px; margin-right: 10px;font-size:16px;">当前流程：{{ this.processName }}</span> -->
        <!-- <el-button-group key="file-control">
          <el-button :size="headerButtonSize" :type="headerButtonType" icon="el-icon-folder-opened" @click="$refs.refFile.click()">打开文件</el-button>
          <el-tooltip effect="light">
            <div slot="content">
              <el-button :size="headerButtonSize" type="text" @click="downloadProcessAsXml()">下载为XML文件</el-button>
              <br />
              <el-button :size="headerButtonSize" type="text" @click="downloadProcessAsSvg()">下载为SVG文件</el-button>
              <br />
              <el-button :size="headerButtonSize" type="text" @click="downloadProcessAsBpmn()">下载为BPMN文件</el-button>
            </div>
            <el-button :size="headerButtonSize" :type="headerButtonType" icon="el-icon-download">下载文件</el-button>
          </el-tooltip>
          <el-tooltip effect="light">
            <div slot="content">
              <el-button :size="headerButtonSize" type="text" @click="previewProcessXML">预览XML</el-button>
              <br />
              <el-button :size="headerButtonSize" type="text" @click="previewProcessJson">预览JSON</el-button>
            </div>
            <el-button :size="headerButtonSize" :type="headerButtonType" icon="el-icon-view">预览</el-button>
          </el-tooltip>
        </el-button-group> -->
        <el-button-group key="scale-control">
          <el-tooltip effect="light" content="缩小视图">
            <pl-button
              :size="headerButtonSize"
              :disabled="defaultZoom < 0.2"
              icon="el-icon-zoom-out"
              @click="processZoomOut()"
            />
          </el-tooltip>
          <pl-button :size="headerButtonSize">{{
            Math.floor(this.defaultZoom * 10 * 10) + "%"
          }}</pl-button>
          <el-tooltip effect="light" content="放大视图">
            <pl-button
              :size="headerButtonSize"
              :disabled="defaultZoom > 4"
              icon="el-icon-zoom-in"
              @click="processZoomIn()"
            />
          </el-tooltip>
          <el-tooltip effect="light" content="重置视图并居中">
            <pl-button
              :size="headerButtonSize"
              icon="el-icon-c-scale-to-original"
              @click="processReZoom()"
            />
          </el-tooltip>
        </el-button-group>
        <el-button-group
          style="margin-left: 10px !important"
          key="stack-control"
        >
          <el-tooltip effect="light" content="撤销">
            <el-button
              :size="headerButtonSize"
              :disabled="!revocable"
              icon="el-icon-refresh-left"
              @click="processUndo()"
            />
          </el-tooltip>
          <el-tooltip effect="light" content="恢复">
            <el-button
              :size="headerButtonSize"
              :disabled="!recoverable"
              icon="el-icon-refresh-right"
              @click="processRedo()"
            />
          </el-tooltip>
          <el-tooltip effect="light" content="重新绘制">
            <el-button
              :size="headerButtonSize"
              icon="el-icon-refresh"
              @click="processRestart"
            />
          </el-tooltip>
        </el-button-group>
        <span style="right: 15px; position: absolute">
          <el-button
            :size="headerButtonSize"
            :type="headerButtonType"
            @click="handleSave()"
            >保存</el-button
          >
          <el-button
            :size="headerButtonSize"
            :type="headerButtonType"
            @click="handleCheckProcessMap()"
            >验证流程图</el-button
          >
        </span>
      </template>
      <!-- 用于打开本地文件-->
      <input
        type="file"
        id="files"
        ref="refFile"
        style="display: none"
        accept=".xml, .bpmn"
        @change="importLocalFile"
      />
    </div>
    <div class="my-process-designer__container">
      <div class="my-process-designer__canvas" ref="bpmn-canvas"></div>
    </div>
    <el-dialog
      title="预览"
      width="60%"
      :visible.sync="previewModelVisible"
      append-to-body
      destroy-on-close
    >
      <highlightjs :language="previewType" :code="previewResult" />
    </el-dialog>
  </div>
</template>
<script>
import lintModule from "bpmn-js-bpmnlint"
import bpmnlintConfig from "/.bpmnlintrc"
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css"
import "bpmn-js-bpmnlint/dist/assets/css/bpmn-js-bpmnlint.css"
// 生产环境时优化
// const BpmnModeler = window.BpmnJS;
import BpmnModeler from "bpmn-js/lib/Modeler"
import DefaultEmptyXML from "./plugins/defaultEmpty"
// 翻译方法
import customTranslate from "./plugins/translate/customTranslate"
import translationsCN from "./plugins/translate/zh"

// 模拟流转流程
import tokenSimulation from "bpmn-js-token-simulation"

// 标签解析构建器
// import bpmnPropertiesProvider from "bpmn-js-properties-panel/lib/provider/bpmn";

// 标签解析 Moddle
import camundaModdleDescriptor from "./plugins/descriptor/camundaDescriptor.json"
import activitiModdleDescriptor from "./plugins/descriptor/activitiDescriptor.json"
import flowableModdleDescriptor from "./plugins/descriptor/flowableDescriptor.json"

// 标签解析 Extension
import camundaModdleExtension from "./plugins/extension-moddle/camunda"
import activitiModdleExtension from "./plugins/extension-moddle/activiti"
import flowableModdleExtension from "./plugins/extension-moddle/flowable"

// 引入json转换与高亮
import convert from "xml-js"

import {
  saveProcessModel,
  xmlValidate,
  getProcessModelDetails,
} from "@/api/process/manage/process"

export default {
  name: "MyProcessDesigner",
  componentName: "MyProcessDesigner",
  props: {
    value: String, // xml 字符串
    processCode: String, //流程code
    processVersion: Number, //流程版本
    processName: String, //流程名称
    actModelId: String, //流程模型Id
    status: Number, //流程状态
    remark: String, //流程备注
    translations: Object, // 自定义的翻译文件
    additionalModel: [Object, Array], // 自定义model
    moddleExtension: Object, // 自定义moddle
    onlyCustomizeAddi: {
      type: Boolean,
      default: false,
    }, //仅保留用户自定义扩展模块
    onlyCustomizeModdle: {
      type: Boolean,
      default: false,
    }, //仅使用用户自定义模块
    simulation: {
      type: Boolean,
      default: true,
    }, //开启/退出模拟
    keyboard: {
      type: Boolean,
      default: true,
    },
    prefix: {
      type: String,
      default: "activiti",
    },
    events: {
      type: Array,
      default: () => ["element.click"],
    }, //事件
    headerButtonSize: {
      type: String,
      default: "small",
      validator: (value) =>
        ["default", "medium", "small", "mini"].indexOf(value) !== -1,
    }, //头部按钮尺寸
    headerButtonType: {
      type: String,
      default: "primary",
      validator: (value) =>
        ["default", "primary", "success", "warning", "danger", "info"].indexOf(
          value
        ) !== -1,
    }, //头部按钮类型
    validate: Boolean,
  },
  watch: {
    validate: {
      immediate: true,
      handler (data) {
        if (data) {
          this.isValidata = data
          this.handleSave()
        }
      },
      deep: true,
    },
  },
  data () {
    return {
      show: false,
      defaultZoom: 1,
      previewModelVisible: false,
      simulationStatus: false,
      previewResult: "",
      previewType: "xml",
      recoverable: false,
      revocable: false,
      isValidata: false,
      globalScript: "",
    }
  },
  computed: {
    additionalModules () {
      const Modules = [lintModule]
      // 仅保留用户自定义扩展模块
      if (this.onlyCustomizeAddi) {
        if (
          Object.prototype.toString.call(this.additionalModel) ===
          "[object Array]"
        ) {
          return this.additionalModel || []
        }
        return [this.additionalModel]
      }
      // 插入用户自定义扩展模块
      if (
        Object.prototype.toString.call(this.additionalModel) ===
        "[object Array]"
      ) {
        Modules.push(...this.additionalModel)
      } else {
        this.additionalModel && Modules.push(this.additionalModel)
      }
      // 翻译模块
      const TranslateModule = {
        translate: [
          "value",
          customTranslate(this.translations || translationsCN),
          //customTranslate(translationsCN),
        ],
      }
      Modules.push(TranslateModule)
      // 模拟流转模块
      if (this.simulation) {
        Modules.push(tokenSimulation)
      }
      // 根据需要的流程类型设置扩展元素构建模块
      // if (this.prefix === "bpmn") {
      //   Modules.push(bpmnModdleExtension);
      // }
      if (this.prefix === "camunda") {
        Modules.push(camundaModdleExtension)
      }
      if (this.prefix === "flowable") {
        Modules.push(flowableModdleExtension)
      }
      if (this.prefix === "activiti") {
        Modules.push(activitiModdleExtension)
      }
      return Modules
    },
    moddleExtensions () {
      const Extensions = {}
      // 仅使用用户自定义模块
      if (this.onlyCustomizeModdle) {
        return this.moddleExtension || null
      }
      // 插入用户自定义模块
      if (this.moddleExtension) {
        for (let key in this.moddleExtension) {
          Extensions[key] = this.moddleExtension[key]
        }
      }
      // 根据需要的 "流程类型" 设置 对应的解析文件
      if (this.prefix === "activiti") {
        Extensions.activiti = activitiModdleDescriptor
      }
      if (this.prefix === "flowable") {
        Extensions.flowable = flowableModdleDescriptor
      }
      if (this.prefix === "camunda") {
        Extensions.camunda = camundaModdleDescriptor
      }
      return Extensions
    },
  },
  mounted () {
    this.initBpmnModeler()
    //this.createNewDiagram(this.value);
    this.getDetailsInfo()
    this.$once("hook:beforeDestroy", () => {
      if (this.bpmnModeler) {
        this.bpmnModeler.destroy()
      }
      this.$emit("destroy", this.bpmnModeler)
      this.bpmnModeler = null
    })
  },
  methods: {
    async getDetailsInfo () {
      await getProcessModelDetails(
        this.actModelId,
        (res) => {
          if (res != {}) {
            let { sourceXml } = res
            if (sourceXml == "") {
              this.createNewDiagram(this.value)
            } else {
              this.createNewDiagram(sourceXml)
            }
          }
        },
        (error) => {
          console.warn(error)
          this.$message({
            type: "error",
            message: error,
            duration: 2000,
          })
          console.log("error")
        },
        (exception) => {
          console.log("error")
          console.warn(exception)
        }
      )
    },
    initBpmnModeler () {
      if (this.bpmnModeler) {
        return
      }
      this.bpmnModeler = new BpmnModeler({
        linting: {
          bpmnlint: bpmnlintConfig,
          active: true,
        },
        container: this.$refs["bpmn-canvas"],
        keyboard: this.keyboard ? { bindTo: document } : null,
        additionalModules: this.additionalModules,
        moddleExtensions: this.moddleExtensions,
      })
      this.$emit("init-finished", this.bpmnModeler)
      this.initModelListeners()
    },
    initModelListeners () {
      const EventBus = this.bpmnModeler.get("eventBus") //流程设计器监听
      const that = this
      // 注册需要的监听事件, 将. 替换为 - , 避免解析异常
      this.events.forEach((event) => {
        EventBus.on(event, function (eventObj) {
          let eventName = event.replace(/\./g, "-")
          let element = eventObj ? eventObj.element : null
          that.$emit(eventName, element, eventObj)
        })
      })
      // 监听图形改变返回xml
      EventBus.on("commandStack.changed", async (event) => {
        try {
          this.recoverable = this.bpmnModeler.get("commandStack").canRedo()
          this.revocable = this.bpmnModeler.get("commandStack").canUndo()

          let { xml } = await this.bpmnModeler.saveXML({ format: true })
          this.$emit("commandStack-changed", event)
          this.$emit("input", xml)
          this.$emit("change", xml)
        } catch (e) {
          console.log(`[Process Designer Warn]: ${e.message || e}`)
        }
      })
      // 监听视图缩放变化
      this.bpmnModeler.on("canvas.viewbox.changed", ({ viewbox }) => {
        this.$emit("canvas-viewbox-changed", { viewbox })
        const { scale } = viewbox
        this.defaultZoom = Math.floor(scale * 100) / 100
      })
    },
    //创建新的流程图
    async createNewDiagram (xml) {
      // 将字符串转换成图显示出来
      let newId = this.processCode || `Process_${new Date().getTime()}`
      let newName = this.processName || `业务流程_${new Date().getTime()}`
      let xmlString =
        xml ||
        DefaultEmptyXML(
          newId,
          newName,
          this.status,
          this.remark,
          this.actModelId,
          this.prefix
        )
      try {
        let { warnings } = await this.bpmnModeler.importXML(xmlString)
        if (warnings && warnings.length) {
          warnings.forEach((warn) => console.warn(warn))
        }
      } catch (e) {
        console.error(`[Process Designer Warn]: ${e.message || e}`)
      }
    },
    //下载流程图到本地
    async downloadProcess (type, name) {
      try {
        const _this = this
        // 按需要类型创建文件并下载
        if (type === "xml" || type === "bpmn") {
          const { err, xml } = await this.bpmnModeler.saveXML()
          // 读取异常时抛出异常
          if (err) {
            console.error(`[Process Designer Warn ]: ${err.message || err}`)
          }
          let { href, filename } = _this.setEncoded(
            type.toUpperCase(),
            name,
            xml
          )
          downloadFunc(href, filename)
        } else {
          const { err, svg } = await this.bpmnModeler.saveSVG()
          // 读取异常时抛出异常
          if (err) {
            return console.error(err)
          }
          let { href, filename } = _this.setEncoded("SVG", name, svg)
          downloadFunc(href, filename)
        }
      } catch (e) {
        console.error(`[Process Designer Warn ]: ${e.message || e}`)
      }
      // 文件下载方法
      function downloadFunc (href, filename) {
        if (href && filename) {
          let a = document.createElement("a")
          a.download = filename //指定下载的文件名
          a.href = href //  URL对象
          a.click() // 模拟点击
          URL.revokeObjectURL(a.href) // 释放URL 对象
        }
      }
    },
    //根据所需类型进行转码并返回下载地址
    setEncoded (type, filename = "diagram", data) {
      const encodedData = encodeURIComponent(data)
      return {
        filename: `${filename}.${type}`,
        href: `data:application/${type === "svg" ? "text/xml" : "bpmn20-xml"
          };charset=UTF-8,${encodedData}`,
        data: data,
      }
    },
    //加载本地文件
    importLocalFile () {
      const that = this
      const file = this.$refs.refFile.files[0]
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = function () {
        let xmlStr = this.result
        //console.log("xmlStr:" + xmlStr);
        that.createNewDiagram(xmlStr)
        // console.log(that.createNewDiagram(xmlStr))
      }
    },
    //下载xml格式文件
    downloadProcessAsXml () {
      this.downloadProcess("xml")
    },
    //下载bpmn格式文件
    downloadProcessAsBpmn () {
      this.downloadProcess("bpmn")
    },
    //下载svg格式文件
    downloadProcessAsSvg () {
      this.downloadProcess("svg")
    },
    //开启/推出模拟
    processSimulation () {
      this.simulationStatus = !this.simulationStatus
      this.simulation && this.bpmnModeler.get("toggleMode").toggleMode()
    },
    //恢复
    processRedo () {
      this.bpmnModeler.get("commandStack").redo()
    },
    //撤销
    processUndo () {
      this.bpmnModeler.get("commandStack").undo()
    },
    //放大视图
    processZoomIn (zoomStep = 0.1) {
      let newZoom = Math.floor(this.defaultZoom * 100 + zoomStep * 100) / 100
      if (newZoom > 4) {
        throw new Error("【Process-Designer-Warn】变焦比不能大于4")
      }
      this.defaultZoom = newZoom
      this.bpmnModeler.get("canvas").zoom(this.defaultZoom)
    },
    //缩小视图
    processZoomOut (zoomStep = 0.1) {
      let newZoom = Math.floor(this.defaultZoom * 100 - zoomStep * 100) / 100
      if (newZoom < 0.2) {
        throw new Error("【Process-Designer-Warn】变焦比不能小于0.2")
      }
      this.defaultZoom = newZoom
      this.bpmnModeler.get("canvas").zoom(this.defaultZoom)
    },
    processZoomTo (newZoom = 1) {
      if (newZoom < 0.2) {
        throw new Error("【Process-Designer-Warn】变焦比不能小于0.2")
      }
      if (newZoom > 4) {
        throw new Error("【Process-Designer-Warn】变焦比不能大于4")
      }
      this.defaultZoom = newZoom
      this.bpmnModeler.get("canvas").zoom(newZoom)
    },
    //重置视图并居中
    processReZoom () {
      this.defaultZoom = 1
      this.bpmnModeler.get("canvas").zoom("fit-viewport", "auto")
    },
    //重新绘制
    processRestart () {
      this.recoverable = false
      this.revocable = false
      this.createNewDiagram(null).then(() =>
        this.bpmnModeler.get("canvas").zoom(1, "auto")
      )
    },
    //对齐方式
    elementsAlign (align) {
      const Align = this.bpmnModeler.get("alignElements")
      const Selection = this.bpmnModeler.get("selection")
      const SelectedElements = Selection.get()
      if (!SelectedElements || SelectedElements.length <= 1) {
        this.$message.warning("请按住 Ctrl 键选择多个元素对齐")
        return
      }
      this.$confirm("自动对齐可能造成图形变形，是否继续？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => Align.trigger(SelectedElements, align))
    },
    //预览xml
    previewProcessXML () {
      this.bpmnModeler.saveXML({ format: true }).then(({ xml }) => {
        this.previewResult = xml
        this.previewType = "xml"
        this.previewModelVisible = true
        //console.log(xml);
      })
    },
    //预览Json
    previewProcessJson () {
      this.bpmnModeler.saveXML({ format: true }).then(({ xml }) => {
        this.previewResult = convert.xml2json(xml, { spaces: 2 })
        //console.log("Json:" + this.previewResult);
        this.previewType = "json"
        this.previewModelVisible = true
      })
    },
    //保存整个xml，拼装Json到后台
    handleSave () {
      if (!this.isValidata) {
        this.$emit("validateForm") //验证表单
        return
      }

      this.bpmnModeler.saveXML({ format: true }).then(({ xml }) => {
        var xmlToJsonStr = convert.xml2json(xml, { spaces: 2 })
        var obj = JSON.parse(xmlToJsonStr)

        var elements = obj.elements[0].elements
        // console.log(elements)

        var basicAttributeInfo = elements[0].attributes //从生成的Json串中获取流程属性
        this.globalScript = basicAttributeInfo["activiti:scriptStrings"]
        var elementAttributeList = elements[0].elements //从生成的Json串中获取组件属性
        // console.log("elementAttributeList:" + JSON.stringify(elementAttributeList))

        var flowNode = {} //定义根结点
        var nodeMap = (flowNode.nodeMap = {}) //定义节点信息（所有节点的组件属性都包含在内）
        var flow = (flowNode.flow = {}) //定义节点基本信息
        elementAttributeList.forEach((item) => {
          if (item.name == "bpmn2:startEvent") {
            //定义开始节点
            this.$set(nodeMap, item.attributes.id, {
              nodeId: item.attributes.id,
              nodeName: item.attributes.name,
              nodeType: "start",
            })
          } else if (item.name == "bpmn2:endEvent") {
            //定义结束节点
            this.$set(nodeMap, item.attributes.id, {
              nodeId: item.attributes.id,
              nodeName: item.attributes.name,
              nodeType: "end",
            })
          } else if (item.name == "bpmn2:extensionElements") {
            //定义扩展属性
            let variables = (flow.variables = {})
            let elementArr = item.elements[0].elements
            if (elementArr != null && elementArr != {}) {
              elementArr.forEach((item1) => {
                let { name, value } = item1.attributes
                variables["" + name + ""] = value
              })
            }
          } else if (item.name == "bpmn2:exclusiveGateway") {
            //定义分支网关属性
            this.$set(nodeMap, item.attributes.id, {
              nodeId: item.attributes.id,
              nodeName: item.attributes.name,
              nodeType: "exclusiveGateway",
            })
          } else if (item.name == "bpmn2:userTask") {
            //定义用户网关属性
            let userTask = item.attributes.id
            let plugins = {} //下面处理用户任务主体部分

            //处理主体分配
            let userAssign = (plugins.userAssign = {})

            //用户或角色主体
            userAssign.ruleList = item.attributes["activiti:ruleList"]
              ? JSON.parse(item.attributes["activiti:ruleList"])
              : []

            //无处理人时：""|null则无处理，skip跳过当前节点
            userAssign.executeSubjectEmpty = item.attributes[
              "activiti:executeSubjectEmpty"
            ]
              ? item.attributes["activiti:executeSubjectEmpty"] == "1"
                ? "skip"
                : ""
              : ""

            //处理操作按钮权限
            plugins.btnList = item.attributes["activiti:btnList"]
              ? JSON.parse(item.attributes["activiti:btnList"])
              : {}

            //处理表单权限
            plugins.formPermission = item.attributes["activiti:formPermission"]
              ? JSON.parse(item.attributes["activiti:formPermission"])
              : []

            //处理脚本设置
            plugins.script = item.attributes["activiti:script"]
              ? JSON.parse(item.attributes["activiti:script"])
              : {}

            //处理节点属性
            let properties = (plugins.properties = {})

            //驳回后负责人：历史节点的处理人:history、任务节点配置处理主体:
            if (
              !item.attributes["activiti:rejectedExecutor"] ||
              item.attributes["activiti:rejectedExecutor"] == 0
            ) {
              properties.turnDownhandle = "history"
            } else if (item.attributes["activiti:rejectedExecutor"] == 1) {
              properties.turnDownhandle = "flow"
            }

            //驳回策略：按照流程图定义flow，指定节点驳回（node:{usertaskId}
            if (
              !item.attributes["activiti:rejectedStrategy"] ||
              item.attributes["activiti:rejectedStrategy"] == 0
            ) {
              properties.turnDownStrategy = "flow"
            } else if (item.attributes["activiti:rejectedStrategy"] == 1) {
              var selectNodeStr = item.attributes[
                "activiti:selectNodeStr"
              ].slice(
                item.attributes["activiti:selectNodeStr"].indexOf("/") + 1
              )
              properties.turnDownStrategy = "node:" + selectNodeStr + ""
            }

            //撤回策略:全局global，不允许none,允许allow
            if (
              !item.attributes["activiti:recallStrategy"] ||
              item.attributes["activiti:recallStrategy"] == 0
            ) {
              properties.recycle = "global"
            } else if (item.attributes["activiti:recallStrategy"] == 1) {
              properties.recycle = "none"
            } else if (item.attributes["activiti:recallStrategy"] == 2) {
              properties.recycle = "allow"
            }

            let variables = (plugins.variables = {})
            //处理扩展属性
            if (item.elements) {
              item.elements.forEach((m) => {
                if (m.name == "bpmn2:extensionElements") {
                  let elementArr = m.elements[0].elements
                  if (elementArr != null && elementArr != {}) {
                    elementArr.forEach((n) => {
                      let { name, value } = n.attributes
                      variables["" + name + ""] = value
                    })
                  }
                }
              })
            }

            //在这加判断，name=name，remark=remark;
            let temp_name = item.attributes.name
            // console.log(item.attributes['activiti:remark'])
            // console.log(item.attributes.name)
            let temp_remark = item.attributes['activiti:remark']
            // console.log(item.attributes.remark)
            let zs_name = ""
            let zs_remark = ""

            if (temp_name && temp_remark) {
              zs_name = temp_remark
              zs_remark = temp_name
              // console.log('name有值，remark有值', zs_name, zs_remark)
            } else {
              if (temp_name && !temp_remark) {
                //name有值，remark无值
                zs_name = temp_name
                // console.log('name有值，remark无值', zs_name)
              }
              else if (temp_remark && !temp_name) {
                //name无值，remark有值
                zs_name = zs_remark
                zs_remark = zs_remark
                // console.log('name无值值，remark有值', zs_name, zs_remark)
              }
            }
            this.$set(nodeMap, userTask, {
              nodeId: item.attributes.id,
              nodeName: zs_name,
              remark: zs_remark,
              nodeType: "userTask",
              plugins: plugins,
            })
          } else if (item.name == "bpmn2:sequenceFlow") {
            try {
              if (item.elements != [] && item.elements.length > 0) {
                let elementArr = item.elements[0].attributes
                this.$set(nodeMap, item.attributes.id, {
                  nodeId: item.attributes.id,
                  nodeName: item.attributes.name,
                  nodeType: "sequenceFlow",
                  conditionExpression: elementArr["activiti:scriptString"]
                })
              }
            }
            catch (error) {
              console.log(error)
            }
          }
        })

        //定义任务跳过策略
        flow.taskSkip = basicAttributeInfo["activiti:taskSkipKey"]

        //定义全局表单
        let globalForm = (flow.globalForm = [])
        // globalForm.formKey = basicAttributeInfo["activiti:globalFormKey"];
        // globalForm.name = basicAttributeInfo["activiti:globalFormName"];

        let globalFormList = basicAttributeInfo["activiti:globalForm"]
          ? JSON.parse(basicAttributeInfo["activiti:globalForm"])
          : []

        //console.log("globalFormList" + JSON.stringify(globalFormList));
        if (globalFormList != [] && globalFormList.length > 0) {
          globalFormList.forEach((item) => {
            var model = {
              boCode: item.boCode,
              formKey: item.code,
              formName: item.name,
              formType: item.formType,
            }
            globalForm.push(model)
          })
        }

        //定义基础属性
        let properties = (flow.properties = {})
        properties.subjectRule = basicAttributeInfo["activiti:titleRule"] //标题规则
        properties.recycle = basicAttributeInfo["activiti:isAllowBaskProcess"]
          ? basicAttributeInfo["activiti:isAllowBaskProcess"]
          : 0 //是否允许撤回
        properties.requireOpinion = basicAttributeInfo[
          "activiti:isRequiredComments"
        ]
          ? basicAttributeInfo["activiti:isRequiredComments"]
          : 0 //是否必填意见
        properties.status = basicAttributeInfo["activiti:status"] //状态：0--草稿，1--已发布
        properties.desc = basicAttributeInfo["activiti:remark"]
          ? basicAttributeInfo["activiti:remark"]
          : "" //描述

        let flowNodeJson = JSON.stringify(flowNode)

        // console.log(flowNode)
        // console.log("flowNode:" + flowNodeJson)
        this.saveProcessModelAsync(flowNodeJson, xml)
      })
    },
    //保存流程设计模型
    async saveProcessModelAsync (bpmDefSetting, flowXml) {

      var a = JSON.parse(bpmDefSetting)
      // console.log(a)
      let user = localStorage.getItem('c_user')
      if (user) {
        user = JSON.parse(user)
      }
      let role = localStorage.getItem('c_role')
      if (role) {
        role = JSON.parse(role)
      }
      var observer = []
      if (user) {
        user.forEach(item => {
          if (item.identityType == 'user') {
            var b = { type: '', name: '', id: '' }
            b.type = item.identityType
            b.name = item.name
            b.id = item.userId
            observer.push(b)
          }
        })
      }
      if (role) {
        role.forEach(item => {
          if (item.identityType == 'role') {
            var ch = { type: '', name: '', id: '' }
            ch.type = item.identityType
            ch.name = item.name
            ch.id = item.key
            observer.push(ch)
          }
        })
      }

      a.flow.globalScript = this.globalScript
      a.flow.observer = observer || []

      // console.log(JSON.stringify(a))

      await saveProcessModel(
        {
          modelId: this.actModelId,
          info: {
            defCode: this.processCode,
            version: this.processVersion,
            name: this.processName,
            info: this.remark,
            bpmDefSetting: JSON.stringify(a),
            flowXml: flowXml,
          },
        },
        (res) => {
          this.$message({
            type: "success",
            message: "保存成功!",
            duration: 1000,
            onClose: () => { },
          })
        },
        (error) => {
          console.warn(error)
          this.$message({
            type: "error",
            message: error,
            duration: 2000,
          })
          console.log("error")
        },
        (exception) => {
          console.log("error")
          console.warn(exception)
        }
      )
    },
    //验证流程图
    handleCheckProcessMap () {
      this.bpmnModeler.saveXML({ format: true }).then(({ xml }) => {
        this.xmlValidateAsync(xml)
      })
    },
    async xmlValidateAsync (xmlToJsonStr) {
      await xmlValidate(
        { bpmDefinitionXml: xmlToJsonStr },
        (res) => {
          this.$message({
            type: "success",
            message: "验证通过！",
            duration: 1000,
            onClose: () => { },
          })
        },
        (error) => {
          console.warn(error)
          this.$message({
            type: "error",
            message: error,
            duration: 2000,
          })
          console.log("error")
        },
        (exception) => {
          console.log("error")
          console.warn(exception)
        }
      )
    },
  },
};
</script>
 <style>
.my-process-designer .my-process-designer__header .el-button-group {
  margin: 0px !important;
}
.bjsl-button {
  width: 200px;
  height: 35px;
}
.bjsl-button-error {
  width: 200px;
}
.bjsl-button-inactive {
  border: 1px solid black;
}
.bjsl-button-success svg,
.bjsl-button-error svg,
.bjsl-button-warning svg,
.bjsl-button-inactive svg {
  width: 25px !important;
}
.bjsl-issues svg {
  width: 12px !important;
  height: 12px !important;
}
</style>
/**
 * Created by DELL on 2018/9/4.
 */
import DictText from './DictText.vue';
import DictSelect from './DictSelect';
import DictTableColumn from './DictTableColumn';
import DictCascade from './DictCascade';
import DictTree from './DictTree.vue';

export default {
  install: (Vue) => {
    Vue.component('dictText',DictText);
    Vue.component('dictSelect',DictSelect);
    Vue.component('dictTableColumn',DictTableColumn);
    Vue.component('DictCascade',DictCascade);
    Vue.component('DictTree',DictTree);
  }
}

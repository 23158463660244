import {
  system
} from "./system"
import {
  process
} from "./process.js"
import {
  workbench
} from "./workbench.js"
import {
  form,
  business
} from "./form.js"
import error from "./error.js"

export const routes = [
  ...error,
  {
    path: '*',
    redirect: '/404',
    hidden: true,
  },
  {
    hash: "",
    path: "/login",
    name: "login",
    component: () => import("@/views/login")
  },
  {
    hash: "",
    path: "/seach",
    name: "seach",
    component: () => import("@/views/province/seachHLJ.vue")
  },
  {
    hash: "",
    path: "/liaoning",
    name: "liaoning",
    component: () => import("@/views/province/liaoning.vue")
  },
  {
    hash: "",
    path: "/qinghai",
    name: "qinghai",
    component: () => import("@/views/province/qinghai.vue")
  },
  {
    hash: "",
    path: "/jiangxi",
    name: "jiangxi",
    component: () => import("@/views/province/jiangxi.vue")
  },
  {
    hash: "",
    path: "/xizang",
    name: "xizang",
    component: () => import("@/views/province/xizang.vue")
  },
  {
    hash: "",
    path: "/neimeng",
    name: "neimeng",
    component: () => import("@/views/province/neimeng.vue")
  },
  {
    hash: "",
    path: "/sichuan",
    name: "sichuan",
    component: () => import("@/views/province/sichuan.vue")
  },
  {
    hash: "",
    path: "/jiangsu",
    name: "jiangsu",
    component: () => import("@/views/province/jiangsu.vue")
  },
  {
    hash: "",
    path: "/chongqing",
    name: "chongqing",
    component: () => import("@/views/province/chongqing.vue")
  },
  {
    hash: "",
    path: "/shanghai",
    name: "shanghai",
    component: () => import("@/views/province/shanghai.vue")
  },
  {
    hash: "",
    path: "/yunnan",
    name: "yunnan",
    component: () => import("@/views/province/yunnan.vue")
  },
  {
    hash: "",
    path: "/guizhou",
    name: "guizhou",
    component: () => import("@/views/province/guizhou.vue")
  },
  {
    hash: "",
    path: "/hainan",
    name: "hainan",
    component: () => import("@/views/province/hainan.vue")
  },
  {
    hash: "",
    path: "/hunan",
    name: "hunan",
    component: () => import("@/views/province/hunan.vue")
  },
  {
    hash: "",
    path: "/hebei",
    name: "hebei",
    component: () => import("@/views/province/hebei.vue")
  },
  {
    hash: "",
    path: "/shandong",
    name: "shandong",
    component: () => import("@/views/province/shandong.vue")
  },
  {
    hash: "",
    path: "/xinjiang",
    name: "xinjiang",
    component: () => import("@/views/province/xinjiang.vue")
  },
  {
    hash: "",
    path: "/guangdong",
    name: "guangdong",
    component: () => import("@/views/province/guangdong.vue")
  },
  {
    hash: "",
    path: "/ningxia",
    name: "ningxia",
    component: () => import("@/views/province/ningxia.vue")
  },
  {
    hash: "",
    path: "/fujian",
    name: "fujian",
    component: () => import("@/views/province/fujian.vue")
  },
  {
    hash: "",
    path: "/jilin",
    name: "jilin",
    component: () => import("@/views/province/jilin.vue")
  },
  {
    hash: "",
    path: "/zhejiang",
    name: "zhejiang",
    component: () => import("@/views/province/zhejiang.vue")
  },
  {
    hash: "",
    path: "/tianjin",
    name: "tianjin",
    component: () => import("@/views/province/tianjin.vue")
  },
  {
    hash: "",
    path: "/shanxi",
    name: "shanxi",
    component: () => import("@/views/province/shanxi.vue")
  },
  {
    hash: "",
    path: "/shaanxi",
    name: "shaanxi",
    component: () => import("@/views/province/shaanxi.vue")
  },
  {
    hash: "",
    path: "/heilongjiang",
    name: "heilongjiang",
    component: () => import("@/views/province/heilongjiang.vue")
  },
  {
    hash: "",
    path: "/guangxi",
    name: "guangxi",
    component: () => import("@/views/province/guangxi.vue")
  },
  {
    hash: "",
    path: "/hubei",
    name: "hubei",
    component: () => import("@/views/province/hubei.vue")
  },
  {
    hash: "",
    path: "/anhui",
    name: "anhui",
    component: () => import("@/views/province/anhui.vue")
  },
  {
    hash: "",
    path: "/henan",
    name: "henan",
    component: () => import("@/views/province/henan.vue")
  },
  {
    hash: "",
    path: "/gansu",
    name: "gansu",
    component: () => import("@/views/province/gansu.vue")
  },
  {
    hash: "",
    path: "/bingtuan",
    name: "bingtuan",
    component: () => import("@/views/province/bingtuan.vue")
  },
  {
    hash: "",
    path: "/beijing",
    name: "beijing",
    component: () => import("@/views/province/beijing.vue")
  },
  {
    hash: "",
    path: "/jiangsuzyz",
    name: "jiangsuzyz",
    component: () => import("@/views/province/jiangsuzyz.vue")
  },

  {
    hash: "",
    path: "/zyzinfor",
    name: "zyzinfor",
    component: () => import("@/views/zyzinfor")
  },
  {
    hash: "",
    path: "/Query.aspx",
    name: "Query.aspx",
    component: () => import("@/views/province/Query.aspx.vue")
  },
  {
    hash: "",
    path: "/autologin",
    name: "autologin",
    component: () => import("@/views/autologin")
  },
  {
    hash: "",
    path: "/jgptzyz",
    name: "jgptzyz",
    component: () => import("@/views/jgptzyz")
  },
  {
    hash: "",
    path: '/screen',
    name: '锁屏',
    component: () => import('@/views/home/screen')
  },
  {
    hash: "",
    path: '/1',
    name: 'otherpage',
    component: () => import("@/views/otherpage/index.vue"),
  },
  {
    hash: "",
    path: "/",
    name: "首页",
    component: () => import("@/views/home/index"),
    redirect: '/workbench/index',
    meta: ["首页"],
    children: [
      ...system,
      ...process,
      ...workbench,
      ...business,
      ...form,
      {
        hash: "",
        path: "/transfer",
        name: "空白页",
        component: () => import("@/views/transfer")
      },
      {
        hash: "",
        path: "/link",
        name: "外链",
        component: () => import("@/views/link")
      },
      {
        hash: "",
        path: "/user/info",
        name: "修改个人资料",
        component: () => import("@/views/home/user/personalData.vue")
      },
      {
        hash: "",
        path: "/user/password",
        name: "修改登录密码",
        component: () => import("@/views/home/user/password.vue")
      }
    ]
  }
]